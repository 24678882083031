const userActionTypes = {
  REGISTER_FORM_EIN_VALUE: 'USERS_REGISTER_FORM_EIN_VALUE',
  REGISTER_FORM_PHONE_VALUE: 'USERS_REGISTER_FORM_PHONE_VALUE',
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
  REGISTER_RESET_ALL: 'USERS_REGISTER_RESET',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  LOGOUT: 'USERS_LOGOUT',
  LOGIN_CLEAR_ERRORS: 'USERS_LOGIN_CLEAR_ERRORS',

  RESEND_VERIFICATION_EMAIL_REQUEST: 'USERS_RESEND_VERIFICATION_EMAIL_REQUEST',
  RESEND_VERIFICATION_EMAIL_SUCCESS: 'USERS_RESEND_VERIFICATION_EMAIL_SUCCESS',
  RESEND_VERIFICATION_EMAIL_FAILURE: 'USERS_RESEND_VERIFICATION_EMAIL_FAILURE',

  RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',
  RESET_PASSWORD_RESET: 'USERS_RESET_PASSWORD_RESET',

  RESET_PASSWORD_SUBMIT_REQUEST: 'USERS_RESET_PASSWORD_SUBMIT_REQUEST',
  RESET_PASSWORD_SUBMIT_SUCCESS: 'USERS_RESET_PASSWORD_SUBMIT_SUCCESS',
  RESET_PASSWORD_SUBMIT_FAILURE: 'USERS_RESET_PASSWORD_SUBMIT_FAILURE',
  RESET_PASSWORD_SUBMIT_RESET: 'USERS_RESET_PASSWORD_SUBMIT_RESET',

  INVITE_FORM_UPDATE_GROUPS: 'INVITE_FORM_UPDATE_GROUPS',
  INVITE_FORM_UPDATE_EMAILS: 'INVITE_FORM_UPDATE_EMAILS',
  INVITE_FORM_SET_HAS_ERROR: 'INVITE_FORM_SET_HAS_ERROR',
  INVITE_FORM_RESET_ALL: 'INVITE_FORM_RESET_ALL',

  SENDING_INVITES_REQUEST: 'SENDING_INVITES_REQUEST',
  SENDING_INVITES_SUCCESS: 'SENDING_INVITES_SUCCESS',
  SENDING_INVITES_FAILURE: 'SENDING_INVITES_FAILURE',

  INVITES_REQUEST: 'INVITES_REQUEST',
  INVITES_SUCCESS: 'INVITES_SUCCESS',
  INVITES_FAILURE: 'INVITES_FAILURE',

  UPDATE_INVITES_REQUEST: 'UPDATE_INVITES_REQUEST',
  UPDATE_INVITES_SUCCESS: 'UPDATE_INVITES_SUCCESS',
  UPDATE_INVITES_FAILURE: 'UPDATE_INVITES_FAILURE',

  FEED_REQUEST: 'FEED_REQUEST',
  FEED_SUCCESS: 'FEED_SUCCESS',
  FEED_FAILURE: 'FEED_FAILURE',

  RECENTLY_VIEWED_DONORS_REQUEST: 'RECENTLY_VIEWED_DONORS_REQUEST',
  RECENTLY_VIEWED_DONORS_SUCCESS: 'RECENTLY_VIEWED_DONORS_SUCCESS',
  RECENTLY_VIEWED_DONORS_FAILURE: 'RECENTLY_VIEWED_DONORS_FAILURE',

  SET_LOCATION: 'SET_LOCATION',

  DONORS_REQUEST: 'DONORS_REQUEST',
  DONORS_SUCCESS: 'DONORS_SUCCESS',
  DONORS_FAILURE: 'DONORS_FAILURE',

  DONOR_INFO_REQUEST: 'DONOR_INFO_REQUEST',
  DONOR_INFO_SUCCESS: 'DONOR_INFO_SUCCESS',
  DONOR_INFO_FAILURE: 'DONOR_INFO_FAILURE',

  DONOR_FORM_UPDATE_USER: 'DONOR_FORM_UPDATE_USER',
  DONOR_FORM_UPDATE_ORG: 'DONOR_FORM_UPDATE_ORG',
  DONOR_FORM_RESET: 'DONOR_FORM_RESET',
  DONOR_FORM_SET_ERRORS: 'DONOR_FORM_SET_ERRORS',

  REFRESH_ACCESS_TOKEN: 'REFRESH_ACCESS_TOKEN',
  REFRESH_ACCESS_TOKEN_TIMER_SETUP: 'REFRESH_ACCESS_TOKEN_TIMER_SETUP',

  DONOR_FORM_UPDATE_DONOR_REQUEST: 'DONOR_FORM_UPDATE_DONOR_REQUEST',
  DONOR_FORM_UPDATE_DONOR_SUCCESS: 'DONOR_FORM_UPDATE_DONOR_SUCCESS',
  DONOR_FORM_UPDATE_DONOR_FAILURE: 'DONOR_FORM_UPDATE_DONOR_FAILURE',
  DONOR_FORM_UPDATE_DONOR_RESET: 'DONOR_FORM_UPDATE_DONOR_RESET',

  DONOR_FORM_SAVE_DONOR_REQUEST: 'DONOR_FORM_SAVE_DONOR_REQUEST',
  DONOR_FORM_SAVE_DONOR_SUCCESS: 'DONOR_FORM_SAVE_DONOR_SUCCESS',
  DONOR_FORM_SAVE_DONOR_FAILURE: 'DONOR_FORM_SAVE_DONOR_FAILURE',
  DONOR_FORM_SAVE_DONOR_RESET: 'DONOR_FORM_SAVE_DONOR_RESET',

  DONOR_FORM_UPDATE_EDITED_FIELDS: 'DONOR_FORM_UPDATE_EDITED_FIELDS',

  GOALS_REQUEST: 'GOALS_REQUEST',
  GOALS_SUCCESS: 'GOALS_SUCCESS',
  GOALS_FAILURE: 'GOALS_FAILURE',

  USERS_REQUEST: 'USERS_REQUEST',
  USERS_SUCCESS: 'USERS_SUCCESS',
  USERS_FAILURE: 'USERS_FAILURE',
};

export default userActionTypes;
