import React from 'react';
import {
  AuthContainer,
} from 'components';
import { ResendVerificationLink } from 'components/auth/resend-email-verification';
import env from '@beam-australia/react-env';
import {
  Grid, Typography,
} from '@material-ui/core';
import {
  thinkingEmoji,
} from 'assets/images';
import {
  authNavLinkStyle,
  errorPageIconStyle,
} from 'components/auth/styles';
import {
  translate,
} from 'lib/intl';
import {
  useLogin,
} from './hooks';

const LoginVerificationEmailErrorPage = () => {
  const {
    isFetching,
    error,
    usernameToReset,
    resendVerificationEmail,
  } = useLogin();

  return (
    <AuthContainer size="md" navLinkText="Sign in" navLinkRoute="/login">
      <Grid container>
        <Grid item sm={6} xs={12}>
          <Typography variant="h5">{translate('GENERAL_EXCLAMATORY')}</Typography>
          <Typography variant="h6" style={{ marginTop: '30px' }}>{translate('LOGIN_VERIFICATION_ERROR')}</Typography>
          <div style={{ marginTop: '30px' }}>
            <ResendVerificationLink
              isFetching={isFetching}
              error={error}
              usernameToReset={usernameToReset}
              resendVerificationEmail={resendVerificationEmail}
            />
          </div>
          <Typography variant="body1" style={{ marginTop: '30px' }}>
            {translate('PERSISTENT_AUTH_ISSUE')}
            &nbsp;
            <a href={`mailto:${env('HELP_LINK_ADDRESS')}`} style={authNavLinkStyle}>{translate('CONTACT_US')}</a>
          </Typography>
        </Grid>
        <Grid item sm={6} xs={12} style={errorPageIconStyle}>
          <img alt="Thinking emoji" src={thinkingEmoji} />
        </Grid>
      </Grid>
    </AuthContainer>
  );
};

export {
  LoginVerificationEmailErrorPage,
};
