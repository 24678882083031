import React from 'react';
import styled from 'styled-components';
import {
  affiliationsIcon,
  BlackCircleIcon,
  birthdayIcon,
  buildingIcon,
  connectionsIcon,
  emailIcon,
  facebookIcon,
  familyIcon,
  giftIcon,
  homeIcon,
  hourGlassIcon,
  jobIcon,
  jobPositionIcon,
  inIcon,
  instagramIcon,
  moneyBagIcon,
  notesIcon,
  phoneIcon,
  phoneNumberIcon,
  spouseIcon,
  personIcon,
  redPhoneNumber,
  twitterIcon,
  webIcon,
  winningIcon,
  concernsIcon,
  prohibitionsIcon,
} from 'assets/images';

const StyledIcon = styled.img`
  height: ${({ social }) => (social ? 24 : 16)}px;
  margin-top: ${({ summary }) => (summary ? 0 : 2)}px;
  width: ${({ social }) => (social ? 24 : 16)}px;
`;

const AffiliationsIcon = <StyledIcon alt="affiliations-icon" data-testid="affiliations-icon" src={affiliationsIcon} />;
const BirthdayIcon = <StyledIcon alt="birthday-icon" data-testid="birthday-icon" src={birthdayIcon} />;
const BuildingIcon = <StyledIcon alt="building-icon" data-testid="building-icon" src={buildingIcon} />;
const ConnectionsIcon = <StyledIcon alt="connections-icon" data-testid="connections-icon" src={connectionsIcon} />;
const FacebookIcon = <StyledIcon alt="facebook-icon" data-testid="facebook-icon" src={facebookIcon} social="true" />;
const FamilyIcon = <StyledIcon alt="family-icon" data-testid="family-icon" src={familyIcon} />;
const GiftIcon = <StyledIcon alt="gift-icon" data-testid="gift-icon" src={giftIcon} summary="true" />;
const HomeIcon = <StyledIcon alt="home-icon" data-testid="home-icon" src={homeIcon} />;
const HourGlassIcon = <StyledIcon alt="hour-glass-icon" data-testid="hour-glass-icon" src={hourGlassIcon} />;
const LinkedInIcon = <StyledIcon alt="linked-in-icon" data-testid="linked-in-icon" src={inIcon} social="true" />;
const MoneyBagIcon = <StyledIcon alt="money-bag-icon" data-testid="money-bag-icon" src={moneyBagIcon} summary="true" />;
const InstagramIcon = <StyledIcon alt="instagram-icon" data-testid="instagram-icon" src={instagramIcon} social="true" />;
const PersonIcon = <StyledIcon alt="person-icon" data-testid="person-icon" src={personIcon} />;
const TwitterIcon = <StyledIcon alt="twitter-icon" data-testid="twitter-icon" src={twitterIcon} social="true" />;
const WebIcon = <StyledIcon alt="web-icon" data-testid="web-icon" src={webIcon} />;
const NotesIcon = <StyledIcon alt="notes-icon" data-testid="notes-icon" src={notesIcon} />;
const EmailIcon = <StyledIcon alt="email-icon" data-testid="email-icon" src={emailIcon} />;
const JobIcon = <StyledIcon alt="job-icon" data-testid="job-icon" src={jobIcon} />;
const JobPositionIcon = <StyledIcon alt="job-position-icon" data-testid="job-position-icon" src={jobPositionIcon} />;
const SpouseIcon = <StyledIcon alt="spouse-icon" data-testid="spouse-icon" src={spouseIcon} />;
const PhoneIcon = <StyledIcon alt="phone-icon" data-testid="phone-icon" src={phoneNumberIcon} />;
const RedPhoneIcon = <StyledIcon alt="red-phone-icon" data-testid="red-phone-icon" src={redPhoneNumber} />;
const BlackCircle = <BlackCircleIcon height="4px" width="4px" />;
const WorkPhoneIcon = <StyledIcon alt="work-phone-icon" data-testid="work-phone-icon" src={phoneIcon} />;
const WinningIcon = <StyledIcon alt="winning-icon" data-testid="winning-icon" src={winningIcon} />;
const ConcernsIcon = <StyledIcon alt="concerns-icon" data-testid="concerns-icon" src={concernsIcon} />;
const ProhibitionsIcon = <StyledIcon alt="prohibitions-icon" data-testid="prohibitions-icon" src={prohibitionsIcon} />;

export {
  AffiliationsIcon,
  BirthdayIcon,
  BlackCircle,
  BuildingIcon,
  ConnectionsIcon,
  EmailIcon,
  FacebookIcon,
  FamilyIcon,
  GiftIcon,
  JobIcon,
  JobPositionIcon,
  HomeIcon,
  HourGlassIcon,
  RedPhoneIcon,
  InstagramIcon,
  LinkedInIcon,
  MoneyBagIcon,
  NotesIcon,
  SpouseIcon,
  PersonIcon,
  PhoneIcon,
  TwitterIcon,
  WebIcon,
  WorkPhoneIcon,
  WinningIcon,
  ConcernsIcon,
  ProhibitionsIcon,
};
