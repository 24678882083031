import React, {
  useMemo,
} from 'react';
import {
  arrayOf,
  bool,
  func,
  string,
} from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  CustomButton,
  Gap,
  Loader,
  Visible,
} from 'components';
import {
  EMPTY_FUNC,
} from 'lib/constants';
import {
  DonorType,
  isMobileView,
} from 'lib/utils';
import {
  translate,
} from 'lib/intl';
import {
  colors,
} from 'styles/theme';
import {
  EditDonorTypeSelector,
} from './edit-donor-type-selector';
import {
  EditDonorUserSections,
} from './edit-donor-user-sections';
import {
  EditDonorOrgSections,
} from './edit-donor-org-sections';

const StyledDialog = styled(Dialog)`
${({ mobile }) => (mobile ? `
  .MuiDialog-paperFullWidth {
    border-radius: 0px;
    height: 100%;
    margin: 0px;
    max-height: 100vh;
    width: 100%;
  }
  ` : '')}
`;

const Header = styled(Box)`
  border-bottom: 1px solid ${colors.lightGrey};

  h2 {
    font-size: 20px;
    font-weight: 500;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;

const StyledBottomContent = styled(Box)`
  height: 104px;
`;

const EditDonorModal = ({
  data,
  errors,
  open,
  handleClose,
  handleSave,
  isCreating,
  isLoading,
  isUserForm,
  setErrors,
  showErrors,
  toggleDonorForm,
  updateData,
}) => {
  const isMobile = isMobileView();

  const Sections = useMemo(() => (isUserForm ? EditDonorUserSections : EditDonorOrgSections), [
    isUserForm,
  ]);

  const title = useMemo(() => translate(isCreating
    ? 'CREATE_DONOR_PROFILE_TITLE'
    : 'EDIT_DONOR_PROFILE_TITLE'), []);

  return (
    <StyledDialog
      aria-labelledby="edit-donor-dialog"
      fullWidth
      maxWidth="md"
      mobile={Number(isMobile)}
      onClose={handleClose}
      open={open}
    >
      <Header
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        pr={3}
      >
        <DialogTitle id="edit-donor-dialog-title">
          {title}
        </DialogTitle>
        <Visible when={isCreating}>
          <EditDonorTypeSelector
            isUserForm={isUserForm}
            toggleDonorForm={toggleDonorForm}
          />
        </Visible>
      </Header>
      <StyledDialogContent>
        <Sections
          data={data}
          errors={errors}
          setErrors={setErrors}
          showErrors={showErrors}
          updateData={updateData}
        />
      </StyledDialogContent>
      <StyledBottomContent
        display="flex"
        alignItems="center"
        justifyContent={isLoading ? 'center' : 'flex-start'}
        px={2}
        py={3}
      >
        <Visible
          fallback={<Loader />}
          when={!isLoading}
        >
          <Visible when={errors?.length && showErrors}>
            <Box px={1}>
              <Typography color="error">
                {translate('DONOR_FORM_FIX_ERRORS_MESSAGE')}
              </Typography>
            </Box>
          </Visible>
          <Box
            display="flex"
            flex="1"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <CustomButton
              buttonType="link"
              color="primary"
              data-testid="edit-donor-modal-cancel-button"
              label={translate('CANCEL')}
              onClick={handleClose}
            />
            <Gap />
            <CustomButton
              buttonType="secondary"
              data-testid="edit-donor-modal-save-button"
              label={translate('SAVE')}
              onClick={handleSave}
            />
          </Box>
        </Visible>
      </StyledBottomContent>
    </StyledDialog>
  );
};

EditDonorModal.propTypes = {
  data: DonorType.isRequired,
  errors: arrayOf(string),
  isCreating: bool,
  isLoading: bool,
  isUserForm: bool.isRequired,
  open: bool,
  handleClose: func,
  handleSave: func,
  showErrors: bool,
  setErrors: func,
  toggleDonorForm: func.isRequired,
  updateData: func,
};

EditDonorModal.defaultProps = {
  errors: [],
  open: false,
  handleClose: EMPTY_FUNC,
  handleSave: EMPTY_FUNC,
  isCreating: false,
  isLoading: false,
  setErrors: EMPTY_FUNC,
  showErrors: false,
  updateData: EMPTY_FUNC,
};

export {
  EditDonorModal,
};
