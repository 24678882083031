import React from 'react';
import { hot } from 'react-hot-loader';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route, Switch } from 'react-router-dom';
import { wrapWithNav } from 'lib/utils/wrap-with-nav';
import {
  RoutesWrapper,
  AuthRoute,
  PrivateRoute,
} from 'components';

import {
  theme,
} from 'styles/theme';

import {
  HomePage,
  InvitePage,
  LoginPage,
  LoginLinkExpiredPage,
  LoginNeedsVerificationPage,
  LoginVerificationEmailErrorPage,
  NotFoundErrorPage,
  RegistrationPage,
  RegistrationByInvitePage,
  RegistrationSuccessPage,
  RegistrationVerificationErrorPage,
  RegistrationInviteVerificationErrorPage,
  ResetPasswordPage,
  ResetPasswordRequestSuccessPage,
  ResetPasswordSetPasswordPage,
  ResetPasswordSetPasswordSuccessPage,
  DonorPage,
  DonorsPage,
} from 'pages';

import {
  PUBLIC_ROUTES,
  AUTH_ROUTES,
} from 'lib/constants';

import {
  applyPrototypes,
} from 'lib/utils';

const {
  LOGIN,
  LOGIN_EXPIRED,
  VERIFICATION,
  VERIFICATION_ERROR,
  REGISTRATION,
  REGISTRATION_FROM_INVITE,
  REGISTRATION_SUCCESS,
  REGISTRATION_VERIFICATION_ERROR,
  REGISTRATION_INVITE_VERIFICATION_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_SET_PASSWORD,
  RESET_PASSWORD_SET_PASSWORD_SUCCESS,
} = PUBLIC_ROUTES;

const {
  LANDING,
  INVITE,
  DONOR,
  DONORS,
  FEED,
} = AUTH_ROUTES;

const HomePageWithNav = wrapWithNav(HomePage);
const InvitePageWithNav = wrapWithNav(InvitePage);
const DonorPageWithNav = wrapWithNav(DonorPage);
const DonorsPageWrapper = wrapWithNav(DonorsPage);

function App() {
  applyPrototypes();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RoutesWrapper>
        <Switch>
          <PrivateRoute exact path={LANDING} component={HomePageWithNav} />
          <PrivateRoute path={FEED} component={HomePageWithNav} />
          <PrivateRoute exact path={INVITE} component={InvitePageWithNav} />
          <PrivateRoute exact path={DONORS} component={DonorsPageWrapper} />
          <PrivateRoute exact path={DONOR} component={DonorPageWithNav} />
          <AuthRoute exact path={LOGIN} component={LoginPage} />
          <AuthRoute path={LOGIN_EXPIRED} component={LoginLinkExpiredPage} />
          <AuthRoute exact path={VERIFICATION} component={LoginNeedsVerificationPage} />
          <AuthRoute path={VERIFICATION_ERROR} component={LoginVerificationEmailErrorPage} />
          <AuthRoute exact path={REGISTRATION} component={RegistrationPage} />
          <AuthRoute path={REGISTRATION_SUCCESS} component={RegistrationSuccessPage} />
          <AuthRoute
            path={REGISTRATION_VERIFICATION_ERROR}
            component={RegistrationVerificationErrorPage}
          />
          <AuthRoute
            path={REGISTRATION_INVITE_VERIFICATION_ERROR}
            component={RegistrationInviteVerificationErrorPage}
          />
          <AuthRoute path={REGISTRATION_FROM_INVITE} component={RegistrationByInvitePage} />
          <AuthRoute exact path={RESET_PASSWORD} component={ResetPasswordPage} />
          <AuthRoute
            path={RESET_PASSWORD_REQUEST_SUCCESS}
            component={ResetPasswordRequestSuccessPage}
          />
          <AuthRoute
            exact
            path={RESET_PASSWORD_SET_PASSWORD}
            component={ResetPasswordSetPasswordPage}
          />
          <AuthRoute
            path={RESET_PASSWORD_SET_PASSWORD_SUCCESS}
            component={ResetPasswordSetPasswordSuccessPage}
          />
          <Route component={NotFoundErrorPage} />
        </Switch>
      </RoutesWrapper>
    </ThemeProvider>
  );
}

export default hot(module)(App);
