import React, {
  useMemo,
} from 'react';
import {
  func,
  string,
} from 'prop-types';
import {
  Box,
} from '@material-ui/core';
import {
  DonorType,
} from 'lib/utils';
import {
  translate,
} from 'lib/intl';
import {
  facebookIcon,
  inIcon,
  instagramIcon,
  twitterIcon,
} from 'assets/images';
import {
  EditDonorIcon,
} from './edit-donor-icon';
import {
  InputSectionWrapper,
} from './input-section-wrapper';
import {
  DonorTextInput,
} from './donor-text-input';

const SOCIAL_INPUTS_CONFIG = [
  {
    id: 'instagram',
    icon: <EditDonorIcon src={instagramIcon} />,
    label: translate('INSTAGRAM_LABEL'),
  },
  {
    id: 'twitter',
    icon: <EditDonorIcon src={twitterIcon} />,
    label: translate('TWITTER_LABEL'),
  },
  {
    id: 'linkedin',
    icon: <EditDonorIcon src={inIcon} />,
    label: translate('LINKEDIN_LABEL'),
  },
  {
    id: 'facebook',
    icon: <EditDonorIcon src={facebookIcon} />,
    label: translate('FACEBOOK_LABEL'),
  },
];

const SocialInputs = ({
  data,
  path,
  updateData,
}) => {
  const inputs = useMemo(() => SOCIAL_INPUTS_CONFIG?.map(({
    id,
    icon,
    label,
  }) => (
    <InputSectionWrapper
      key={id}
      leftColumnContent={icon}
    >
      <DonorTextInput
        data={data}
        path={path ? `${path}.${id}` : id}
        label={label}
        updateData={updateData}
      />
    </InputSectionWrapper>
  )), [data]);

  return (
    <Box>
      {inputs}
    </Box>
  );
};

SocialInputs.propTypes = {
  data: DonorType.isRequired,
  path: string.isRequired,
  updateData: func.isRequired,
};

export {
  SocialInputs,
};
