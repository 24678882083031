import {
  DEFAULT_LOCALES,
} from 'translations/locales';

const defaultFormatterProps = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
};

// Creates currency formatters
const currencyFormatter = new Intl.NumberFormat(DEFAULT_LOCALES, defaultFormatterProps);

const fractionCurrencyFormatter = new Intl.NumberFormat(DEFAULT_LOCALES, {
  ...defaultFormatterProps,
  minimumFractionDigits: 2,
});

// formats a numeric value to be a string with the following format: $2,500 or $2,500.25
const formatCurrency = (val = '0') => {
  const value = Number(val);

  return value % 1 === 0
    ? currencyFormatter.format(value)
    : fractionCurrencyFormatter.format(value);
};

export {
  currencyFormatter,
  fractionCurrencyFormatter,
  formatCurrency,
};
