import {
  createApiRequest,
} from 'lib/utils';

const fetchDonorInfo = (orgPubId, donorPubId) => createApiRequest({
  method: 'get',
  path: `orgs/${orgPubId}/donors/${donorPubId}`,
});

export const donorInfoService = {
  fetchDonorInfo,
};
