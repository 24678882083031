import {
  FeedTypes,
} from 'lib/types';
import {
  camelCaseKeys,
} from 'lib/utils';

const apiDelay = 1000;
const createFeedPage = (pageObject, resolve) => {
  const { page, pageSize } = pageObject.page;
  const startingId = (page - 1) * pageSize + 1;
  const endingId = startingId + pageSize;
  const items = [];
  let i;

  for (i = startingId; i < endingId; i += 1) {
    const feedItem = {
      // Modeled after https://github.com/dotorg-tech/planning/wiki/The-Feed as per 9/29/2020
      feed_item_pub_id: i,
      datetime: '2020-09-09T18:50:22.364148+00:00',
      // eslint-disable-next-line no-bitwise
      feed_item_content_type: FeedTypes[Math.random() * FeedTypes.length | 0],
      feed_item_content_pub_id: 'intZG9uQm1wd2ctZkNUakdiMEd1TnJyU1llUSMyMDIwMDkyMTIwMzIzMCNzdWIw',
      pinned: Math.random() >= 0.5,
      content: [
        {
          display_order: 0,
          type: 'markdown',
          // TODO SOW3/4 custom types will be implemented
          // text: "[DONOR]{pub_id:donBG9uQm1}<Kate Johnson> :smile:
          text: '### This is a header\nAnd this is a paragraph\n>block quote with link here: [DotOrg](https://home.dev.dotorg.dev/)',
        },
      ],
    };

    items.push(camelCaseKeys(feedItem));
  }

  return resolve({ items, page });
};

const mockApiRequest = page => (
  new Promise(resolve => (
    setTimeout(() => { createFeedPage(page, resolve); }, apiDelay)
  ))
);

// TODO replace with createApiRequest and real API when ready
const getFeed = page => camelCaseKeys(
  mockApiRequest({
    page,
    path: 'feed/items',
  }),
);

export const feedService = {
  getFeed,
};
