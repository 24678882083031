import React from 'react';
import {
  bool,
  func,
  node,
  string,
} from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import {
  HeadingWithIcon,
  FullWidthMobileButton,
} from 'components';
import {
  EMPTY_FUNC,
} from 'lib/constants';
import {
  successIcon,
  uncertaintyIcon,
} from 'assets/images';
import {
  translate,
} from 'lib/intl';

const StatusDialog = ({
  color,
  children,
  onClose,
  onExited,
  open,
  isSuccessMessage,
  message,
  title,
}) => (
  <Dialog
    aria-labelledby="status-dialog-title"
    maxWidth="xs"
    onClose={onClose}
    onExited={onExited}
    open={open}
  >
    <DialogTitle id="status-dialog-title">
      <HeadingWithIcon
        iconSrc={isSuccessMessage ? successIcon : uncertaintyIcon}
        title={title}
      />
    </DialogTitle>
    <DialogContent>
      <Typography variant="body1">{message}</Typography>
      {children}
    </DialogContent>
    <Box
      alignItems="flex-end"
      display="flex"
      flexDirection="column"
      px={2}
      py={3}
    >
      <FullWidthMobileButton color={color} onClick={onClose}>
        {translate('GOT_IT')}
      </FullWidthMobileButton>
    </Box>
  </Dialog>
);

StatusDialog.propTypes = {
  open: bool,
  color: string,
  children: node,
  onClose: func,
  onExited: func,
  isSuccessMessage: bool,
  message: string,
  title: string.isRequired,
};

StatusDialog.defaultProps = {
  color: 'primary',
  children: null,
  open: false,
  onClose: EMPTY_FUNC,
  onExited: EMPTY_FUNC,
  message: '',
  isSuccessMessage: true,
};

export {
  StatusDialog,
};
