import React, {
  useMemo,
} from 'react';
import {
  DonorType,
  isMobileView,
} from 'lib/utils';
import {
  Box,
  Link,
} from '@material-ui/core';
import {
  SOCIAL_ACCOUNTS,
} from 'lib/constants';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
} from './donor-icons';

const SOCIAL_ACCOUNTS_ICONS = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  linkedin: LinkedInIcon,
  twitter: TwitterIcon,
};

const SocialAccounts = (data) => {
  const isMobile = isMobileView();

  const Accounts = useMemo(() => SOCIAL_ACCOUNTS?.map((account) => {
    const link = data[account];

    if (link) {
      return (
        <Link
          data-testid={`social-account-${account}`}
          href={link}
          key={account}
          rel="noopener noreferrer"
          target="_blank"
        >
          {SOCIAL_ACCOUNTS_ICONS[account]}
        </Link>
      );
    }

    return null;
  }), [data]);

  return (
    <Box
      display="flex"
      flexDirection={isMobile ? 'row' : 'column'}
      height="100%"
      justifyContent="space-evenly"
    >
      {Accounts}
    </Box>
  );
};

SocialAccounts.propTypes = DonorType.social;

SocialAccounts.defaultProps = {
  facebook: '',
  instagram: '',
  linkedin: '',
  twitter: '',
};

export {
  SocialAccounts,
};
