import React from 'react';
import {
  AuthContainer,
  RegistrationByInviteForm,
} from 'components';
import {
  translate,
} from 'lib/intl';
import {
  PUBLIC_ROUTES,
} from 'lib/constants';
import {
  useRegistration,
} from './hooks';

const RegistrationByInvitePage = () => {
  const {
    code,
    email,
    orgName,
    registerAction,
    resetRegistrationState,
    isFetching,
    isRegistrationSuccessful,
    error,
  } = useRegistration();

  return (
    <AuthContainer
      size="md"
      navLinkText={translate('SIGN_IN_INSTEAD')}
      navLinkRoute={PUBLIC_ROUTES.LOGIN}
      orgName={orgName}
    >
      <RegistrationByInviteForm
        code={code}
        email={email}
        orgName={orgName}
        registerAction={registerAction}
        resetRegistrationState={resetRegistrationState}
        isFetching={isFetching}
        isRegistrationSuccessful={isRegistrationSuccessful}
        error={error}
      />
    </AuthContainer>
  );
};

export {
  RegistrationByInvitePage,
};
