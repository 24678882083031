import {
  createIntl, createIntlCache,
} from 'react-intl';
import {
  TRANSLATIONS,
} from 'translations';

const cache = createIntlCache();

const DEFAULT_LOCALE = 'en-US';

const intl = createIntl({
  defaultLocale: DEFAULT_LOCALE,
  locale: navigator.language,
  messages: TRANSLATIONS[navigator.language]?.length
    ? TRANSLATIONS[navigator.language]
    : TRANSLATIONS[DEFAULT_LOCALE],
}, cache);

export {
  intl,
};
