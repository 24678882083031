import React from 'react';
import {
  bool,
  node,
  string,
} from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';
import {
  colors,
} from 'styles/theme';

const StyledAccordion = styled(Accordion)`
  border-bottom: 1px solid ${colors.lightGrey};
  box-shadow: none;
  padding: 0;

  &:before {
    display: none;
  }

  &.Mui-expanded {
    margin: 0px;
  }

  .MuiAccordionSummary-root {
    padding: 0px 24px;
  }

  .MuiAccordionDetails-root {
    padding: 0px 94px 24px 0px;
  }
`;

const EditDonorSectionWrapper = ({
  id,
  children,
  defaultExpanded,
  title,
}) => (
  <StyledAccordion
    data-testid={id}
    defaultExpanded={defaultExpanded}
    square
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={id}
      id={id}
    >
      <Typography>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Box flex="1">
        {children}
      </Box>
    </AccordionDetails>
  </StyledAccordion>
);

EditDonorSectionWrapper.propTypes = {
  id: string.isRequired,
  children: node.isRequired,
  defaultExpanded: bool,
  title: string.isRequired,
};

EditDonorSectionWrapper.defaultProps = {
  defaultExpanded: false,
};

export {
  EditDonorSectionWrapper,
};
