import { colors } from 'styles/theme';

const authFormButtonStyles = {
  borderRadius: '200px',
  height: '48px',
  width: '215px',
};

const authNavLinkStyle = {
  fontSize: '14px',
  fontWeight: '500',
  textDecoration: 'none',
  color: 'black',
  borderBottom: '1px solid',
  borderColor: colors.primaryGreen,
  cursor: 'pointer',
  paddingTop: '10px',
};

const errorPageIconStyle = {
  display: 'flex',
  justifyContent: 'center',
  width: '160px',
  height: '160px',
};

export {
  authFormButtonStyles,
  authNavLinkStyle,
  errorPageIconStyle,
};
