import React from 'react';
import AuthContainer from 'components/auth/auth-container';
import env from '@beam-australia/react-env';
import {
  Grid, Typography,
} from '@material-ui/core';
import {
  thinkingEmoji,
} from 'assets/images';
import {
  authNavLinkStyle,
  errorPageIconStyle,
} from 'components/auth/styles';

const NotFoundErrorPage = () => (
  <AuthContainer size="md" navLinkText="Home" navLinkRoute="/">
    <Grid container>
      <Grid item sm={6} xs={12}>
        <Typography variant="h5">Oops!</Typography>
        <Typography variant="h6" style={{ marginTop: '30px' }}>Looks like there&apos;s nothing here.</Typography>
        <Typography variant="body1" style={{ marginTop: '30px' }}>
          Searching for help? Please&nbsp;
          <a href={`mailto:${env('HELP_LINK_ADDRESS')}`} style={authNavLinkStyle}>Contact Us</a>
        </Typography>
      </Grid>
      <Grid item sm={6} xs={12} style={errorPageIconStyle}>
        <img alt="Thinking emoji" src={thinkingEmoji} />
      </Grid>
    </Grid>
  </AuthContainer>
);

export {
  NotFoundErrorPage,
};
