import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, makeStyles, List, ListItem, ListItemAvatar, Menu, Fade,
} from '@material-ui/core';
import {
  homeIcon,
  donorIcon,
  campaignIcon,
  reportsIcon,
  phoneIcon,
  settingsIcon,
} from 'assets/images';
import {
  AUTH_ROUTES,
} from 'lib/constants';
import {
  Avatar,
  NavigationListItem,
  UserProfileBottomLinks,
  UserProfileSignOutButton,
  UserProfileUpper,
} from 'components';

const {
  LANDING,
  INVITE,
  DONORS,
} = AUTH_ROUTES;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '72px',
    background: theme.palette.background.light,
    boxShadow: `inset -2px 0 3px -2px ${theme.palette.grey.light}`,
  },
  contentContainer: {
    flexGrow: 1,
  },
  avatar: {
    background: 'none',
  },
}));

const DesktopNavigation = ({
  children,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.navContainer}>
        <List component="nav" aria-label="upper-main">
          <ListItem button>
            <ListItemAvatar>
              <Avatar
                data-testid="navigation-org-id-icon"
                alt="OrgIcon"
                src="orgIcon"
              />
            </ListItemAvatar>
          </ListItem>
          <NavigationListItem navRoute={LANDING} exact icon={homeIcon} iconAlt="Home Icon" />
          <NavigationListItem navRoute={DONORS} icon={donorIcon} iconAlt="Donor Icon" />
          <NavigationListItem navRoute="/campaigns" icon={campaignIcon} iconAlt="Campaign Icon" />
          <NavigationListItem navRoute="/reports" icon={reportsIcon} iconAlt="Report Icon" />
        </List>
        <List component="nav" aria-label="lower-main">
          <NavigationListItem
            navRoute="/contacts"
            icon={phoneIcon}
            iconAlt="Phone Icon"
          />
          <NavigationListItem
            navRoute={INVITE}
            icon={settingsIcon}
            iconAlt="Settings Icon"
          />
          <ListItem
            button
            onClick={handleMenu}
          >
            <Avatar alt="UserAvatar" src="userAvatarImage" />
          </ListItem>
        </List>
      </Box>
      <Box className={classes.contentContainer}>
        { children }
      </Box>
      <Menu
        data-testid="desktop-navigation-menu-button"
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        style={{
          width: '288px',
        }}
        getContentAnchorEl={null}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <UserProfileUpper />
        <UserProfileSignOutButton />
        <UserProfileBottomLinks />
      </Menu>
    </Box>
  );
};

DesktopNavigation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

DesktopNavigation.defaultProps = {
  children: null,
};

export default DesktopNavigation;
