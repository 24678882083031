import React, { useState } from 'react';
import {
  TextField, Grid, Box,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import userActionTypes from 'rdx/actionTypes';
import { EMPTY_FUNC } from 'lib/constants';
import {
  authNavLinkStyle,
} from './styles';
import {
  phoneNumberRegex,
} from './constants';

const einFieldValue = 'EIN';
const phoneNumberFieldValue = 'phoneNumber';

const registerEIN = (register) => {
  register(
    { name: einFieldValue },
    {
      required: 'Employer ID Number Required.',
      pattern: {
        value: /^\d{2}-?\d{7}$/,
        message: 'Improper EIN Formatting',
      },
    },
  );
};

const registerPhoneNumber = (register) => {
  register(
    { name: phoneNumberFieldValue },
    {
      required: 'Phone Number Required',
      pattern: {
        value: phoneNumberRegex,
        message: 'Invalid Phone Number',
      },
    },
  );
};

const isRegistered = (fieldValue, values) => !!values[fieldValue];

const OrgIdForm = ({
  register, unregister, errors, setValue, clearErrors, trigger, values,
}) => {
  const [usingFormEIN, setUsingFormEIN] = useState(true);
  const toggleEIN = () => {
    setUsingFormEIN(!usingFormEIN);
  };
  const dispatch = useDispatch();

  const einValue = useSelector(state => state.registration.einValue);
  const phoneNumberValue = useSelector(state => state.registration.phoneNumberValue);

  // EIN input change handler
  function onEinValueChanged(value) {
    dispatch({ type: userActionTypes.REGISTER_FORM_EIN_VALUE, einValue: value });

    clearErrors(einFieldValue);
    setValue(einFieldValue, value);
  }

  // Phone number input change handler
  function onPhoneNumberValueChanged(value) {
    dispatch({ type: userActionTypes.REGISTER_FORM_PHONE_VALUE, phoneNumberValue: value });

    clearErrors(phoneNumberFieldValue);
    setValue(phoneNumberFieldValue, value);
  }

  const customizedAuthNavLinkStyle = {
    ...authNavLinkStyle,
    marginLeft: '10px',
    textAlign: 'left',
  };

  const renderEINOrPhoneNumber = () => {
    if (usingFormEIN) {
      if (!isRegistered(einFieldValue, values)) {
        registerEIN(register);
        setValue(einFieldValue, einValue);
        if (einValue) {
          trigger(einFieldValue);
        }
      }
      unregister(phoneNumberFieldValue);

      return (
        <React.Fragment>
          <Grid item xs={10}>
            <InputMask
              mask="99-9999999"
              maskChar=" "
              onChange={event => onEinValueChanged(event.target.value)}
              onBlur={() => trigger(einFieldValue)}
              value={einValue}
            >
              {() => (
                <TextField
                  name={einFieldValue}
                  error={!!errors[einFieldValue]}
                  helperText={errors[einFieldValue] ? errors[einFieldValue].message : ''}
                  fullWidth
                  label="Employer ID Number"
                  variant="outlined"
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-end" width="100%">
              <a
                data-testid="org-value-toggle-button"
                role="button"
                tabIndex={0}
                onClick={toggleEIN}
                onKeyDown={EMPTY_FUNC}
                style={customizedAuthNavLinkStyle}
              >
                No EIN?
              </a>
            </Box>
          </Grid>
        </React.Fragment>
      );
    }

    if (!isRegistered(phoneNumberFieldValue, values)) {
      registerPhoneNumber(register);
      setValue(phoneNumberFieldValue, phoneNumberValue);

      if (phoneNumberValue) {
        trigger(phoneNumberFieldValue);
      }
    }
    unregister(einFieldValue);

    return (
      <React.Fragment>
        <Grid item xs={10}>
          <PhoneInput
            fullWidth
            containerStyle={{ width: '100%' }}
            containerClass="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth"
            inputStyle={{ width: '100%', borderRadius: '8px', height: '56px' }}
            inputClass={errors && errors.phoneNumber ? 'react-phone-input-error' : ''}
            country="us"
            countryCodeEditable={false}
            onChange={number => onPhoneNumberValueChanged(number)}
            onBlur={() => trigger(phoneNumberFieldValue)}
            inputProps={{
              name: phoneNumberFieldValue,
            }}
            value={phoneNumberValue}
          />
          {errors && errors.phoneNumber && errors.phoneNumber
            ? <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{errors.phoneNumber.message}</p>
            : null
          }
        </Grid>
        <Grid item xs={2}>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <a
              data-testid="org-value-toggle-button"
              role="button"
              tabIndex={0}
              onClick={toggleEIN}
              onKeyDown={EMPTY_FUNC}
              style={customizedAuthNavLinkStyle}
            >
              Use EIN
            </a>
          </Box>
        </Grid>
      </React.Fragment>
    );
  };

  return renderEINOrPhoneNumber();
};

export { OrgIdForm };
