import {
  createApiRequest,
} from 'lib/utils';

const sendInvites = (ownerOrgPubId, data) => createApiRequest({
  data,
  path: `orgs/${ownerOrgPubId}/invites/bulk-create`,
  useTest: true,
});

const fetchInvites = ownerOrgPubId => createApiRequest({
  path: `orgs/${ownerOrgPubId}/invites`,
  method: 'get',
});

const updateInvite = (invitePubId, data) => createApiRequest({
  data,
  path: `invites/${invitePubId}`,
  method: 'put',
});

export const invitesService = {
  fetchInvites,
  sendInvites,
  updateInvite,
};
