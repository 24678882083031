import React, {
  useMemo,
} from 'react';
import {
  string,
  func,
} from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  Chip,
} from '@material-ui/core';
import {
  EMPTY_FUNC,
} from 'lib/constants';
import {
  isValidEmail,
} from 'lib/utils';
import {
  colors,
} from 'styles/theme';
import {
  DeleteIcon,
} from 'assets/images';

const DeleteIconComponent = <DeleteIcon data-testid="delete-email-icon" />;

const StyledEmailChip = styled(Box)`
  .MuiChip-outlined.MuiChip-outlinedPrimary {
    background-color: ${colors.transparentError};
    border-color: ${colors.errorMain};
    color: ${colors.black};
  }

  .MuiChip-outlined.MuiChip-deletable .MuiChip-deleteIcon {
    height: 12px;
    margin-right: 10px;
    width: 12px;
  }
`;

const EmailChip = ({
  email,
  handleDelete,
}) => {
  const color = useMemo(() => (isValidEmail(email) ? 'default' : 'primary'), [email]);

  return (
    <StyledEmailChip mr={1} mb={1}>
      <Chip
        color={color}
        deleteIcon={DeleteIconComponent}
        label={email}
        onDelete={handleDelete}
        variant="outlined"
      />
    </StyledEmailChip>
  );
};

EmailChip.propTypes = {
  email: string,
  handleDelete: func,
};

EmailChip.defaultProps = {
  email: '',
  handleDelete: EMPTY_FUNC,
};

export {
  EmailChip,
};
