import React from 'react';
import {
  Box,
} from '@material-ui/core';
import {
  translate,
} from 'lib/intl';
import {
  mapAddress,
  mapPhoneNumber,
  mapSingleValue,
  mapEmail,
  mapWebsite,
} from 'lib/mappers';
import {
  ViewFields,
} from 'components';
import {
  DonorType,
  isMobileView,
} from 'lib/utils';
import {
  BuildingIcon,
  JobIcon,
  JobPositionIcon,
  WorkPhoneIcon,
  EmailIcon,
  WebIcon,
} from './donor-icons';
import {
  DonorSectionTitle,
} from './donor-section-title';
import {
  SocialAccounts,
} from './social-accounts';
import {
  DonorSectionWrapper,
} from './donor-section-wrapper';

const WorkInformation = ({
  donor,
}) => {
  const isMobile = isMobileView();

  const {
    addresses,
    emails,
    employer,
    phones,
  } = donor;

  return (
    <DonorSectionWrapper>
      <DonorSectionTitle>
        {translate('DONOR_WORK_INFORMATION')}
      </DonorSectionTitle>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={isMobile ? 'column' : 'row'}
        flexWrap="wrap"
      >
        <Box pr={2}>
          <ViewFields
            icon={JobIcon}
            data={mapSingleValue(employer?.employerName)}
          />
          <ViewFields
            icon={EmailIcon}
            data={mapEmail(emails, ['work'])}
          />
          <ViewFields
            icon={BuildingIcon}
            data={mapAddress(addresses?.work)}
          />
        </Box>
        <Box pr={2}>
          <Box>
            <ViewFields
              icon={JobPositionIcon}
              data={mapSingleValue(employer?.jobTitle)}
            />
            <ViewFields
              icon={WorkPhoneIcon}
              data={mapPhoneNumber(phones, ['work'])}
            />
            <ViewFields
              icon={WebIcon}
              data={mapWebsite(employer?.employerWebsite)}
            />
          </Box>
        </Box>
        <Box pt={isMobile ? 3 : 0}>
          <SocialAccounts {...employer} />
        </Box>
      </Box>
    </DonorSectionWrapper>
  );
};

WorkInformation.propTypes = {
  donor: DonorType,
};

WorkInformation.defaultProps = {
  donor: {},
};

export {
  WorkInformation,
};
