import axios from 'axios';
import env from '@beam-australia/react-env';
import {
  camelCaseKeys,
  snakeCaseKeys,
  upperCase,
} from 'lib/utils';
import {
  AUTH_USER_ID_TOKEN,
  AUTH_X_DOTORG,
} from 'lib/constants';
import {
  userService,
} from 'services/user';
import {
  translate,
} from 'lib/intl';

const refreshRetryLimit = 5;
const retryRequestTimeout = 2000;
const handleData = ({ data }) => data;
const handleErrors = (error) => {
  let formattedError;

  try {
    formattedError = error.toJSON();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Failed to format JSON', error);
    formattedError = translate('GENERIC_ERROR');
  }

  throw new Error(formattedError);
};
const formatAuthBearerHeader = accessToken => (`Bearer ${accessToken}`);

axios.interceptors.response.use(null, (error) => {
  if (error.config && error.response && error.response.status === 401) {
    return userService.refreshUserSession().then((tokenResponse) => {
      const { token } = tokenResponse;

      if (token) {
        const { config } = error;
        const { attempts } = config;
        config.headers.Authorization = formatAuthBearerHeader(token);
        config.attempts = attempts ? attempts + 1 : 1;

        if (config.attempts >= refreshRetryLimit) {
          // Log user out if too many failed retries
          // eslint-disable-next-line no-console
          console.warn('Too many failed attempts, logging user out.');
          return userService.logout().then(logoutError => Promise.reject(logoutError));
        }

        if (config.attempts > 0) {
          setTimeout(() => axios.request(config), retryRequestTimeout);
        } else {
          // Retry the request with the updated authorization header
          return axios.request(config);
        }
      }

      return Promise.reject(error);
    })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.warn('Refresh user session attempt failed', err);
      });
  }

  return Promise.reject(error);
});

const createApiRequest = ({
  config,
  data,
  method = 'post',
  postProcessMethod = camelCaseKeys,
  preProcessMethod = snakeCaseKeys,
  path,
  useAuth = true,
} = {}) => {
  if (!method || !path) {
    throw new Error('Invalid URL or Method');
  }

  const requestConfig = { ...config };

  const authToken = localStorage.getItem(AUTH_USER_ID_TOKEN);
  const dotOrgXAuthToken = localStorage.getItem(AUTH_X_DOTORG);
  const headers = {};

  if (useAuth && authToken) {
    headers.Authorization = formatAuthBearerHeader(authToken);
  }

  // This header is required on all API requests for user informational purposes
  if (dotOrgXAuthToken) {
    headers['X-Dotorg-Authorization'] = dotOrgXAuthToken;
  }

  if (typeof requestConfig === 'object') {
    requestConfig.headers = headers;
  }

  const isProd = process.env.NODE_ENV === 'production';
  const apiPath = isProd ? `${env('API_URL')}${env('API_PREFIX')}/${path}` : `${env('API_PREFIX')}/${path}`;
  const requestParams = [apiPath, requestConfig];

  if (['POST', 'PATCH', 'PUT', 'DELETE'].includes(upperCase(method))) {
    requestParams.splice(1, 0, preProcessMethod ? preProcessMethod(data) : data);
  }

  return axios[method](...requestParams)
    .then(result => postProcessMethod(handleData(result)))
    .catch(error => handleErrors(error));
};

export {
  createApiRequest,
  handleData,
  handleErrors,
};
