import React from 'react';
import {
  arrayOf,
  bool,
  func,
  node,
  shape,
  string,
} from 'prop-types';
import {
  DonorType,
} from 'lib/utils';
import {
  EMPTY_FUNC,
} from 'lib/constants';
import {
  EditDonorMultipleInputs,
} from './edit-donor-multiple-inputs';
import {
  DonorTextInput,
} from './donor-text-input';
import {
  InputSectionWrapper,
} from './input-section-wrapper';

const SectionsGenerator = ({
  config,
  data,
  errors,
  setErrors,
  showErrors,
  updateData,
}) => config?.map(({
  icon,
  useMultipleInputs,
  ...rest
}, idx) => {
  const Component = useMultipleInputs
    ? EditDonorMultipleInputs
    : (rest.Component || DonorTextInput);

  return (
    <InputSectionWrapper
      // eslint-disable-next-line
      key={idx}
      leftColumnContent={icon}
    >
      <Component
        data={data}
        errors={errors}
        setErrors={setErrors}
        showErrors={showErrors}
        updateData={updateData}
        {...rest}
      />
    </InputSectionWrapper>
  );
});

SectionsGenerator.propTypes = {
  config: arrayOf(shape({
    key: string.isRequired,
    Component: func,
    defaultValue: shape({
      dataPath: string,
      type: string,
    }),
    icon: node,
    label: string,
    path: string,
    required: bool,
    types: arrayOf(shape({
      label: string,
      value: string,
    })),
    useMultipleInputs: bool,
    validate: func,
  })).isRequired,
  data: DonorType.isRequired,
  errors: arrayOf(string),
  setErrors: func,
  showErrors: bool,
  updateData: func.isRequired,
};

SectionsGenerator.defaultProps = {
  errors: [],
  setErrors: EMPTY_FUNC,
  showErrors: false,
};

export {
  SectionsGenerator,
};
