import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  arrayOf,
  func,
  oneOf,
  shape,
  string,
} from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  Tabs,
  Tab,
} from '@material-ui/core';
import {
  colors,
} from 'styles/theme';
import {
  EMPTY_FUNC,
  ZERO,
} from 'lib/constants';

const StyledPageTabs = styled(Box)`
  .MuiTab-textColorPrimary.Mui-selected {
    color: ${colors.black};
  }
`;

const PageTabs = ({
  onChange,
  tabs,
  variant,
}) => {
  const [value, setValue] = useState(ZERO);

  const handleChange = useCallback((_event, newValue) => {
    setValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  }, []);

  const RenderedTabs = useMemo(() => tabs?.map(({ id, label }, idx) => (
    <Tab data-testid={`page-tab-${idx}`} label={label} key={id} />
  )), [tabs]);

  return (
    <StyledPageTabs>
      <Tabs
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        value={value}
        variant={variant}
      >
        {RenderedTabs}
      </Tabs>
    </StyledPageTabs>
  );
};

PageTabs.propTypes = {
  onChange: func,
  tabs: arrayOf(shape({
    id: string,
    label: string,
  })),
  variant: oneOf(['fullWidth', 'standard', 'scrollable']),
};

PageTabs.defaultProps = {
  onChange: EMPTY_FUNC,
  tabs: [],
  variant: 'standard',
};

export {
  PageTabs,
};
