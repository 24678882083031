import {
  useEffect,
  useState,
} from 'react';
import {
  useLocation,
} from 'react-router-dom';
import {
  useDispatch,
} from 'react-redux';
import userActionTypes from 'rdx/actionTypes';

// Keeps track of last two paths - the current one and the previous one
// Returns the previous path
const usePreviousLocation = (newPath) => {
  const [
    history,
    setHostiry,
  ] = useState(['']);

  useEffect(() => {
    setHostiry([
      ...history,
      newPath,
    ].slice(-2));
  }, [newPath]);

  return history[0];
};

// Saves prev location to rdx store
const useRoutesWrapper = () => {
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const setLocationState = path => dispatch({ type: userActionTypes.SET_LOCATION, prevPath: path });

  const prevPath = usePreviousLocation(pathname);

  useEffect(() => {
    setLocationState(prevPath);
  }, [prevPath]);
};

export {
  useRoutesWrapper,
};
