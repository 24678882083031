import React, {
  useMemo,
} from 'react';
import {
  string,
} from 'prop-types';
import {
  prop,
} from 'rambdax';
import styled from 'styled-components';
import {
  isMobileView,
} from 'lib/utils';

const StyledIcon = styled.img`
  height: ${prop('size')};
  width: ${prop('size')};
`;

const EditDonorIcon = ({
  src,
}) => {
  const isMobile = isMobileView();

  const size = useMemo(() => (isMobile ? '16px' : '24px'), [isMobile]);

  return (
    <StyledIcon size={size} src={src} />
  );
};

EditDonorIcon.propTypes = {
  src: string.isRequired,
};

export {
  EditDonorIcon,
};
