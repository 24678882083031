import React from 'react';
import { useSelector } from 'react-redux';
import {
  makeStyles, Typography, Box, Link, Grid,
} from '@material-ui/core';
import {
  authNavLinkStyle,
} from 'components/auth/styles';
import {
  Avatar,
} from 'components';

// eslint-disable-next-line no-empty-pattern
const UserProfileUpper = React.forwardRef(({}, ref) => {
  const userSession = useSelector(state => state.authentication.user) || {};

  const { user: { displayName = '', email = '' } = {} } = userSession;
  const useStyles = makeStyles(theme => ({
    upperContainer: {
      padding: '26px 26px 16px',
      [theme.breakpoints.down('xs')]: {
        padding: '8px 16px 24px',
      },
    },
    emailText: {
      margin: theme.spacing(2),
      marginTop: 0,
    },
  }));
  const classes = useStyles();

  return (
    <Grid
      data-testid="user-profile-upper"
      ref={ref}
      container
      className={classes.upperContainer}
    >
      <Grid
        item
        xs={12}
        style={{
          paddingBottom: '8px',
        }}
      >
        <Avatar
          alt={displayName}
          // TODO add actual image once available
          size="56px"
          src="UserProfileImage"
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography
          className={classes.link}
          variant="subtitle1"
          style={{
            fontWeight: 500,
          }}
        >
          {displayName}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          paddingBottom: '8px',
        }}
      >
        <Typography
          variant="subtitle2"
        >
          {email}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="inline-block"
          color="primary.main"
          borderColor="primary.main"
          borderBottom={1}
        >
          <Link
            style={{
              ...authNavLinkStyle,
              fontSize: '16px',
            }}
          >
            Edit profile
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
});

export {
  UserProfileUpper,
};
