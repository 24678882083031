const PUBLIC_ROUTES = {
  LOGIN: '/login',
  LOGIN_EXPIRED: '/login/expired',
  VERIFICATION: '/login/verification',
  VERIFICATION_ERROR: '/login/verification-error',
  REGISTRATION: '/register',
  REGISTRATION_FROM_INVITE: '/register/invite',
  REGISTRATION_SUCCESS: '/register/success',
  REGISTRATION_VERIFICATION_ERROR: '/register/verification-error',
  REGISTRATION_INVITE_VERIFICATION_ERROR: '/register/invite-verification-error',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_REQUEST_SUCCESS: '/reset-password/success',
  RESET_PASSWORD_SET_PASSWORD: '/reset-password/submit',
  RESET_PASSWORD_SET_PASSWORD_SUCCESS: '/reset-password/submit/success',
};

const AUTH_ROUTES = {
  LANDING: '/',
  INVITE: '/invite',
  DONORS: '/donors',
  DONOR: '/donors/:id',
  FEED: '/feed/:id',
};

export {
  PUBLIC_ROUTES,
  AUTH_ROUTES,
};
