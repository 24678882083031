import React from 'react';
import { AuthContainer, ResetPasswordForm } from 'components';
import {
  translate,
} from 'lib/intl';
import {
  PUBLIC_ROUTES,
} from 'lib/constants';

const {
  LOGIN,
} = PUBLIC_ROUTES;

const ResetPasswordPage = () => (
  <AuthContainer size="sm" navLinkText={translate('BACK_TO_SIGN_IN')} navLinkRoute={LOGIN}>
    <ResetPasswordForm />
  </AuthContainer>
);

export {
  ResetPasswordPage,
};
