import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  useHistory,
} from 'react-router-dom';
import {
  isMobileView,
} from 'lib/utils';
import {
  DeleteIcon,
} from 'assets/images';
import {
  AUTH_ROUTES,
} from 'lib/constants';
import {
  FeedItemTitle,
} from './feed-item-title';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    padding: '25px',
  },
  deleteIcon: {
    position: 'absolute',
    right: '25px',
    top: '25px',
    cursor: 'pointer',
  },
}));

const FeedSlider = ({
  item,
  useOutsideAlerter,
}) => {
  const isMobile = isMobileView();
  const wrapperRef = useRef(null);
  const styles = useStyles();
  const history = useHistory();

  useOutsideAlerter(wrapperRef, history, isMobile);

  return (
    <Box
      ref={wrapperRef}
      className={styles.container}
    >
      <DeleteIcon
        className={styles.deleteIcon}
        onClick={() => history.push(AUTH_ROUTES.LANDING)}
      />
      <FeedItemTitle
        type={item.name}
        date={item.datetime.utc}
      />
      <Typography
        variant="body1"
      >
        {item?.body}
      </Typography>
    </Box>
  );
};

FeedSlider.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    datetime: PropTypes.string,
    body: PropTypes.string,
  }),
  useOutsideAlerter: PropTypes.func.isRequired,
};

FeedSlider.defaultProps = {
  item: {
    name: '',
    datetime: '',
    body: '',
  },
};

export {
  FeedSlider,
};
