import {
  format,
  formatDistanceToNow,
  parseISO,
  toDate,
} from 'date-fns';

const formatDistance = (date, props = { addSuffix: true }) => formatDistanceToNow(
  toDate(parseISO(date)),
  props,
);

const formatDate = (date, dateFormat = 'd MMM y') => format(parseISO(date), dateFormat);

const formatDateObject = (date, dateFormat = 'd MMM y') => format(date, dateFormat);

export {
  formatDate,
  formatDateObject,
  formatDistance,
};
