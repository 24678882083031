import React, { useState } from 'react';
import {
  Grid,
  Typography,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  makeStyles,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  validationCaseContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  typographySubTitleOverride: {
    fontSize: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
}));

const PasswordForm = ({
  register, errors = {},
}) => {
  const styles = useStyles();
  const [password, setPassword] = useState({
    value: '',
    showPassword: false,
    hasCharacterCount: null,
    hasNumber: null,
    hasUpper: null,
    hasLower: null,
    hasSpecial: null,
  });

  // Check password validation, update password validation state
  const isValidPassword = (value) => {
    const hasCharacterCount = value.length > 10;
    const hasNumber = /[0-9]/.test(value);
    const hasUpper = /[A-Z]/.test(value);
    const hasLower = /[a-z]/.test(value);
    const hasSpecial = /_|[^\w]/.test(value);

    setPassword({
      ...password,
      value,
      hasCharacterCount,
      hasNumber,
      hasUpper,
      hasLower,
      hasSpecial,
    });

    return hasCharacterCount && hasNumber && hasUpper && hasLower && hasSpecial;
  };

  const handlePasswordChange = () => (event) => {
    isValidPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const renderPasswordValidationCase = (text, isValid) => {
    const iconStyles = { fontSize: '1rem', paddingRight: '4px' };

    // Pristine state
    let icon = <ClearIcon color="disabled" style={iconStyles} />;

    if (isValid) {
      icon = <CheckIcon data-testid="password-condition-success" color="primary" style={iconStyles} />;
    } else if (isValid === false) {
      icon = <ClearIcon data-testid="password-condition-error" style={{ ...iconStyles, color: 'red' }} />;
    }

    return (
      <Grid
        className={styles.validationCaseContainer}
        item
        container
        xs={6}
      >
        {icon}
        <Typography
          variant="caption"
          className={styles.typographySubTitleOverride}
        >
          {text}
        </Typography>
      </Grid>
    );
  };

  const renderPasswordComponent = () => (
    <Grid item container display="flex" xs={12}>
      <FormControl
        fullWidth
        error={!!errors.password}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          name="password"
          id="outlined-adornment-password"
          fullWidth
          type={password.showPassword ? 'text' : 'password'}
          value={password.value}
          onChange={handlePasswordChange('password')}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {password.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )}
          labelWidth={75}
          inputRef={
            register({
              required: {
                value: true,
                message: 'Password Required',
              },
              validate: {
                isValidPassword: value => isValidPassword(value),
              },
            })
          }
        />
        {/* Only show text for password required, custom icon error state shows the rest */}
        {errors && errors.password && errors.password.type === 'required'
          ? (
            <p data-testid="password-form-error-state" className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
              {errors.password.message}
            </p>
          )
          : null
        }
      </FormControl>
      {renderPasswordValidationCase('At least 10 characters', password.hasCharacterCount)}
      {renderPasswordValidationCase('Contains a number', password.hasNumber)}
      {renderPasswordValidationCase('Contains uppercase', password.hasUpper)}
      {renderPasswordValidationCase('Contains a symbol', password.hasSpecial)}
      {renderPasswordValidationCase('Contains lowercase', password.hasLower)}
    </Grid>
  );

  return renderPasswordComponent();
};

export default PasswordForm;
