import {
  upperCase,
} from 'voca';

// a helper functions that returns initials
const getInitials = value => value?.split(' ')?.map(w => upperCase(w?.charAt(0)))?.join('') || '';

export {
  getInitials,
};
