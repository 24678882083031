import React from 'react';
import {
  node,
  string,
} from 'prop-types';
import {
  Box,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  isMobileView,
} from 'lib/utils';
import {
  Visible,
} from 'components';

const LeftColumn = styled(Box)`
  display: flex;
  justify-content: center;
  min-width: ${({ mobile }) => (mobile ? '80px' : '128px')};
  padding-top: ${({ mobile, pad }) => (pad || (mobile ? '30px' : '24px'))};
`;

const InputSectionWrapper = ({
  children,
  leftColumnContent,
  leftColumnTopPad,
}) => {
  const isMobile = isMobileView();

  return (
    <Box display="flex">
      <LeftColumn pad={leftColumnTopPad} mobile={Number(isMobile)}>
        <Visible when={leftColumnContent}>
          {leftColumnContent}
        </Visible>
      </LeftColumn>
      <Box flex="1">
        {children}
      </Box>
    </Box>
  );
};

InputSectionWrapper.propTypes = {
  children: node.isRequired,
  leftColumnContent: node,
  leftColumnTopPad: string,
};

InputSectionWrapper.defaultProps = {
  leftColumnContent: null,
  leftColumnTopPad: '',
};

export {
  InputSectionWrapper,
};
