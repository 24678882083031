import React from 'react';
import PropTypes from 'prop-types';
import { AuthContainer, ResetPasswordSubmitForm } from 'components';
import {
  translate,
} from 'lib/intl';
import {
  PUBLIC_ROUTES,
} from 'lib/constants';

const {
  LOGIN,
} = PUBLIC_ROUTES;

const ResetPasswordSetPasswordPage = ({
  location,
}) => (
  <AuthContainer size="sm" navLinkText={translate('BACK_TO_SIGN_IN')} navLinkRoute={LOGIN}>
    <ResetPasswordSubmitForm location={location} />
  </AuthContainer>
);

ResetPasswordSetPasswordPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export {
  ResetPasswordSetPasswordPage,
};
