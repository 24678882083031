import React from 'react';
import {
  AuthContainer,
  LoginForm,
} from 'components';
import {
  translate,
} from 'lib/intl';
import {
  PUBLIC_ROUTES,
} from 'lib/constants';
import {
  useLogin,
} from './hooks';

const {
  REGISTRATION,
} = PUBLIC_ROUTES;

const LoginPage = () => {
  const {
    isFetching,
    error,
    loginAction,
    clearLoginErrors,
  } = useLogin();

  return (
    <AuthContainer
      size="sm"
      navLinkText={translate('REGISTER_INSTEAD')}
      navLinkRoute={REGISTRATION}
    >
      <LoginForm
        isFetching={isFetching}
        error={error}
        loginAction={loginAction}
        clearLoginErrors={clearLoginErrors}
      />
    </AuthContainer>
  );
};

export {
  LoginPage,
};
