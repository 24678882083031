import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  object,
  string,
} from 'prop-types';
import styled from 'styled-components';
import {
  TextField,
} from '@material-ui/core';
import {
  assocPath,
  path as getByPath,
  update,
  uniq,
} from 'rambdax';
import {
  DonorType,
} from 'lib/utils';
import {
  colors,
} from 'styles/theme';

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    background: none;
  
    &:before {
      border-bottom: 1px solid ${colors.grey500};
    }
  }
  
  .MuiInputLabel-filled {
    transform: translate(0px, 30px) scale(1);
  }
  
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(0px, 10px) scale(0.75);
  }
  
  .MuiFilledInput-input {
    border-radius: 0px;
    padding: 28px 12px 4px 0px;
  }
`;

// TODO: @anna - check if I need arrays here at all
const DonorTextInput = ({
  data,
  errors,
  idx,
  label,
  newValueMapper,
  path,
  required,
  showErrors,
  setErrors,
  updateData,
  valueMapper,
  validate,
  width,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  const isArray = typeof idx === 'number';
  const valueByPath = getByPath(path, data);

  // eslint-disable-next-line no-nested-ternary
  const value = isArray ? (valueByPath?.length ? valueByPath[idx] : '') : valueByPath;

  const onChange = useCallback(({ target }) => {
    const newValue = newValueMapper(target?.value, value);
    updateData(assocPath(path, isArray ? update(idx, newValue, valueByPath?.length ? valueByPath : ['']) : newValue, data), path);

    if (validate && setErrors) {
      const isValid = validate(newValue);

      setErrors(isValid ? errors.filter(error => error !== path) : uniq([...errors, path]));
    }
  }, [
    data,
    errors,
    idx,
    valueByPath,
  ]);

  const onBlur = useCallback(() => setIsDirty(true), []);

  const hasError = useMemo(() => (isDirty || showErrors) && errors.includes(path), [
    errors,
    isDirty,
    showErrors,
  ]);

  return (
    <StyledTextField
      data-testid={`donor-text-input-${path}`}
      error={hasError}
      fullWidth
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      placeholder={label}
      required={required}
      value={valueMapper(value)}
      variant="filled"
      width={width}
    />
  );
};

DonorTextInput.propTypes = {
  data: DonorType.isRequired,
  errors: arrayOf(string),
  label: string.isRequired,
  idx: oneOfType([object, number]),
  newValueMapper: func,
  path: string.isRequired,
  required: bool,
  setErrors: func,
  showErrors: bool,
  updateData: func.isRequired,
  valueMapper: func,
  validate: func,
  width: string,
};

DonorTextInput.defaultProps = {
  errors: [],
  idx: null,
  newValueMapper: value => value,
  required: false,
  setErrors: null,
  showErrors: false,
  valueMapper: value => value || '',
  validate: null,
  width: '100%',
};

export {
  DonorTextInput,
};
