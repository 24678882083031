import React from 'react';
import {
  NavigationControl,
} from 'components';

const wrapWithNav = Component => props => (
  <NavigationControl>
    <Component {...props} />
  </NavigationControl>
);

export {
  wrapWithNav,
};
