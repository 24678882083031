import { createMuiTheme } from '@material-ui/core/styles';

const primaryBackground = '#fff';

/* Style guide specific colors */
const darkGreen = '#01562F';
const primaryGreen = '#00A85D';
const darkerGreenHover = '#008F4F';
const secondaryGreen = '#00D473';
const lightGreen = '#E1F9E5';
const darkerLightGreenHover = '#A6E7BA';
const darkerLightGreenPressed = '#7AD593';

// Used on button hover style
const mediumGreen = '#008F4F';

const black = '#000';
const darkGrey = '#666';
const lightGrey = '#ECECEC';
const offWhite = '#F8F9F9';

const lightBlue = '#0094FF';
/* End Style guide specific colors */

/* Let's move away from using the below styles going forward, or check with designer for usage */
const transparentGreen = 'rgba(0, 168, 93, 0.1)';
const grey300 = '#F8F9FA';
const grey500 = '#C4C4C4';
const transparentGrey = 'rgba(0, 0, 0, 0.12)';
const transparentBack = 'rgba(0, 0, 0, 0.4)';
const transparentBlack2 = 'rgba(0, 0, 0, 0.6)';
const transparentBlack3 = 'rgba(0, 0, 0, 0.8)';
const white = '#fff';
const errorMain = '#FF0C3E';
const transparentError = 'rgba(255, 12, 62, 0.5)';
const greyCustom2 = '#F3F5F5';

const progressGreen = '#00A85D';
const progressDarkGrey = '#D5D5D5';
const progressLightGrey = '#E4E4E4';

const feedInteraction = '#FF7A00';
const feedAnnouncement = '#0038FF';
const feedDonation = '#FF0500';
const feedTrend = '#0256B7';
/* End non-official styles */

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    secondaryButtonClass: 'MuiButton-customSecondary',
    tertiaryButtonClass: 'MuiButton-customTertiary',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: primaryBackground,
          height: '100vh',
        },
        '#root': {
          height: '100vh',
          overflowX: 'hidden',
        },
        '.MuiTab-wrapper': {
          textTransform: 'capitalize',
        },
        '.MuiTabs-flexContainer': {
          borderBottom: `1px solid ${transparentGrey}`,
        },
      },
    },
  },
  palette: {
    primary: {
      main: primaryGreen,
    },
    secondary: {
      main: secondaryGreen,
    },
    grey: {
      300: grey300,
      500: grey500,
      light: lightGrey,
      dark: darkGrey,
      transparent: transparentGrey,
    },
    text: {
      primary: black,
      secondary: transparentBack,
    },
    error: {
      main: errorMain,
      transparentError,
    },
    background: {
      light: 'rgba(243, 245, 245, 0.6)',
      white,
    },
  },
  /* Typography as per styleguide */
  typography: {
    color: black,
    fontFamily: [
      'Poppins',
      'Roboto',
    ],
    h1: {
      fontSize: '96px',
      fontWeight: '300',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontSize: '60px',
      fontWeight: '300',
      letterSpacing: '-0.5px',
    },
    h3: {
      fontSize: '48px',
      fontWeight: '400',
      letterSpacing: '0px',
    },
    h4: {
      fontSize: '34px',
      fontWeight: '400',
      letterSpacing: '0px',
    },
    h5: {
      fontSize: '24px',
      fontWeight: '600',
      letterSpacing: '0px',
    },
    h6: {
      fontSize: '24px',
      fontWeight: '400',
      letterSpacing: '0px',
    },
    h7: {
      fontSize: '20px',
      fontWeight: '500',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: '16px',
      fontWeight: '400',
      letterSpacing: '0.1px',
    },
    subtitle3: {
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0.1px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: '400',
      letterSpacing: '0.5px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: '400',
      letterSpacing: '0.5px',
    },
    button: {
      fontSize: '16px',
      fontWeight: '500',
      letterSpacing: '0.75px',
    },
    caption: {
      fontSize: '12px',
      fontWeight: '400',
      letterSpacing: '0.25px',
    },
    /*
    // Currently only supports 'caption' not variations of caption
    // If a second caption variation is used, make custom class with styles
    caption1: {
      fontSize: '12px',
      fontWeight: '600',
      letterSpacing: '0.25px',
    },
    caption2: {
      fontSize: '12px',
      fontWeight: '400',
      letterSpacing: '0.25px',
    },
    */
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 780,
      lg: 1280,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: [
      '8px',
    ],
  },
});

const colors = {
  primaryBackground,
  primaryGreen,
  secondaryGreen,
  grey300,
  grey500,
  greyCustom2,
  darkGrey,
  lightGrey,
  lightBlue,
  transparentGrey,
  transparentGreen,
  black,
  errorMain,
  transparentError,
  white,
  progressGreen,
  progressDarkGrey,
  progressLightGrey,
  feedInteraction,
  feedAnnouncement,
  feedDonation,
  feedTrend,
  lightGreen,
  mediumGreen,
  darkGreen,
  offWhite,
  transparentBlack2,
  transparentBlack3,
  darkerGreenHover,
  darkerLightGreenHover,
  darkerLightGreenPressed,
};

export {
  theme,
  colors,
};
