import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  arrayOf,
  func,
  string,
} from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {
  EMPTY_FUNC,
} from 'lib/constants';
import {
  Visible,
} from 'components';
import {
  translate,
} from 'lib/intl';
import {
  capitalize,
} from 'lib/utils';
import {
  TickIcon,
} from 'assets/images';

const StyledInviteSelector = styled(FormControl)`
  max-width: 170px;
  width: ${({ width }) => width || '170px'};

  .MuiInputLabel-formControl {
    left: 8px;
  }

  .MuiSelect-select {
    border-radius: 8px;
    padding-left: 12px;
  }
  
  .MuiList-root .MuiButtonBase-root.MuiListItem-root {
    line-height: 1.5;
    min-height: 40px;
    width: 6px;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }

  label + .MuiInput-formControl {
    margin-top: 0px;
  }

  .MuiInputLabel-formControl {
    top: -16px;
  }

  .MuiInputLabel-animated {
    transition: none;
  }

  .MuiInputLabel-shrink {
    transform: none;
  }

  .MuiInputLabel {
    zIndex: 10;
  }

  .MuiFormLabel-filled,
  .MuiFormLabel-root.Mui-focused:not(.MuiInputLabel) {
    display: none;
  }
`;

const renderSelectValue = (selectedOptions = []) => selectedOptions
  ?.map(option => capitalize(option))
  ?.join(', ');

const InviteSelector = ({
  BottomComponent,
  onClose,
  options,
  selectedOptions,
  handleChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openSelector = useCallback(() => setIsOpen(true), []);
  const closeSelector = useCallback(() => {
    if (onClose) {
      onClose(selectedOptions);
    }

    setIsOpen(false);
  }, [selectedOptions]);

  const Options = useMemo(() => options?.map((optionName) => {
    const isSelected = selectedOptions?.includes(optionName);

    return (
      <MenuItem key={optionName} value={optionName}>
        <Visible when={isSelected}>
          <TickIcon />
        </Visible>
        <Box ml={isSelected ? 2 : 4}>
          <Typography variant="body1">{capitalize(optionName)}</Typography>
        </Box>
      </MenuItem>
    );
  }), [
    selectedOptions,
  ]);

  const onChange = useCallback(({ target }) => {
    const newOptions = target?.value?.filter(val => Boolean(val));

    handleChange(newOptions);
  }, []);

  const BottomPart = useMemo(() => {
    if (BottomComponent) {
      return (
        <Box>
          <Divider />
          <BottomComponent onClose={closeSelector} />
        </Box>
      );
    }

    return null;
  }, [
    BottomComponent,
    closeSelector,
  ]);

  return (
    <StyledInviteSelector width={selectedOptions?.length ? 'fit-content' : ''}>
      <InputLabel id="options-label">
        {translate('INVITE_PAGE_PERMISSION_TYPE_LABEL')}
      </InputLabel>
      <Select
        id="options-selector"
        labelId="options-label"
        multiple
        onChange={onChange}
        onClose={closeSelector}
        onOpen={openSelector}
        open={isOpen}
        renderValue={renderSelectValue}
        value={selectedOptions}
      >
        {Options}
        {BottomPart}
      </Select>
    </StyledInviteSelector>
  );
};

InviteSelector.propTypes = {
  BottomComponent: func,
  onClose: func,
  options: arrayOf(string),
  selectedOptions: arrayOf(string),
  handleChange: func,
};

InviteSelector.defaultProps = {
  BottomComponent: null,
  handleChange: EMPTY_FUNC,
  onClose: EMPTY_FUNC,
  options: [],
  selectedOptions: [],
};

export {
  InviteSelector,
};
