import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  arrayOf,
  bool,
  elementType,
  func,
  object,
  oneOfType,
  number,
  shape,
  string,
} from 'prop-types';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {
  DonorType,
  isMobileView,
} from 'lib/utils';
import {
  translate,
} from 'lib/intl';
import {
  AddIcon,
  RemoveIcon,
} from 'assets/images';
import {
  Visible,
} from 'components';
import styled from 'styled-components';
import {
  assocPath,
  path as getByPath,
  pipe,
  uniq,
  without,
} from 'rambdax';
import {
  colors,
} from 'styles/theme';

const StyledWrapper = styled(Box)`
  position: relative;
`;

const StyledIcons = styled(Box)`
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: -64px;
  top: 24px;
  width: 50px;
`;

const IconWrapper = styled(Box)`
  cursor: pointer;
`;

const TypesWrapper = styled(FormControl)`
  margin-left: ${({ mobile }) => (mobile ? '0px' : '16px')};
  margin-top: ${({ mobile }) => (mobile ? '8px' : '0px')};
  width: ${({ mobile }) => (mobile ? '100%' : '160px')};

  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid ${colors.grey500};
  }

  label + .MuiInput-formControl {
    margin-top: ${({ mobile }) => (mobile ? '16px' : '0px')};
  }

  .MuiInputLabel-formControl {
    transform: translate(0, ${({ mobile }) => (mobile ? '0px' : '16px')}) scale(1);
  }
  
  .MuiInputLabel-formControl.MuiInputLabel-shrink {
    transform: translate(0px, ${({ mobile }) => (mobile ? '0px' : '-16px')}) scale(0.75);
  }

  .MuiSelect-select:focus {
    background: none;
  }

  .MuiInputBase-input {
    border-radius: 0px;
    height: 24px;
    padding: 0px 12px 0px 0px;
  }
`;

const EditDonorMultipleInputsItem = ({
  Component,
  data,
  dataToMapLength,
  el,
  idx,
  onAdd,
  onRemove,
  onTypeChange,
  path,
  selectedTypes,
  types,
  updateData,
  useIndexes,
  ...rest
}) => {
  const isMobile = isMobileView();

  const hasTypes = useMemo(() => types?.length, []);

  const availableTypes = useMemo(() => types
    .filter(({ value }) => !without(el?.type, selectedTypes).includes(value)), [
    el,
    selectedTypes,
  ]);

  const isLastInput = useMemo(() => dataToMapLength - 1 === idx, [
    dataToMapLength,
    idx,
  ]);

  const showAddButton = useMemo(() => (hasTypes
    ? (isLastInput && (availableTypes?.length > 1))
    : isLastInput), [
    availableTypes,
    isLastInput,
  ]);

  const MenuItems = useMemo(() => availableTypes?.map(({ label, value }) => (
    <MenuItem key={value} value={value}>
      <Typography color="textPrimary">
        {label}
      </Typography>
    </MenuItem>
  )), [availableTypes]);

  const selectValue = useMemo(() => el?.type || types[0]?.value || '', [el]);

  const RenderedComponent = useMemo(() => (
    <Component
      data={data}
      idx={useIndexes ? idx : null}
      path={el?.dataPath || path}
      updateData={updateData}
      {...rest}
    />
  ), [
    data,
    idx,
    el,
    path,
    updateData,
    useIndexes,
    rest,
  ]);

  return (
    // eslint-disable-next-line react/no-array-index-key
    <StyledWrapper key={idx}>
      <Box
        alignItems="flex-end"
        display={isMobile ? 'block' : 'flex'}
      >
        {RenderedComponent}
        <Visible when={types.length}>
          <TypesWrapper mobile={Number(isMobile)}>
            <InputLabel id="phone-input-type-select">
              {translate('TYPE_LABEL')}
            </InputLabel>
            <Select
              data-testid="phone-input-type-select"
              id="phone-input-type-select"
              onChange={onTypeChange(el)}
              value={selectValue}
            >
              {MenuItems}
            </Select>
          </TypesWrapper>
        </Visible>
      </Box>
      <StyledIcons>
        <Visible when={dataToMapLength > 1}>
          <IconWrapper>
            <RemoveIcon onClick={onRemove(idx, el?.type)} />
          </IconWrapper>
        </Visible>
        <Visible when={showAddButton}>
          <IconWrapper>
            <AddIcon onClick={onAdd} />
          </IconWrapper>
        </Visible>
      </StyledIcons>
    </StyledWrapper>
  );
};

EditDonorMultipleInputsItem.propTypes = {
  Component: elementType.isRequired,
  data: DonorType.isRequired,
  dataToMapLength: number.isRequired,
  el: shape({
    dataPath: string,
    type: string,
  }),
  onAdd: func.isRequired,
  onRemove: func.isRequired,
  onTypeChange: func.isRequired,
  path: string.isRequired,
  idx: number,
  selectedTypes: arrayOf(string).isRequired,
  types: arrayOf(shape({
    label: string,
    value: string,
  })).isRequired,
  updateData: func.isRequired,
  useIndexes: bool,
};

EditDonorMultipleInputsItem.defaultProps = {
  el: null,
  idx: null,
  useIndexes: true,
};

const EditDonorMultipleInputs = ({
  data,
  defaultValue,
  path,
  types,
  updateData,
  ...rest
}) => {
  const [selectedTypes, setSelectedTypes] = useState([]);

  const hasTypes = useMemo(() => types?.length, []);

  const dataToMap = useMemo(() => {
    const orderedTypes = uniq([...selectedTypes, ...types.map(({ value }) => value)]);

    const sortedTypes = [...orderedTypes]
      .sort((a, b) => orderedTypes.indexOf(a) - orderedTypes.indexOf(b));

    const dataByPath = types.length
      ? sortedTypes?.reduce((acc, type) => {
        const dataPath = `${path}.${type}`;
        const val = getByPath(dataPath, data);

        if (val || selectedTypes.includes(type)) {
          acc.push({
            dataPath,
            type,
          });
        }

        return acc;
      }, [])
      : getByPath(path, data);

    return dataByPath?.length ? dataByPath : [defaultValue];
  }, [
    data,
    selectedTypes,
  ]);

  useEffect(() => {
    setSelectedTypes(hasTypes ? dataToMap?.map(({ type }) => type) : []);
  }, []);

  const onAdd = useCallback(() => {
    if (hasTypes) {
      setSelectedTypes([
        ...selectedTypes,
        without(selectedTypes, types?.map(({ value }) => value))[0],
      ]);
    } else {
      updateData(assocPath(path, [
        ...dataToMap,
        defaultValue,
      ], data), path);
    }
  }, [
    dataToMap,
    selectedTypes,
    updateData,
  ]);

  const onRemove = useCallback((idx, type) => () => {
    if (hasTypes) {
      const fullPath = `${path}.${type}`;
      setSelectedTypes(without(type, selectedTypes));
      updateData(assocPath(fullPath, '', data), fullPath);
    } else {
      const updatedList = [...dataToMap];
      // Adds isRemoved field which is used in mapDonorEditedFields from lib/mappers
      // This field is  necessary to remove the item later on by making an api call
      updatedList[idx].isRemoved = true;
      updateData(assocPath(path, updatedList?.length ? updatedList : null, data), path);
    }
  }, [
    dataToMap,
    selectedTypes,
    updateData,
  ]);

  const onTypeChange = useCallback(el => ({ target: { value: currentType } }) => {
    const prevDataValue = getByPath(el.dataPath, data);
    const prevPath = `${path}.${el?.type}`;
    const currentPath = `${path}.${currentType}`;

    const updatedData = pipe(
      dataVal => assocPath(prevPath, '', dataVal),
      dataVal => assocPath(currentPath, prevDataValue, dataVal),
    )(data);

    updateData(updatedData, [currentPath, prevPath]);
    setSelectedTypes(without([el?.type], uniq([...selectedTypes, currentType])));
  }, [
    selectedTypes,
  ]);

  return dataToMap?.map((el, idx) => (
    <Visible
      // eslint-disable-next-line
      key={idx}
      when={!el.isRemoved}
    >
      <EditDonorMultipleInputsItem
        data={data}
        dataToMapLength={dataToMap?.length}
        el={el}
        idx={idx}
        onAdd={onAdd}
        onRemove={onRemove}
        onTypeChange={onTypeChange}
        path={path}
        selectedTypes={selectedTypes}
        types={types}
        updateData={updateData}
        {...rest}
      />
    </Visible>
  ));
};

EditDonorMultipleInputs.propTypes = {
  Component: elementType.isRequired,
  data: DonorType.isRequired,
  defaultValue: oneOfType([string, object]).isRequired,
  path: string.isRequired,
  types: arrayOf(shape({
    label: string,
    value: string,
  })),
  updateData: func.isRequired,
};

EditDonorMultipleInputs.defaultProps = {
  types: [],
};

export {
  EditDonorMultipleInputs,
};
