import {
  translate,
} from 'lib/intl';

const INVITE_PAGE_TABS = [
  {
    id: 'sendInvite',
    label: translate('INVITE_PAGE_SEND_INVITE_TAB'),
  },
  {
    id: 'pendingInvites',
    label: translate('INVITE_PAGE_SEND_PENDING_INVITES'),
  },
];

export {
  INVITE_PAGE_TABS,
};
