
import {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  useHistory,
} from 'react-router-dom';
import {
  donorsActions,
} from 'rdx/actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  AUTH_ROUTES,
} from 'lib/constants';

const useDonorsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    donorsData,
    ownerOrgPubId,
  } = useSelector(state => ({
    donorsData: state.donors?.data || [],
    ownerOrgPubId: state.authentication?.user?.org?.ownerOrgPubId || '',
  }));

  // TODO: update mappers once data structure is finalized
  const donors = useMemo(() => donorsData?.map(({
    donorPubId,
    donorSummary: {
      donorIdentity,
    },
    updated,
  } = {
    donorSummary: {
      donorIdentity: {},
    },
  }) => ({
    avatar: null,
    donorPubId,
    displayName: donorIdentity?.org?.orgName || donorIdentity?.user?.displayName,
    updated,
  })), [donorsData]);

  // TODO: @anna - replace with a real id once available
  const fetchDonors = () => dispatch(donorsActions.fetchDonors(ownerOrgPubId));

  const onDonorClick = useCallback((donorPubId) => {
    history.push(`${AUTH_ROUTES.DONORS}/${donorPubId}`);
  }, []);

  useEffect(() => {
    fetchDonors();
  }, []);

  return {
    donors,
    fetchDonors,
    onDonorClick,
    ownerOrgPubId,
  };
};

export {
  useDonorsPage,
};
