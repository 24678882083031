import React, {
  useMemo,
} from 'react';
import {
  string,
  arrayOf,
  node,
  shape,
} from 'prop-types';
import {
  Box,
  Link,
  Typography,
} from '@material-ui/core';
import {
  Visible,
} from 'components';
import {
  GapType,
} from 'lib/utils';

const ViewFields = (({
  icon,
  data,
  gapSize,
}) => {
  const Values = useMemo(() => data?.map(({
    value,
    url,
  }, idx) => {
    const Text = (
      <Typography
        color={url ? 'primary' : 'textPrimary'}
        variant="body1"
      >
        {value}
      </Typography>
    );

    return (
      <Visible
        fallback={Text}
        // eslint-disable-next-line
        key={idx}
        when={url}
      >
        <Link
          href={url}
        >
          {Text}
        </Link>
      </Visible>
    );
  }), [data]);

  return (
    <Visible when={data?.length}>
      <Box
        data-testid="view-fields"
        display="flex"
        py={1}
      >
        <Visible when={icon}>
          <Box pr={gapSize}>
            {icon}
          </Box>
        </Visible>
        <Box>
          {Values}
        </Box>
      </Box>
    </Visible>
  );
});

ViewFields.propTypes = {
  icon: node,
  gapSize: GapType.size,
  data: arrayOf(shape({
    value: string,
    url: string,
  })),
};

ViewFields.defaultProps = {
  icon: null,
  data: [],
  gapSize: 2,
};

export {
  ViewFields,
};
