import userActionTypes from 'rdx/actionTypes';
import { feedService } from 'services/feed';

// Thunk action creator
function fetchFeed(feedPage) {
  function request(page) { return { type: userActionTypes.FEED_REQUEST, page }; }
  function success(feed) { return { type: userActionTypes.FEED_SUCCESS, feed }; }
  function failure(error) { return { type: userActionTypes.FEED_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(feedPage));

    feedService.getFeed(feedPage)
      .then(
        (feed) => {
          dispatch(success(feed));
        },
        (error) => {
          const { code, message } = error;

          dispatch(failure({ code, message }));
        },
      );
  };
}

export const feedActions = {
  fetchFeed,
};
