import {
  translate,
} from 'lib/intl';

const DONOR_PAGE_TABS = [
  {
    id: 'donorHistory',
    label: translate('DONOR_HISTORY_TAB'),
  },
  {
    id: 'personalInfo',
    label: translate('PERSONAL_INFO_TAB'),
  },
];

export {
  DONOR_PAGE_TABS,
};
