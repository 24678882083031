import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { COUNTRY_CODES } from 'lib/constants';

/**
  Checks if a phone number is valid
  @param {string} number value
  @returns {boolean} result
*/
const isValidPhoneNumber = (number) => {
  const parsedNumber = parsePhoneNumberFromString(number, COUNTRY_CODES.US);
  return parsedNumber && parsedNumber.isValid();
};

export {
  isValidPhoneNumber,
};
