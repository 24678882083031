import userActionTypes from 'rdx/actionTypes';
import {
  AUTH_USER_ID_TOKEN,
  AUTH_X_DOTORG,
  USER_DATA,
} from 'lib/constants';
import {
  userService,
} from 'services/user';

const isAuthenticated = () => !!localStorage.getItem(AUTH_USER_ID_TOKEN);
const getAuthToken = () => localStorage.getItem(AUTH_USER_ID_TOKEN);
const getUser = () => localStorage.getItem(USER_DATA);

const setUpRefreshInterval = () => {
  if (isAuthenticated()) {
    return userService.refreshAndCreateRefreshInterval();
  }

  return null;
};

const initialState = {
  isAuthenticated: isAuthenticated(),
  authToken: getAuthToken(),
  tokenExpiration: null,
  user: JSON.parse(getUser()),
  lastUsernameUsed: '',
  isFetching: false,
  error: null,
  refreshTokenTimerRef: setUpRefreshInterval(),
};

/* eslint-disable no-case-declarations */
export function authentication(
  state = initialState,
  action,
) {
  switch (action.type) {
    case userActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        lastUsernameUsed: action.user?.username,
      };
    case userActionTypes.LOGIN_SUCCESS:
      const { userResponse: { user, expiration: initialTokenExpiration } } = action;
      const { cognito: { idToken } } = user;

      // Store user details and jwt token in local storage to keep user logged in
      localStorage.setItem(AUTH_USER_ID_TOKEN, idToken);
      localStorage.setItem(AUTH_X_DOTORG, idToken);

      // Currently storing:
      // - access and id tokens from cognito
      // - user and org data from context service (currently the first one listed)
      localStorage.setItem(USER_DATA, JSON.stringify(user));

      return {
        ...state,
        isAuthenticated: isAuthenticated(),
        authToken: getAuthToken(),
        user,
        isFetching: false,
        tokenExpiration: initialTokenExpiration,
      };
    case userActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case userActionTypes.LOGOUT:
      // Disconnect the refresh token timer interval
      clearInterval(state.refreshTokenTimerRef);
      localStorage.removeItem(AUTH_USER_ID_TOKEN);
      localStorage.removeItem(AUTH_X_DOTORG);
      localStorage.removeItem(USER_DATA);

      return {
        ...state,
        isAuthenticated: isAuthenticated(),
        authToken: getAuthToken(),
        user: getUser(),
        isFetching: false,
        refreshTokenTimerRef: null,
      };
    case userActionTypes.LOGIN_CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case userActionTypes.REFRESH_ACCESS_TOKEN_TIMER_SETUP:
      // Timer should only be set once
      if (state.refreshTokenTimerRef) {
        // eslint-disable-next-line no-console
        console.warn('Refresh timer already set');

        return {
          ...state,
        };
      }

      if (!state.tokenExpiration) {
        // eslint-disable-next-line no-console
        console.warn('Token expiration not set');

        return {
          ...state,
        };
      }

      // Calculate interval duration as half of the expiration time
      const msUntilExpiration = (state.tokenExpiration - new Date().getTime() / 1000) * 1000;
      // Refresh token halfway to expiration for safety
      const refreshInterval = msUntilExpiration / 2;

      return {
        ...state,
        refreshTokenTimerRef: userService.createRefreshInterval(refreshInterval),
      };
    case userActionTypes.REFRESH_ACCESS_TOKEN:
      const { tokenResponse: { token, expiration } } = action;

      localStorage.removeItem(AUTH_USER_ID_TOKEN);
      localStorage.setItem(AUTH_USER_ID_TOKEN, token);

      return {
        ...state,
        authToken: getAuthToken(),
        tokenExpiration: expiration,
      };
    default:
      return state;
  }
}
