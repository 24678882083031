import React from 'react';
import {
  Grid, Typography,
} from '@material-ui/core';
import { AuthContainer } from 'components';
import {
  translate,
} from 'lib/intl';
import {
  checkEmailIcon,
} from 'assets/images';
import {
  PUBLIC_ROUTES,
} from 'lib/constants';

const {
  LOGIN,
} = PUBLIC_ROUTES;

const ResetPasswordRequestSuccessPage = () => (
  <AuthContainer
    size="md"
    navLinkText={translate('BACK_TO_SIGN_IN')}
    navLinkRoute={LOGIN}
  >
    <Grid
      container
    >
      <Grid
        item
        sm={6}
        xs={12}
      >
        <Typography
          variant="h5"
        >
          {`${translate('THANK_YOU')}!`}
        </Typography>
        <Typography
          variant="h6"
          style={{
            marginTop: '30px',
          }}
        >
          {translate('RESET_PASSWORD_REQUEST_SUCCESS')}
        </Typography>
      </Grid>
      <Grid
        item
        sm={6}
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '160px',
          height: '160px',
        }}
      >
        <img
          alt="Check email"
          src={checkEmailIcon}
        />
      </Grid>
    </Grid>
  </AuthContainer>
);

export {
  ResetPasswordRequestSuccessPage,
};
