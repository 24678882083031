import { combineReducers } from 'redux';
import { registration } from 'rdx/reducers/registration';
import { authentication } from 'rdx/reducers/authentication';
import { resendVerification } from 'rdx/reducers/resend-verification';
import { inviteForm } from 'rdx/reducers/invite-form';
import { sendingInvites } from 'rdx/reducers/sending-invites';
import { invites } from 'rdx/reducers/invites';
import { updatingInvites } from 'rdx/reducers/updating-invites';
import { resetPassword } from 'rdx/reducers/reset-password';
import { resetPasswordSubmit } from 'rdx/reducers/reset-password-submit';
import { location } from 'rdx/reducers/location';
import { donorInfo } from 'rdx/reducers/donor-info';
import { donors } from 'rdx/reducers/donors';
import { donorForm } from 'rdx/reducers/donor-form';
import { saveDonor } from 'rdx/reducers/save-donor';
import { fetchFeed } from 'rdx/reducers/feed';
import { recentlyViewedDonors } from 'rdx/reducers/recently-viewed-donors';
import { user } from 'rdx/reducers/user';

export default combineReducers({
  authentication,
  donors,
  donorInfo,
  donorForm,
  saveDonor,
  inviteForm,
  invites,
  location,
  sendingInvites,
  updatingInvites,
  registration,
  resetPassword,
  resendVerification,
  resetPasswordSubmit,
  fetchFeed,
  recentlyViewedDonors,
  user,
});
