import {
  createAction,
} from 'lib/utils';
import {
  usersService,
} from 'services/users';
import userActionTypes from 'rdx/actionTypes';

const {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAILURE,
} = userActionTypes;

const fetchUser = ownerOrgPubId => async (dispatch) => {
  try {
    dispatch(createAction({ type: USERS_REQUEST }));

    const response = await usersService.fetchUsers(ownerOrgPubId);

    dispatch(createAction({
      type: USERS_SUCCESS,
      data: response?.data || {},
    }));
  } catch (err) {
    dispatch(createAction({
      type: USERS_FAILURE,
      err,
    }));
  }
};

export const usersActions = {
  fetchUser,
};
