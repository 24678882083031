import {
  checkedIcon,
  hourGlassIcon,
  uncertaintyIcon,
} from 'assets/images';

// Be aware that fields are ordered for invites sorting
const INVITE_STATUSES = {
  CANCELLED: 'cancelled',
  REJECTED: 'rejected',
  PENDING: 'pending',
  SENT: 'sent',
  JOINED: 'joined',
};

const ICON_STATUS_MAPPER = {
  pending: hourGlassIcon,
  sent: hourGlassIcon,
  rejected: uncertaintyIcon,
  joined: checkedIcon,
  cancelled: uncertaintyIcon,
};

export {
  ICON_STATUS_MAPPER,
  INVITE_STATUSES,
};
