import React from 'react';
import {
  Box,
} from '@material-ui/core';
import {
  translate,
} from 'lib/intl';
import {
  mapAddress,
  mapName,
  mapPhoneNumber,
  mapSingleValue,
  mapEmail,
  mapWebsite,
} from 'lib/mappers';
import {
  ViewFields,
} from 'components';
import {
  DonorType,
  isMobileView,
} from 'lib/utils';
import {
  BirthdayIcon,
  EmailIcon,
  HomeIcon,
  PersonIcon,
  PhoneIcon,
  RedPhoneIcon,
  WebIcon,
} from './donor-icons';
import {
  DonorSectionTitle,
} from './donor-section-title';
import {
  SocialAccounts,
} from './social-accounts';
import {
  DonorSectionWrapper,
} from './donor-section-wrapper';

const PersonalInformation = ({
  donor,
}) => {
  const isMobile = isMobileView();

  const {
    addresses,
    dob,
    emails,
    facebook,
    instagram,
    linkedin,
    phones,
    twitter,
    website,
  } = donor;

  return (
    <DonorSectionWrapper>
      <DonorSectionTitle>
        {translate('DONOR_PERSONAL_INFORMATION')}
      </DonorSectionTitle>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={isMobile ? 'column' : 'row'}
        flexWrap="wrap"
      >
        <Box pr={2}>
          <ViewFields
            icon={PersonIcon}
            data={mapName(donor)}
          />
          <ViewFields
            icon={HomeIcon}
            data={mapAddress(addresses?.mailing)}
          />
          <ViewFields
            icon={BirthdayIcon}
            data={mapSingleValue(dob)}
          />
        </Box>
        <Box pr={2}>
          <Box>
            <ViewFields
              icon={EmailIcon}
              data={mapEmail(emails)}
            />
            <ViewFields
              icon={PhoneIcon}
              data={mapPhoneNumber(phones)}
            />
            <ViewFields
              icon={RedPhoneIcon}
              data={mapPhoneNumber(phones, ['home'])}
            />
            <ViewFields
              icon={WebIcon}
              data={mapWebsite(website)}
            />
          </Box>
        </Box>
        <Box pt={isMobile ? 3 : 0}>
          <SocialAccounts
            facebook={facebook}
            instagram={instagram}
            linkedin={linkedin}
            twitter={twitter}
          />
        </Box>
      </Box>
    </DonorSectionWrapper>
  );
};

PersonalInformation.propTypes = {
  donor: DonorType,
};

PersonalInformation.defaultProps = {
  donor: {},
};

export {
  PersonalInformation,
};
