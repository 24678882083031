import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import { EMPTY_FUNC } from 'lib/constants';
import {
  Visible,
} from 'components';
import {
  FeedType,
} from 'lib/types';
import {
  FeedItemTitle,
} from './feed-item-title';

const useStyles = makeStyles(theme => ({
  feedItem: {
    background: theme.palette.background.white,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
  active: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const FeedItem = React.forwardRef(({
  type,
  date,
  body,
  pinned,
  isActive,
  onClick,
}, ref) => {
  const styles = useStyles();
  const feedItemClassName = isActive ? `${styles.feedItem} ${styles.active}` : styles.feedItem;

  // Sort based on display order
  // eslint-disable-next-line no-confusing-arrow
  body.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);

  const renderMarkdown = (item, idx) => {
    const { text } = item;

    return (
      <ReactMarkdown key={`markdown-${idx}`} source={text} />
    );
  };

  return (
    <Box
      ref={ref}
      onClick={onClick}
      className={feedItemClassName}
      p={2}
      m={2}
    >
      <FeedItemTitle
        type={type}
        date={date}
        pinned={pinned}
      />
      <Visible when={body}>
        <Box>
          {body.map(renderMarkdown)}
        </Box>
      </Visible>
    </Box>
  );
});

FeedItem.propTypes = {
  date: PropTypes.string,
  type: FeedType,
  isActive: PropTypes.bool,
  pinned: PropTypes.bool,
  onClick: PropTypes.func,
  body: PropTypes.arrayOf(PropTypes.shape({
    displayOrder: PropTypes.number,
    type: PropTypes.string,
    text: PropTypes.string,
  })),
};

FeedItem.defaultProps = {
  date: null,
  type: null,
  isActive: false,
  pinned: false,
  onClick: EMPTY_FUNC,
  body: [],
};

export {
  FeedItem,
};
