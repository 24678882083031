import React, {
  useCallback,
  useState,
} from 'react';
import {
  bool,
  func,
} from 'prop-types';
import {
  Box,
  TextField,
} from '@material-ui/core';
import {
  KEY_RETURN,
  KEY_TAB,
  KEY_SPACE,
} from 'keycode-js';
import styled from 'styled-components';
import {
  translate,
} from 'lib/intl';
import {
  colors,
} from 'styles/theme';

const StyledEmailInput = styled(Box)`
  .MuiInputBase-root {
    padding-right: 6px;
    padding-left: 6px;
  }

  .MuiInputLabel-root.MuiInputLabel-formControl {
    left: 8px;
  }

  .MuiSelect-select.MuiSelect-select:focus {
    border-radius: 8px;
  }

  .MuiChip-outlined.MuiChip-outlinedPrimary {
    background-color: ${colors.transparentError};
    border-color: ${colors.errorMain};
    color: ${colors.black};
  }

  .MuiInputBase-root.MuiInput-underline:before {
    border-bottom: 2px solid ${colors.grey500};
  }

  .MuiInputBase-root.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid ${colors.grey500};
  }

  .MuiInputBase-root.MuiInput-underline.Mui-focused:after {
    top: 1px;
  }
`;

const EmailInput = ({
  handlePaste,
  hasError,
  submitChanges,
}) => {
  const [value, setValue] = useState('');
  const [pastedValue, setPastedValue] = useState('');

  const onChange = useCallback((e) => {
    if (pastedValue) {
      handlePaste(pastedValue);
      setPastedValue('');
      setValue('');
    } else {
      setValue(e?.target?.value);
    }
  }, [pastedValue]);

  const onKeyDown = useCallback((e) => {
    if ([KEY_RETURN, KEY_SPACE, KEY_TAB].includes(e.keyCode)) {
      submitChanges(value);
      setValue('');
    }
  }, [
    submitChanges,
    value,
  ]);

  const onPaste = useCallback((e) => {
    setPastedValue(e?.clipboardData.getData('text/plain'));
  }, [
    submitChanges,
    value,
  ]);

  return (
    <StyledEmailInput>
      <TextField
        error={hasError}
        fullWidth
        onChange={onChange}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        placeholder={translate('INVITE_PAGE_INPUT_PLACEHOLDER')}
        value={value}
      />
    </StyledEmailInput>
  );
};

EmailInput.propTypes = {
  handlePaste: func.isRequired,
  hasError: bool.isRequired,
  submitChanges: func.isRequired,
};

export {
  EmailInput,
};
