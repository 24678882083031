import React from 'react';
import {
  func,
} from 'prop-types';
import {
  translate,
} from 'lib/intl';
import {
  DonorType,
} from 'lib/utils';
import {
  EditDonorSectionWrapper,
} from './edit-donor-section-wrapper';
import {
  SocialInputs,
} from './social-inputs';
import {
  ShowMoreContent,
} from './show-more-content';
import {
  SectionsGenerator,
} from './sections-generator';
import {
  USER_WORK_INFO_CONFIG,
} from './config';

const EditDonorWorkInfo = ({
  data,
  updateData,
}) => (
  <EditDonorSectionWrapper
    id="edit-donor-work-info-section"
    title={translate('DONOR_WORK_INFORMATION')}
  >
    <SectionsGenerator
      config={USER_WORK_INFO_CONFIG}
      data={data}
      updateData={updateData}
    />
    <ShowMoreContent>
      <SocialInputs
        data={data}
        path="employer"
        updateData={updateData}
      />
    </ShowMoreContent>
  </EditDonorSectionWrapper>
);

EditDonorWorkInfo.propTypes = {
  data: DonorType.isRequired,
  updateData: func.isRequired,
};

export {
  EditDonorWorkInfo,
};
