import React from 'react';
import {
  HomeLayout,
} from 'components';
import {
  useHome,
  useRecentlyViewedDonors,
  useUser,
} from './hooks';

const HomePage = () => {
  const {
    feed,
    getFeedPage,
    setActiveFeedItem,
    activeFeedItemId,
  } = useHome();
  const {
    recentlyViewedDonors,
  } = useRecentlyViewedDonors();
  const {
    goals,
    visits,
  } = useUser();

  return (
    <HomeLayout
      feed={feed}
      getFeedPage={getFeedPage}
      setActiveFeedItem={setActiveFeedItem}
      activeFeedItemId={activeFeedItemId}
      recentlyViewedDonors={recentlyViewedDonors}
      goals={goals}
      visits={visits}
    />
  );
};

export {
  HomePage,
};
