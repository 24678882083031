import {
  arrayOf,
  bool,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';

const DonorSingleAssociationType = shape({
  displayName: string,
  userId: string,
  primaryEmail: string,
  primaryPhone: string,
  role: string,
});

const DonorAssociationType = arrayOf(DonorSingleAssociationType);

const SocialType = shape({
  facebook: string,
  instagram: string,
  linkedin: string,
  twitter: string,
});

// TODO: @anna - update type once finalized
const DonorType = shape({
  acquaintances: DonorAssociationType,
  affiliations: arrayOf(shape({
    orgName: string,
    orgId: string,
    primaryContact: shape({
      displayName: string,
      userId: string,
      primaryEmail: string,
      primaryPhone: string,
      role: string,
    }),
  })),
  connections: DonorAssociationType,
  developers: shape({
    // primary
    dev1: DonorSingleAssociationType,
    // secondary
    dev2: DonorSingleAssociationType,
  }),
  employees: DonorAssociationType,
  // TODO: @anna remove associations
  associations: shape({
    household: DonorAssociationType,
    affiliations: arrayOf(shape({
      orgName: string,
      orgId: string,
      primaryContact: shape({
        displayName: string,
        userId: string,
        primaryEmail: string,
        primaryPhone: string,
        role: string,
      }),
    })),
    connections: DonorAssociationType,
    developers: DonorAssociationType,
  }),
  description: string,
  owner: shape({
    primaryPhone: string,
    displayName: string,
    role: string,
    primaryEmail: string,
  }),
  avatar: string,
  website: string,
  emails: shape({
    primary: string,
    home: string,
    work: string,
  }),
  employer: shape({
    employerName: string,
    employerOrgId: string,
    employerWebsite: string,
    jobTitle: string,
    social: SocialType,
    website: string,
  }),
  phones: shape({
    primary: string,
    home: string,
    mobile: string,
    work: string,
  }),
  social: SocialType,
  firstName: string,
  lastName: string,
  notes: shape({
    n1: string,
    n2: string,
    n3: string,
  }),
  orgName: string,
  updated: string,
});

const GapType = {
  size: oneOfType([string, number]),
  vertical: bool,
};

const CustomButtonType = oneOf(['primary', 'secondary', 'tertiary', 'floating', 'link']);

export {
  DonorAssociationType,
  DonorType,
  GapType,
  SocialType,
  CustomButtonType,
};
