import React from 'react';
import {
  AuthContainer,
  RegistrationForm,
} from 'components';
import {
  translate,
} from 'lib/intl';
import {
  PUBLIC_ROUTES,
} from 'lib/constants';
import {
  useRegistration,
} from './hooks';

const RegistrationPage = () => {
  const {
    registerAction,
    resetRegistrationState,
    isFetching,
    isRegistrationSuccessful,
    error,
  } = useRegistration();

  return (
    <AuthContainer
      size="md"
      navLinkText={translate('SIGN_IN_INSTEAD')}
      navLinkRoute={PUBLIC_ROUTES.LOGIN}
    >
      <RegistrationForm
        isFetching={isFetching}
        error={error}
        isRegistrationSuccessful={isRegistrationSuccessful}
        registerAction={registerAction}
        resetRegistrationState={resetRegistrationState}
      />
    </AuthContainer>
  );
};

export {
  RegistrationPage,
};
