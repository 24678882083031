import {
  oneOf,
} from 'prop-types';
import {
  donorIcon,
  hornIcon,
  giftIcon,
  barGraphIcon,
} from 'assets/images';
import {
  colors,
} from 'styles/theme';

const interaction = 'Interaction';
const announcement = 'Announcement';
const donation = 'Donation';
const trend = 'Trend';

const FeedTypeMap = {
  [interaction]: {
    color: colors.feedInteraction,
    icon: donorIcon,
  },
  [announcement]: {
    color: colors.feedAnnouncement,
    icon: hornIcon,
  },
  [donation]: {
    color: colors.feedDonation,
    icon: giftIcon,
  },
  [trend]: {
    color: colors.feedTrend,
    icon: barGraphIcon,
  },
};

const FeedTypes = [
  interaction,
  announcement,
  donation,
  trend,
];

const FeedType = oneOf(
  FeedTypes,
);

export {
  FeedTypeMap,
  FeedTypes,
  FeedType,
};
