import {
  camelCaseKeys,
  createApiRequest,
} from 'lib/utils';

const fetchDonors = ownerOrgPubId => createApiRequest({
  method: 'get',
  path: `orgs/${ownerOrgPubId}/donors`,
});

// TODO remove mock data when API is ready
const mockRecentlyViewedDonors = {
  data: {
    donors: [
      {
        donor_pub_id: 'odub2R1MCNzdWIw',
        donor_type: 'odu',
        display_name: 'Richard Buckingham',
        avatar: 'facebook/pic.jpg',
        when: '3 days ago',
        interaction_type: 'meeting',
      },
      {
        donor_pub_id: 'odob2R1MCNzdWIw',
        donor_type: 'odo',
        display_name: 'Nike',
        avatar: null,
        when: '1 month ago',
        interaction_type: 'phone',
      },
    ],
  },
};

// TODO update this when the API is ready
const fetchRecentlyViewedDonors = () => {
  const transformed = mockRecentlyViewedDonors.data.donors.map(donor => camelCaseKeys(donor));

  return new Promise(resolve => setTimeout(() => resolve({ data: { donors: transformed } }), 2000));
};

const saveDonor = ({
  ownerOrgPubId,
  data,
  donorPubId,
}) => {
  const mainPath = `orgs/${ownerOrgPubId}/donors`;

  return createApiRequest({
    data,
    method: donorPubId ? 'put' : 'post',
    path: donorPubId ? `${mainPath}/${donorPubId}` : mainPath,
  });
};

export const donorsService = {
  saveDonor,
  fetchDonors,
  fetchRecentlyViewedDonors,
};
