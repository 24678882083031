import {
  AUTH,
} from './auth';
import {
  BUTTONS,
} from './buttons';
import {
  COMMON,
} from './common';
import {
  ERRORS,
} from './errors';
import {
  INVITE_PAGE,
} from './invite-page';
import {
  NAVIGATION,
} from './navigation';
import {
  DONOR,
} from './donor';
import {
  HOME,
} from './home';
import {
  DONORS,
} from './donors';

const en = {
  ...AUTH,
  ...BUTTONS,
  ...COMMON,
  ...ERRORS,
  ...INVITE_PAGE,
  ...NAVIGATION,
  ...DONOR,
  ...HOME,
  ...DONORS,
};

export {
  en,
};
