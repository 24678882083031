import React, {
  useCallback,
  useState,
} from 'react';
import {
  arrayOf,
  func,
  string,
} from 'prop-types';
import {
  Box,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {
  formatIncompletePhoneNumber,
  getCountryCallingCode,
  parseIncompletePhoneNumber,
  parseDigits,
} from 'libphonenumber-js';
import styled from 'styled-components';
import {
  DonorType,
  isValidPhoneNumber,
} from 'lib/utils';
import {
  translate,
} from 'lib/intl';
import {
  PHONE_NUMBERS_CONFIG,
  PHONE_NUMBERS_CONFIG_VALUES,
} from 'lib/types';
import {
  Gap,
} from 'components';
import {
  colors,
} from 'styles/theme';
import {
  DonorTextInput,
} from './donor-text-input';

const StyledSelect = styled(Select)`
  &:before,
  &:after {
    display: none;
  }

  .MuiSelect-root {
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .MuiSelect-select.MuiSelect-select:focus {
    background-color: ${colors.white};
  }
`;

const PhoneInputMenuItems = PHONE_NUMBERS_CONFIG_VALUES?.map(({
  id,
  flag: FlagIcon,
  label,
}) => (
  <MenuItem key={id} value={id}>
    <FlagIcon />
    <Gap />
    <Box
      display="flex"
      flex="1"
      justifyContent="space-between"
    >
      <Typography color="textPrimary">
        {label}
      </Typography>
      <Gap />
      <Typography
        color="textSecondary"
      >
        {`+${getCountryCallingCode(id)}`}
      </Typography>
    </Box>
  </MenuItem>
));

const renderSelectedDialCodeItem = (selectedConfigId) => {
  const { flag: FlagIcon } = PHONE_NUMBERS_CONFIG[selectedConfigId];

  return (
    <Box px="4px">
      <FlagIcon />
    </Box>
  );
};

const EditDonorPhoneInput = ({
  data,
  errors,
  path,
  setErrors,
  updateData,
}) => {
  const [countryCodeId, setCountryCodeId] = useState(PHONE_NUMBERS_CONFIG_VALUES[0].id);

  const handleCountryCodeChange = useCallback(({ target: { value } }) => {
    setCountryCodeId(value);
  }, []);

  const mapPhoneNumber = useCallback((addDialCode = true) => (value = '') => {
    const dialCode = `+${getCountryCallingCode(countryCodeId)}`;
    const prefix = addDialCode ? `${dialCode} ` : '';
    const number = value.replace(dialCode, '');

    return parseDigits(number).length === 3
      ? `${prefix}${parseIncompletePhoneNumber(number)}`
      : `${prefix}${formatIncompletePhoneNumber(number, countryCodeId)}`;
  }, [countryCodeId]);

  return (
    <Box
      display="flex"
      alignItems="flex-end"
      width="100%"
    >
      <StyledSelect
        data-testid="phone-input-select"
        id="phone-input-select"
        onChange={handleCountryCodeChange}
        renderValue={renderSelectedDialCodeItem}
        value={countryCodeId}
      >
        {PhoneInputMenuItems}
      </StyledSelect>
      <DonorTextInput
        data={data}
        errors={errors}
        label={translate('PHONE_NUMBER_LABEL')}
        newValueMapper={mapPhoneNumber()}
        path={path}
        setErrors={setErrors}
        updateData={updateData}
        validate={isValidPhoneNumber}
        valueMapper={mapPhoneNumber(false)}
      />
    </Box>
  );
};

EditDonorPhoneInput.propTypes = {
  data: DonorType.isRequired,
  errors: arrayOf(string).isRequired,
  path: string.isRequired,
  setErrors: func.isRequired,
  updateData: func.isRequired,
};

export {
  EditDonorPhoneInput,
};
