import {
  camelCaseKeys,
  createAction,
} from 'lib/utils';
import userActionTypes from 'rdx/actionTypes';
import {
  donorsService,
} from 'services/donors';

const {
  DONOR_FORM_UPDATE_USER,
  DONOR_FORM_UPDATE_ORG,
  DONOR_FORM_RESET,
  DONOR_FORM_SET_ERRORS,
  DONOR_FORM_SAVE_DONOR_REQUEST,
  DONOR_FORM_SAVE_DONOR_SUCCESS,
  DONOR_FORM_SAVE_DONOR_FAILURE,
  DONOR_FORM_SAVE_DONOR_RESET,
  DONOR_FORM_UPDATE_EDITED_FIELDS,
} = userActionTypes;

const updateUserDonorForm = data => createAction({ type: DONOR_FORM_UPDATE_USER, user: data });

const updateOrgDonorForm = data => createAction({ type: DONOR_FORM_UPDATE_ORG, org: data });

const resetDonorForm = () => createAction({ type: DONOR_FORM_RESET });

const setErrorsOrgDonorForm = data => createAction({ type: DONOR_FORM_SET_ERRORS, errors: data });

const updateDonorEditedFields = data => createAction({
  type: DONOR_FORM_UPDATE_EDITED_FIELDS,
  editedFields: data,
});

const saveDonor = ({
  ownerOrgPubId,
  data,
  donorPubId,
}) => async (dispatch) => {
  try {
    dispatch(createAction({ type: DONOR_FORM_SAVE_DONOR_REQUEST }));

    const response = await donorsService.saveDonor({
      ownerOrgPubId,
      data,
      donorPubId,
    });

    dispatch(createAction({
      type: DONOR_FORM_SAVE_DONOR_SUCCESS,
      data: camelCaseKeys(response?.data),
    }));
  } catch (err) {
    dispatch(createAction({
      type: DONOR_FORM_SAVE_DONOR_FAILURE,
      err,
    }));
  }
};

const resetDonorSaving = () => createAction({ type: DONOR_FORM_SAVE_DONOR_RESET });

export const donorFormActions = {
  saveDonor,
  updateUserDonorForm,
  updateOrgDonorForm,
  resetDonorSaving,
  resetDonorForm,
  setErrorsOrgDonorForm,
  updateDonorEditedFields,
};
