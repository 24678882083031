import queryString from 'query-string';
import {
  userService,
} from 'services/user';
import userActionTypes from 'rdx/actionTypes';
import {
  useSelector,
  useDispatch,
} from 'react-redux';

const useLogin = () => {
  const params = queryString.parse(location?.search);
  const { username: usernameToReset } = params;
  const dispatch = useDispatch();

  const {
    isFetching,
    error,
  } = useSelector(state => ({
    isFetching: state.authentication.isFetching,
    error: state.authentication.error,
  }));

  const resendVerificationEmail = (username) => {
    dispatch({ type: userActionTypes.RESEND_VERIFICATION_EMAIL_REQUEST, username });

    userService.resendVerificationEmail(username)
      .then(
        () => {
          dispatch({ type: userActionTypes.RESEND_VERIFICATION_EMAIL_SUCCESS });
        },
        (requestError) => {
          dispatch({ type: userActionTypes.RESEND_VERIFICATION_EMAIL_FAILURE, requestError });
        },
      );
  };

  const loginAction = (username, password) => {
    dispatch({ type: userActionTypes.LOGIN_REQUEST, username });

    userService.login(username, password)
      .then(
        (response) => {
          const { userSession, tokenExpiration } = response;
          const userResponse = {
            user: userSession,
            expiration: tokenExpiration,
          };

          dispatch({ type: userActionTypes.LOGIN_SUCCESS, userResponse });
          dispatch({ type: userActionTypes.REFRESH_ACCESS_TOKEN_TIMER_SETUP });
        },
        (loginError) => {
          dispatch({ type: userActionTypes.LOGIN_FAILURE, error: loginError });
        },
      );
  };
  const clearLoginErrors = () => dispatch({ type: userActionTypes.LOGIN_CLEAR_ERRORS });

  return {
    isFetching,
    error,
    usernameToReset,
    resendVerificationEmail,
    loginAction,
    clearLoginErrors,
  };
};

export {
  useLogin,
};
