import React, {
  useMemo,
} from 'react';
import {
  Box,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  DonorInfoSection,
  DonorHistoryContent,
  EditDonorModal,
  PersonalInfoContent,
  PageTabs,
  StatusDialog,
  Visible,
} from 'components';
import {
  isMobileView,
} from 'lib/utils';
import {
  translate,
} from 'lib/intl';
import {
  useDonorForm,
} from 'lib/hooks';
import {
  colors,
} from 'styles/theme';
import {
  DONOR_PAGE_TABS,
} from './constants';
import {
  useDonorPage,
} from './hooks';

const StyledContainer = styled(Box)`
  height: 100vh;
  overflow-y: scroll;
`;

const StyledTabsContent = styled(Box)`
  flex: 1;
`;

const StyledCommonInfoSectoin = styled(Box)`
  border-right: ${({ mobile }) => (mobile ? 'none' : `1px solid ${colors.transparentGrey}`)};
  width: ${({ mobile }) => (mobile ? 'auto' : '400px')};
`;

const DonorPage = () => {
  const {
    donor,
    fetchDonorInfo,
    id,
    isDonorHistoryTab,
    ownerOrgPubId,
    setTabIdx,
  } = useDonorPage();

  const {
    errors,
    formData,
    isEditModalOpen,
    isFailed,
    isUserForm,
    openEditModal,
    closeEditModal,
    updateForm,
    saveDonor,
    showErrors,
    setErrors,
    toggleDonorForm,
    showStatusDialog,
    closeStatusDialog,
    resetSavingDonor,
  } = useDonorForm({
    onSave: fetchDonorInfo,
    ownerOrgPubId,
    id,
    defaultData: donor,
  });

  const isMobile = isMobileView();

  const PersonalInfo = useMemo(() => (
    <PersonalInfoContent donor={donor} />
  ), [donor]);

  return (
    <StyledContainer
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
    >
      <StyledCommonInfoSectoin
        mobile={Number(isMobile)}
        px={isMobile ? 2 : 3}
        py={1}
      >
        <DonorInfoSection donor={donor} openEditModal={openEditModal} />
      </StyledCommonInfoSectoin>
      <StyledTabsContent py={1}>
        <Box px={isMobile ? 2 : 0} width={isMobile ? 'fit-content' : '100%'}>
          <PageTabs tabs={DONOR_PAGE_TABS} onChange={setTabIdx} />
        </Box>
        <Box>
          <Visible
            fallback={PersonalInfo}
            when={isDonorHistoryTab}
          >
            <DonorHistoryContent />
          </Visible>
        </Box>
      </StyledTabsContent>
      <EditDonorModal
        data={formData}
        errors={errors}
        handleClose={closeEditModal}
        handleSave={saveDonor}
        isUserForm={isUserForm}
        open={isEditModalOpen}
        setErrors={setErrors}
        showErrors={showErrors}
        toggleDonorForm={toggleDonorForm}
        updateData={updateForm}
      />
      <StatusDialog
        open={showStatusDialog}
        onClose={closeStatusDialog}
        onExited={resetSavingDonor}
        isSuccessMessage={!isFailed}
        message={translate(isFailed ? 'TRY_AGAIN' : 'DONOR_UPDATED_MESSAGE')}
        title={translate(isFailed ? 'DEFAULT_ERROR' : 'DONOR_UPDATED_LABEL')}
      />
    </StyledContainer>
  );
};

export {
  DonorPage,
};
