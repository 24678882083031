import React from 'react';
import PropTypes from 'prop-types';
import {
  useMediaQuery, useTheme,
} from '@material-ui/core';
import DesktopNavigation from './desktop-navigation';
import MobileNavigation from './mobile-navigation';

const NavigationControl = ({
  children,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  if (matches) {
    return (
      <DesktopNavigation>
        {children}
      </DesktopNavigation>
    );
  }

  return (
    <MobileNavigation>
      {children}
    </MobileNavigation>
  );
};

NavigationControl.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

NavigationControl.defaultProps = {
  children: null,
};

export {
  NavigationControl,
};
