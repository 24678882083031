export const AUTH = {
  SIGN_OUT: 'Sign out',
  SIGN_IN: 'Sign in',
  SIGN_IN_INSTEAD: 'Sign in instead',
  BACK_TO_SIGN_IN: 'Back to Sign in',
  PASSWORD: 'Password',
  FORGOT_PASSWORD: 'Forgot password',
  RESET_PASSWORD: 'Reset password',
  REGISTER_INSTEAD: 'Create account instead',
  REGISTER_SUCCESS_HEADER: 'You\'re all signed up!',
  REGISTER_EMAIL_LINK_CHECK: 'Check your email for a verification link to continue.',
  REGISTER_EMAIL_LINK_CLOSE_TAB: 'Once you click the link you can close this tab.',
  REGISTER_HEADING_WELCOME: 'Welcome!',
  REGISTER_GET_SET_UP: 'Let\'s get you set up.',
  REGISTER_ORG_SET_UP: 'Let\'s get your Org set up.',
  REGISTER_YOUR_USER_NAME: 'Your user name is:',
  REGISTER_INVITE_VERIFICATION_ERROR: 'Something happened with your invite.',
  REGISTER_INVITE_VERIFICATION_ERROR_CONTACT: 'Please contact your organization administrator to request a new invite.',
  CREATE_ACCOUNT: 'Create Account',
  RESET_PASSWORD_CHOOSE_NEW: 'Choose a new password',
  RESET_PASSWORD_EMAIL: 'Enter your Org email to reset password.',
  RESET_PASSWORD_REQUEST_SUCCESS: 'If an account exists for the submitted email you will receive an email with instructions on resetting your password.',
  RESET_PASSWORD_SET_SUCCESS: 'Your password has been updated!',
  LOGIN_LINK_EXPIRED: 'Your invite link has expired.',
  LOGIN_RESEND_VERIFICATION_EMAIL: 'Resend verification email',
  PERSISTENT_AUTH_ISSUE: 'If the issue persists, please',
  LOGIN_VERIFY_ACCOUNT: 'Looks like you still need to verify your account.',
  LOGIN_CHECK_VERIFY_EMAIL: 'Check your email for the verification link to continue.',
  LOGIN_VERIFICATION_ERROR: 'Something went wrong. Please try again.',
  RESENT_VERIFICATION_EMAIL: 'Resend verification email',
  CHECK_EMAIL_VERIFICATION_LINK: 'Check your email for the verification link!',
  VALIDATION_MAX_CHARACTERS_ALLOWED: 'Maximum {length} Characters Allowed',
  VALIDATION_MIN_CHARACTERS_REQUIRED: 'Minimum {length} Characters Required',
  VALIDATION_ORG_NAME_REQUIRED: 'Org Name Required',
  VALIDATION_ORG_EMAIL_REQUIRED: 'Org Email Required',
  VALIDATION_INVALID_EMAIL: 'Invalid email',
  VALIDATION_RECAPTCHA_REQUIRED: 'reCAPTCHA Required',
  VALIDATION_PASSWORD_REQUIRED: 'Password required',
};
