import React from 'react';
import {
  func,
  node,
  string,
} from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Gap,
  Visible,
} from 'components';
import {
  colors,
} from 'styles/theme';
import {
  CustomButtonType,
} from 'lib/utils';
import { EMPTY_FUNC } from 'lib/constants';

const useStyles = makeStyles(theme => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: colors.white,
    boxShadow: 'none',
    '& span svg rect, & span svg path': {
      fill: colors.white,
    },
    '& span svg g, & span svg g': {
      opacity: 1,
    },
    '&:disabled': {
      backgroundColor: colors.offWhite,
      color: colors.darkGrey,
      '& span svg rect, & span svg path': {
        fill: colors.darkGrey,
      },
    },
    '&:hover': {
      backgroundColor: colors.darkerGreenHover,
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: colors.darkGreen,
      boxShadow: 'none',
    },
  },
  secondary: {
    backgroundColor: colors.lightGreen,
    color: colors.darkGreen,
    boxShadow: 'none',
    '& span svg rect, & span svg path': {
      fill: colors.darkGreen,
    },
    '& span svg g, & span svg g': {
      opacity: 1,
    },
    '&:disabled': {
      backgroundColor: colors.offWhite,
      color: colors.darkGrey,
      '& span svg rect, & span svg path': {
        fill: colors.darkGrey,
      },
    },
    '&:focused': {
      backgroundColor: colors.lightGreen,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&:hover': {
      backgroundColor: colors.darkerLightGreenHover,
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
    },
  },
  tertiary: {
    backgroundColor: colors.white,
    color: colors.darkGrey,
    border: `1px solid ${colors.darkGrey}`,
    boxShadow: 'none',
    '& span svg rect, & span svg path': {
      fill: colors.darkGrey,
    },
    '& span svg g, & span svg g': {
      opacity: 1,
    },
    '&:disabled': {
      backgroundColor: colors.offWhite,
      color: colors.darkGrey,
      '& span svg rect, & span svg path': {
        fill: colors.darkGrey,
      },
    },
    '&:hover': {
      backgroundColor: colors.white,
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: 'none',
      '& span svg rect, & span svg path': {
        fill: theme.palette.primary.main,
      },
    },
    '&:active': {
      backgroundColor: colors.lightGreen,
      color: colors.darkerGreenHover,
      border: `1px solid ${colors.darkerGreenHover}`,
      boxShadow: 'none',
      '& span svg rect, & span svg path': {
        fill: theme.palette.primary.main,
      },
    },
  },
  floating: {
    backgroundColor: colors.white,
    color: theme.palette.primary.main,
    '& span svg rect, & span svg path': {
      fill: colors.darkGreen,
    },
    '& span svg g, & span svg g': {
      opacity: 1,
    },
    '&:disabled': {
      backgroundColor: colors.offWhite,
      color: colors.darkGrey,
      '& span svg rect, & span svg path': {
        fill: colors.darkGrey,
      },
    },
    '&:hover': {
      backgroundColor: colors.lightGreen,
      color: colors.darkGreen,
      border: `1px solid ${theme.palette.primary.main}`,
      '& span svg rect, & span svg path': {
        fill: colors.darkGreen,
      },
    },
    '&:active': {
      backgroundColor: colors.darkerLightGreenHover,
      color: colors.darkGreen,
      border: `1px solid ${theme.palette.primary.main}`,
      '& span svg rect, & span svg path': {
        fill: colors.darkGreen,
      },
    },
  },
  link: {
    background: 'none',
    color: theme.palette.primary.main,
    '& span svg rect, & span svg path': {
      fill: theme.palette.primary.main,
    },
    '& span svg g, & span svg g': {
      opacity: 1,
    },
    '&:disabled': {
      color: colors.darkGrey,
      '& span svg rect, & span svg path': {
        fill: colors.darkGrey,
      },
    },
    '&:hover': {
      backgroundColor: colors.darkerLightGreenHover,
      color: colors.darkGreen,
      '& span svg rect, & span svg path': {
        fill: colors.darkGreen,
      },
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
      color: colors.darkGreen,
      '& span svg rect, & span svg path': {
        fill: colors.darkGreen,
      },
    },
  },
}));

const StyledButton = styled(Button)`
  border-radius: 100px;
  height: 56px;
  display: flex;
  align-items: center;
  text-transform: none;
  min-width: 100px;
  padding: 0 32px;
`;

const CustomButton = ({
  prefix,
  label,
  id,
  buttonType,
  onClick,
  postfix,
  ...rest
}) => {
  const styles = useStyles();

  return (
    <StyledButton
      className={styles[buttonType]}
      color="primary"
      data-testid={id}
      onClick={onClick}
      {...rest}
    >
      <Visible when={prefix}>
        {prefix}
        <Gap />
      </Visible>
      <Typography variant="body1">
        {label}
      </Typography>
      <Visible when={postfix}>
        {postfix}
        <Gap />
      </Visible>
    </StyledButton>
  );
};

CustomButton.propTypes = {
  buttonType: CustomButtonType,
  id: string,
  label: string.isRequired,
  onClick: func,
  postfix: node,
  prefix: node,
};

CustomButton.defaultProps = {
  buttonType: 'primary',
  id: 'custom-button',
  postfix: null,
  prefix: null,
  onClick: EMPTY_FUNC,
};

export {
  CustomButton,
};
