import queryString from 'query-string';
import { userService } from 'services/user';
import userActionTypes from 'rdx/actionTypes';
import {
  useSelector,
  useDispatch,
} from 'react-redux';

const useRegistration = () => {
  const params = queryString.parse(location?.search);
  const { code, email, org_name: orgName } = params;
  // TODO DOT-87 refactor all dispatch actions to hook file
  const dispatch = useDispatch();

  const {
    isFetching,
    isRegistrationSuccessful,
    error,
  } = useSelector(state => ({
    isFetching: state.registration.isFetching,
    isRegistrationSuccessful: state.registration.isRegistrationSuccessful,
    error: state.registration.error,
  }));

  const registerAction = (user) => {
    dispatch({ type: userActionTypes.REGISTER_REQUEST, user });

    userService.register(user)
      .then(
        () => {
          dispatch({ type: userActionTypes.REGISTER_SUCCESS });
        },
        (registerError) => {
          // We do not want to fail the registration flow on the DotOrg Custom request at this time
          // Detect a failure on the dotorg request and pass the user through to be handled later
          // This may change in the future
          if (registerError && registerError.error_enum) {
            dispatch({ type: userActionTypes.REGISTER_SUCCESS });
          } else {
            dispatch({ type: userActionTypes.REGISTER_FAILURE, registerError });
          }
        },
      );
  };

  const resetRegistrationState = () => ({ type: userActionTypes.REGISTER_RESET_ALL });

  return {
    code,
    email,
    orgName,
    registerAction,
    resetRegistrationState,
    isFetching,
    isRegistrationSuccessful,
    error,
  };
};

export {
  useRegistration,
};
