import userActionTypes from 'rdx/actionTypes';

const {
  INVITES_REQUEST,
  INVITES_SUCCESS,
  INVITES_FAILURE,
} = userActionTypes;

const initialState = {
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: {},
};

export function invites(
  state = initialState,
  action,
) {
  switch (action.type) {
    case INVITES_REQUEST:
      return {
        ...state,
        dataLoaded: false,
        isFetching: true,
        error: null,
      };
    case INVITES_SUCCESS:
      return {
        ...state,
        data: action.data,
        dataLoaded: true,
        isFetching: false,
        error: null,
      };
    case INVITES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
