import {
  useParams,
  useHistory,
} from 'react-router-dom';
import {
  useEffect,
} from 'react';
import {
  AUTH_ROUTES,
} from 'lib/constants';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  feedActions,
  donorsActions,
  usersActions,
} from 'rdx/actions';

const useHome = () => {
  const history = useHistory();
  const { id: activeFeedItemId } = useParams();
  const dispatch = useDispatch();

  const {
    feed,
  } = useSelector(state => ({
    feed: state.fetchFeed,
  }));

  const getFeedPage = page => dispatch(feedActions.fetchFeed({
    page,
    pageSize: 10,
  }));

  const setActiveFeedItem = itemId => history.push(`/feed/${itemId}`);

  return {
    feed,
    getFeedPage,
    setActiveFeedItem,
    activeFeedItemId: parseInt(activeFeedItemId, 10),
  };
};

// Fetches initial page, handles active feed item scroll logic
const feedControl = (
  activeFeedItemId,
  mobileActiveItem,
  isMobile,
  isFetching,
  itemRefs,
  layoutContainerRef,
  getFeedPage,
  feed,
  scrollToRef,
) => useEffect(() => {
  let foundItem;
  const { currentPage, items } = feed;

  // Fetch the first page of the feed
  if (!isFetching && items.length === 0) {
    getFeedPage(currentPage);
  // If active item is present, begin querying until that item is reached
  } else if (items && activeFeedItemId) {
    foundItem = items.find(item => item.feed_item_pub_id === activeFeedItemId);

    // Logic to load until feed item is found
    if (!isFetching && !foundItem) {
      getFeedPage(currentPage + 1);
    }

    if (foundItem) {
      scrollToRef(itemRefs[foundItem.feed_item_pub_id]);
    }
  }

  // TODO refactor this part to be cleaner once behavior is approved
  // Mobile feed styling control for locking / scrolling container
  /* eslint-disable no-param-reassign */
  if (layoutContainerRef) {
    if (mobileActiveItem) {
      layoutContainerRef.current.style.overflowY = 'hidden';
    } else if (isMobile) {
      layoutContainerRef.current.style.overflowY = 'auto';
    } else {
      layoutContainerRef.current.style.overflowY = 'hidden';
    }
  }
}, [feed, mobileActiveItem]);

const useOutsideAlerter = (ref, history, isMobile) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (isMobile && ref.current && !ref.current.contains(event.target)) {
        history.push(AUTH_ROUTES.LANDING);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchend', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchend', handleClickOutside);
    };
  }, [ref, isMobile]);
};

const useRecentlyViewedDonors = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(donorsActions.fetchRecentlyViewedDonors());
  }, []);

  const {
    recentlyViewedDonors,
  } = useSelector(state => ({
    recentlyViewedDonors: state.recentlyViewedDonors,
  }));

  return {
    recentlyViewedDonors,
  };
};

const useUser = () => {
  const dispatch = useDispatch();

  const {
    goals,
    visits,
    userPubId,
  } = useSelector(state => ({
    goals: state.goals,
    visits: state.visits,
    userPubId: state.authentication?.user?.user?.userPubId || '',
  }));

  useEffect(() => {
    dispatch(usersActions.fetchUser(userPubId));
  }, []);

  return {
    goals,
    visits,
  };
};

export {
  useHome,
  feedControl,
  useOutsideAlerter,
  useRecentlyViewedDonors,
  useUser,
};
