import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  Box,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import {
  useHistory,
  Link,
} from 'react-router-dom';
import {
  useForm,
} from 'react-hook-form';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {
  AuthActionButton,
} from 'components/auth/auth-action-button';
import {
  Visible,
} from 'components';
import {
  PUBLIC_ROUTES,
} from 'lib/constants';
import {
  translate,
} from 'lib/intl';
import {
  authNavLinkStyle,
} from './styles';
import {
  emailRegex, userNotConfirmedError,
} from './constants';

const {
  VERIFICATION,
  RESET_PASSWORD,
} = PUBLIC_ROUTES;

const LoginForm = ({
  isFetching,
  error,
  loginAction,
  clearLoginErrors,
}) => {
  const history = useHistory();
  const {
    register, handleSubmit, errors,
  } = useForm();

  const [submittedOrgEmail, setSubmittedOrgEmail] = useState();
  const [password, setPassword] = useState({
    value: '',
    showPassword: false,
  });

  if (error && error.code === userNotConfirmedError) {
    clearLoginErrors();
    history.push(`${VERIFICATION}?username=${encodeURIComponent(submittedOrgEmail)}`);
  }

  const handlePasswordChange = () => (event) => {
    setPassword({
      ...password,
      value: event.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Box
        pb={4}
        display="flex"
      >
        <Typography
          variant="h5"
        >
          {translate('SIGN_IN')}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
      >
        <form onSubmit={handleSubmit((data) => {
          const { orgEmail, password: enteredPassword } = data;
          setSubmittedOrgEmail(orgEmail);

          return loginAction(orgEmail, enteredPassword);
        })}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                error={!!errors.orgEmail}
                helperText={errors.orgEmail ? errors.orgEmail.message : ''}
                name="orgEmail"
                label="Org Email Address"
                variant="outlined"
                inputRef={register({
                  required: translate('VALIDATION_ORG_EMAIL_REQUIRED'),
                  pattern: {
                    // eslint-disable-next-line
                    value: emailRegex,
                    message: translate('VALIDATION_INVALID_EMAIL'),
                  },
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FormControl
                fullWidth
                error={!!errors.password}
                variant="outlined"
              >
                <InputLabel
                  htmlFor="outlined-adornment-password"
                >
                  {translate('PASSWORD')}
                </InputLabel>
                <OutlinedInput
                  name="password"
                  id="outlined-adornment-password"
                  fullWidth
                  type={password.showPassword ? 'text' : 'password'}
                  value={password.value}
                  onChange={handlePasswordChange('password')}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {password.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  labelWidth={75}
                  inputRef={
                    register({
                      required: {
                        value: true,
                        message: translate('VALIDATION_PASSWORD_REQUIRED'),
                      },
                    })
                  }
                />
                {errors && errors.password
                  ? <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{errors.password.message}</p>
                  : null
                }
              </FormControl>
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-end"
            width="100%"
          >
            <Link
              to={{ pathname: RESET_PASSWORD }}
              style={authNavLinkStyle}
            >
              {translate('FORGOT_PASSWORD')}
            </Link>
          </Box>
          <Visible
            when={error}
          >
            <Grid
              container
              item
              xs={12}
            >
              <FormHelperText
                error={!!error}
                style={{
                  textAlign: 'center',
                  marginTop: '20px',
                  width: '100%',
                }}
              >
                {error?.message}
              </FormHelperText>
            </Grid>
          </Visible>
          <AuthActionButton
            text={translate('SIGN_IN')}
            isFetching={isFetching}
          />
        </form>
      </Box>
    </Box>
  );
};

LoginForm.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  loginAction: PropTypes.func.isRequired,
  clearLoginErrors: PropTypes.func.isRequired,
  error: PropTypes.shape({ code: {}, message: {} }),
};

LoginForm.defaultProps = {
  error: null,
};

export {
  LoginForm,
};
