import userActionTypes from 'rdx/actionTypes';

const {
  DONOR_FORM_UPDATE_USER,
  DONOR_FORM_UPDATE_ORG,
  DONOR_FORM_RESET,
  DONOR_FORM_SET_ERRORS,
  DONOR_FORM_UPDATE_EDITED_FIELDS,
} = userActionTypes;

const initialState = {
  user: {
    donorType: 'odu',
    firstName: '',
    lastName: '',
    email: '',
    dob: '',
    avatar: null,
    acquaintances: null,
    connections: null,
    affiliations: null,
    spouse: '',
    addresses: {
      mailing: {
        street: '',
        apt: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      work: {
        street: '',
        apt: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
    },
    emails: {
      primary: '',
      home: '',
      work: '',
    },
    phones: {
      primary: '',
      home: '',
      mobile: '',
      work: '',
    },
    facebook: '',
    instagram: '',
    linkedin: '',
    twitter: '',
    notes: {
      n1: '',
      n2: '',
      n3: '',
    },
    employer: {
      employerName: '',
      jobTitle: '',
      employerWebsite: '',
      social: {
        facebook: '',
        instagram: '',
        linkedin: '',
        twitter: '',
      },
      website: '',
    },
  },
  org: {
    donorType: 'odo',
    orgName: '',
    description: '',
    avatar: null,
    website: '',
    addresses: {
      business: {
        street: '',
        apt: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
    },
    developers: {
      dev1: null,
      dev2: null,
    },
    affiliations: null,
    employees: null,
    emails: {
      billing: '',
      sales: '',
    },
    phones: {
      frontDesk: '',
      billing: '',
    },
    facebook: '',
    instagram: '',
    linkedin: '',
    twitter: '',
    notes: {
      n1: '',
      n2: '',
      n3: '',
    },
  },
  errors: [],
  editedFields: [],
};

export function donorForm(
  state = initialState,
  action,
) {
  switch (action.type) {
    case DONOR_FORM_UPDATE_USER:
      return {
        ...state,
        user: action.user,
      };
    case DONOR_FORM_UPDATE_ORG:
      return {
        ...state,
        org: action.org,
      };
    case DONOR_FORM_RESET:
      return initialState;
    case DONOR_FORM_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    case DONOR_FORM_UPDATE_EDITED_FIELDS:
      return {
        ...state,
        editedFields: action.editedFields,
      };
    default:
      return state;
  }
}
