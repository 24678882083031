
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  invitesActions,
} from 'rdx/actions';
import {
  ZERO,
} from 'lib/constants';
import {
  INVITE_STATUSES,
} from 'components/invite/constants';
import {
  isValidEmail,
  lowerCase,
  sortByCategories,
} from 'lib/utils';
import userActionTypes from 'rdx/actionTypes';

const {
  INVITE_FORM_SET_HAS_ERROR,
} = userActionTypes;

const useInvitePage = () => {
  const [tabIdx, setTabIdx] = useState(ZERO);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

  const dispatch = useDispatch();

  const {
    sendingInvites,
    invites,
    updatingInvites,
    ownerOrgPubId,
    ownerOrgName,
  } = useSelector(state => ({
    sendingInvites: state.sendingInvites,
    invites: state.invites.data.invites || [],
    updatingInvites: state.updatingInvites,
    ownerOrgPubId: state?.authentication.user?.org?.ownerOrgPubId,
    ownerOrgName: state?.authentication.user?.org?.ownerOrgName,
  }));

  const fetchInvites = () => dispatch(invitesActions.fetchInvites(ownerOrgPubId));

  useEffect(() => {
    fetchInvites();
  }, []);

  useEffect(() => {
    if (sendingInvites.dataLoaded) {
      setSuccessModalOpen(true);
      fetchInvites();
    }
  }, [sendingInvites]);

  useEffect(() => {
    if (updatingInvites.dataLoaded) {
      fetchInvites();
    }
  }, [updatingInvites]);

  const isFormDisabled = useMemo(() => sendingInvites.isFetching, [sendingInvites]);

  const closeModal = useCallback(() => setSuccessModalOpen(false), []);

  const updateInvite = useCallback(({
    invitePubId,
    ...data
  }) => {
    dispatch(invitesActions.updateInvites(invitePubId, data));
  }, []);

  const sendInvites = useCallback(({
    emails,
    groups,
  }) => {
    const valid = emails?.length && emails?.every(email => isValidEmail(email));

    if (valid) {
      dispatch(invitesActions.sendInvites(ownerOrgPubId, {
        emails,
        groups: groups?.map(group => lowerCase(group)),
        ownerOrgName,
      }));
    } else {
      dispatch({ type: INVITE_FORM_SET_HAS_ERROR, hasError: true });
    }
  }, [
    ownerOrgPubId,
    ownerOrgName,
  ]);

  const sortedInvites = useMemo(() => sortByCategories(invites, 'status', Object.values(INVITE_STATUSES)), [
    invites,
  ]);

  const updatingInviteIds = updatingInvites?.invitePubIds || [];

  return {
    // redux data
    // TODO: @anna - update with real groups once available
    groups: [
      'editors',
      'viewers',
    ],
    invites: sortedInvites,
    // state data
    isFormDisabled,
    isSuccessModalOpen,
    tabIdx,
    // handlers
    closeModal,
    updateInvite,
    sendInvites,
    setTabIdx,
    updatingInviteIds,
  };
};

export {
  useInvitePage,
};
