import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import {
  thumbsUpSmall,
  thumbsDownSmall,
} from 'assets/images';
import {
  Visible,
} from 'components';

const useStyles = makeStyles(theme => ({
  streakIndicatorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    border: `1px solid ${theme.palette.grey.light}`,
    borderRadius: '50%',
    '&.missing': {
      border: `1px solid ${theme.palette.error.main}`,
    },
    '&.success': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

const StreakDay = ({
  isStreakDay,
}) => {
  const classes = useStyles();
  let modifierClass;
  const isStreakDayNull = isStreakDay === null;

  if (isStreakDayNull) {
    modifierClass = '';
  } else if (isStreakDay) {
    modifierClass = 'success';
  } else {
    modifierClass = 'missing';
  }

  return (
    <Box
      data-testid="streak-day-container"
      className={`${classes.streakIndicatorContainer} ${modifierClass}`}
    >
      <Visible when={!isStreakDayNull}>
        <img
          data-testid="streak-day-icon"
          alt="Thumb Icon"
          src={isStreakDay ? thumbsUpSmall : thumbsDownSmall}
        />
      </Visible>
    </Box>
  );
};

StreakDay.propTypes = {
  isStreakDay: PropTypes.bool,
};

StreakDay.defaultProps = {
  isStreakDay: null,
};

export {
  StreakDay,
};
