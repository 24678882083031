
import { Auth } from 'aws-amplify';
import {
  AUTH_USER_ID_TOKEN,
} from 'lib/constants';
import {
  createApiRequest,
} from 'lib/utils';
import userActionTypes from 'rdx/actionTypes';
import store from '../rdx/store';

// Builds out the user session object
const buildUserSessionObject = (cognitoData, contextData) => {
  const { signInUserSession: { accessToken, idToken } } = cognitoData;
  const {
    data: { context: { contexts, email }, setHeaders: { xDotorgAuthorization } },
  } = contextData;

  // TODO in later phases we plan to support multiple contexts
  const primaryContext = contexts[0];
  const { user, org } = primaryContext;

  return {
    cognito: {
      accessToken: accessToken.jwtToken,
      idToken: idToken.jwtToken,
    },
    user: {
      ...user,
      email,
    },
    org,
    dotOrgAuthHeader: xDotorgAuthorization,
  };
};

const dotOrgRegisterAction = data => createApiRequest({
  data,
  path: 'registration',
  useAuth: false,
});

const getContext = data => createApiRequest({
  data,
  method: 'get',
  path: 'context',
});

function isAuthenticated() {
  const token = localStorage.getItem(AUTH_USER_ID_TOKEN);

  return token && typeof token !== 'undefined';
}

function setAccessIdToken(idToken) {
  localStorage.setItem(AUTH_USER_ID_TOKEN, idToken);
}

function setAccessIdTokenFromCognitoUserResponse(response) {
  const idToken = response.signInUserSession.idToken.jwtToken;

  setAccessIdToken(idToken);
}

function handleAmplifyResponse(response) {
  // TODO potentially remove this response handler

  return response;
}

function login(username, password) {
  let cognitoResponse;

  return Auth.signIn(username, password)
    .then(handleAmplifyResponse)
    .then((response) => {
      cognitoResponse = response;

      // Set access ID token immediately for subsequent context request
      setAccessIdTokenFromCognitoUserResponse(cognitoResponse);

      return getContext();
    })
    .then((contextResponse) => {
      const userSession = buildUserSessionObject(cognitoResponse, contextResponse);
      const tokenExpiration = cognitoResponse.signInUserSession.idToken.payload.exp;

      return { userSession, tokenExpiration };
    });
}

function register(registrationInfo) {
  const {
    firstName, lastName, orgEmail: email, orgName, phoneNumber, password, EIN,
  } = registrationInfo;

  // Add + to phone number to fit amplify formatting constraints
  // eslint-disable-next-line
  const formattedPhoneNumber = phoneNumber ? `+${phoneNumber}` : null;
  const formattedEin = EIN ? EIN.replace(/-/g, '') : null;
  const phoneOrEin = formattedPhoneNumber || formattedEin;
  const dotOrgRegistrationPayload = {
    email,
    first_name: firstName,
    last_name: lastName,
    country_uid: phoneOrEin,
    org_name: orgName,
  };

  return Auth.signUp({
    username: email,
    password,
    attributes: {
      email,
      name: `${firstName} ${lastName}`,
      // eslint-disable-next-line
      ['custom:firstOrgReg']: `{\"f_name\":\"${firstName}\",\"l_name\": \"${lastName}\", \"org_cc\":\"US\",\"org_id\":\"${orgName}\"}`,
    },
  })
    .then(handleAmplifyResponse)
    .then(() => dotOrgRegisterAction(
      dotOrgRegistrationPayload,
    )
      // This catch is here to swallow any errors that occur during the dotOrg registration request
      // The current plan is to resolve any such errors at a later time
      // So the user will technically be able to login, but the context service will fail
      .catch((error) => {
        // eslint-disable-next-line
        console.warn('DotOrg registration error', error);
      }));
}

function resendVerificationEmail(username) {
  return Auth.resendSignUp(username)
    .then(handleAmplifyResponse);
}

function resetPassword(username) {
  return Auth.forgotPassword(username)
    .then(handleAmplifyResponse);
}

function resetPasswordSubmit(username, code, password) {
  return Auth.forgotPasswordSubmit(username, code, password)
    .then(handleAmplifyResponse);
}

async function refreshUserSession() {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const { refreshToken } = cognitoUser.getSignInUserSession();
    const accessIdToken = await new Promise((resolve, reject) => {
      cognitoUser.refreshSession(refreshToken, (err, session) => {
        if (err || (!session || !session.idToken || !session.idToken.jwtToken)) {
          reject(err);
        } else {
          const { idToken: { jwtToken, payload: { exp } } } = session;
          const response = {
            token: jwtToken,
            expiration: exp,
          };

          resolve(response);
        }
      });
    });

    return accessIdToken;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Unable to refresh user token, potentially no logged in user', e);
    return e;
  }
}

async function logout() {
  try {
    await Auth.signOut({ global: false });

    store.dispatch({ type: userActionTypes.LOGOUT });

    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Error signing out of cognito', error);

    return false;
  }
}

function createRefreshInterval(intervalDuration) {
  const refreshIntervalRef = setInterval(() => {
    refreshUserSession().then((tokenResponse) => {
      store.dispatch({ type: userActionTypes.REFRESH_ACCESS_TOKEN, tokenResponse });
    });
  }, intervalDuration);

  return refreshIntervalRef;
}

function refreshAndCreateRefreshInterval() {
  refreshUserSession().then((tokenResponse) => {
    store.dispatch({ type: userActionTypes.REFRESH_ACCESS_TOKEN, tokenResponse });
    store.dispatch({ type: userActionTypes.REFRESH_ACCESS_TOKEN_TIMER_SETUP });
  });
}

export const userService = {
  login,
  logout,
  register,
  isAuthenticated,
  resendVerificationEmail,
  resetPassword,
  resetPasswordSubmit,
  refreshUserSession,
  createRefreshInterval,
  refreshAndCreateRefreshInterval,
};
