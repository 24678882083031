import React, {
  useMemo,
} from 'react';
import {
  bool,
  func,
} from 'prop-types';
import {
  Box,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  translate,
} from 'lib/intl';
import {
  DonorType,
} from 'lib/utils';
import {
  Visible,
} from 'components';
import {
  EditDonorSectionWrapper,
} from './edit-donor-section-wrapper';
import {
  SectionsGenerator,
} from './sections-generator';
import {
  ORG_PRIMARY_CONTACT_INFO_CONFIG,
  ORG_SECONDARY_CONTACT_INFO_CONFIG,
} from './config';

const StyledButtonContainer = styled(Box)`
  position: relative;
`;

const StyledButton = styled(Box)`
  cursor: pointer;
  position: absolute;
  right: -60px;

  p {
    font-size: 14px;
    font-weight: 500;
  }
`;

const EditDonorContactInfo = ({
  data,
  isPrimaryContact,
  showSecondaryContact,
  toggleSecondaryContact,
  updateData,
}) => {
  const title = useMemo(() => translate(isPrimaryContact
    ? 'PRIMARY_CONTACT_INFO'
    : 'SECONDARY_CONTACT_INFO'), []);

  const config = useMemo(() => (isPrimaryContact
    ? ORG_PRIMARY_CONTACT_INFO_CONFIG
    : ORG_SECONDARY_CONTACT_INFO_CONFIG), []);

  return (
    <EditDonorSectionWrapper
      defaultExpanded={showSecondaryContact}
      id="edit-donor-contact-info-section"
      title={title}
    >
      <SectionsGenerator
        config={config}
        data={data}
        updateData={updateData}
      />
      <Visible when={(isPrimaryContact && !showSecondaryContact) || !isPrimaryContact}>
        <StyledButtonContainer
          justifyContent="flex-end"
          display="flex"
          py={3}
        >
          <StyledButton onClick={toggleSecondaryContact}>
            <Typography color="primary">
              {translate(isPrimaryContact ? 'ADD_SECONDARY_CONTACT' : 'REMOVE_CONTACT')}
            </Typography>
          </StyledButton>
        </StyledButtonContainer>
      </Visible>
    </EditDonorSectionWrapper>
  );
};

EditDonorContactInfo.propTypes = {
  data: DonorType.isRequired,
  isPrimaryContact: bool.isRequired,
  showSecondaryContact: bool.isRequired,
  toggleSecondaryContact: func.isRequired,
  updateData: func.isRequired,
};

export {
  EditDonorContactInfo,
};
