import userActionTypes from 'rdx/actionTypes';

const initialState = {
  isFetching: false,
  isResetRequestSuccessful: false,
  error: null,
};

export function resetPassword(
  state = initialState,
  action,
) {
  switch (action.type) {
    case userActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case userActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isResetRequestSuccessful: true,
      };
    case userActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case userActionTypes.RESET_PASSWORD_RESET:
      return {
        ...state,
        isFetching: false,
        isResetRequestSuccessful: false,
        error: null,
      };
    default:
      return state;
  }
}
