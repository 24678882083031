import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import {
  bool,
  func,
  string,
  node,
  object,
} from 'prop-types';
import {
  Box,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  KEY_RETURN,
} from 'keycode-js';
import styled from 'styled-components';
import {
  lensProp,
  path,
  set,
} from 'rambdax';
import {
  Gap,
  Visible,
} from 'components';
import {
  EMPTY_FUNC,
} from 'lib/constants';

const StyledContainer = styled(Box)`
  cursor: ${({ editable }) => (editable === 'true' ? 'pointer' : 'default')};
`;

const StyledEmailInput = styled(TextField)`
  .MuiFilledInput-root {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
`;

const EditableText = (({
  editable,
  icon,
  label,
  placeholder,
  submitChange,
  value,
  valuePath,
}) => {
  const [currentValue, setCurrentValue] = useState(path(valuePath, value) || '');
  const [isEditing, setIsEditing] = useState(false);

  const inputRef = useRef(null);

  const TextValue = useMemo(() => (
    <Typography
      color={currentValue ? 'textPrimary' : 'textSecondary'}
      data-testid="editable-text-value"
      variant="body1"
    >
      {currentValue || placeholder}
    </Typography>
  ), [currentValue]);

  const turnOnEditMode = useCallback(() => {
    if (editable) {
      setIsEditing(true);
    }
  }, [editable]);

  const onChange = useCallback(({ target }) => {
    setCurrentValue(target?.value);
  }, []);

  const onSumbit = useCallback(() => {
    // eslint-disable-next-line
    inputRef?.current?.blur();
    setIsEditing(false);
    submitChange(set(lensProp(valuePath), currentValue, value));
  }, [currentValue]);

  const onKeyDown = useCallback((e) => {
    if (e.keyCode === KEY_RETURN) {
      onSumbit();
    }
  }, [
    onSumbit,
  ]);

  useEffect(() => {
    setCurrentValue(path(valuePath, value));
  }, [value]);

  useEffect(() => {
    if (isEditing) {
      // eslint-disable-next-line
      inputRef?.current?.childNodes[1]?.childNodes[0]?.focus();
    }
  }, [isEditing]);

  return (
    <StyledContainer
      data-testid="editable-text"
      display="flex"
      editable={String(editable)}
      onClick={turnOnEditMode}
    >
      <Visible when={icon}>
        <Box>
          {icon}
          <Gap size={1} />
        </Box>
      </Visible>
      <Visible
        fallback={TextValue}
        when={editable && isEditing}
      >
        <StyledEmailInput
          fullWidth
          onChange={onChange}
          onBlur={onSumbit}
          onKeyDown={onKeyDown}
          label={label}
          placeholder={placeholder}
          ref={inputRef}
          value={currentValue}
        />
      </Visible>
    </StyledContainer>
  );
});

EditableText.propTypes = {
  editable: bool,
  icon: node,
  label: string,
  placeholder: string,
  submitChange: func,
  // eslint-disable-next-line
  value: object,
  valuePath: string,
};

EditableText.defaultProps = {
  editable: true,
  icon: null,
  label: '',
  placeholder: '',
  submitChange: EMPTY_FUNC,
  valuePath: '',
  value: {},
};

export {
  EditableText,
};
