import React, {
  useCallback,
  useMemo,
} from 'react';
import {
  func,
  string,
} from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  Typography,
} from '@material-ui/core';
import {
  Avatar,
  LastInteraction,
  Gap,
} from 'components';
import {
  getInitials,
} from 'lib/utils';
import {
  EMPTY_FUNC,
} from 'lib/constants';
import {
  colors,
} from 'styles/theme';

const StyledContainer = styled(Box)`
  background: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 8px;
  cursor: pointer;
`;

const DonorCard = ({
  avatar,
  donorPubId,
  displayName,
  onClick,
  updated,
}) => {
  const initials = useMemo(() => getInitials(displayName), [
    displayName,
  ]);

  const onDonorClick = useCallback(() => onClick(donorPubId), [donorPubId]);

  return (
    <StyledContainer
      alignItems="center"
      display="flex"
      onClick={onDonorClick}
      p={2}
    >
      <Avatar src={avatar}>
        {initials}
      </Avatar>
      <Gap size={2} />
      <Box>
        <Typography variant="h5">{displayName}</Typography>
        <LastInteraction date={updated} />
      </Box>
    </StyledContainer>
  );
};

DonorCard.propTypes = {
  avatar: string,
  displayName: string,
  donorPubId: string.isRequired,
  onClick: func,
  updated: string,
};

DonorCard.defaultProps = {
  avatar: '',
  displayName: '',
  onClick: EMPTY_FUNC,
  updated: '',
};

export {
  DonorCard,
};
