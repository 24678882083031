import React from 'react';
import {
  string,
} from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  Typography,
} from '@material-ui/core';
import {
  LeftArrowIcon,
} from 'assets/images';
import {
  Gap,
} from 'components';
import {
  useBackButton,
} from './hooks';

const StyledBackButton = styled(Box)`
  cursor: pointer;
`;

const BackButton = ({
  label,
  path,
}) => {
  const { onBackClick } = useBackButton({ path });

  return (
    <StyledBackButton
      alignItems="center"
      justifyItems="center"
      data-testid="back-button"
      display="flex"
      flexDirection="row"
      onClick={onBackClick}
    >
      <LeftArrowIcon />
      <Gap />
      <Typography variant="caption">
        {label}
      </Typography>
    </StyledBackButton>
  );
};

BackButton.propTypes = {
  path: string,
  label: string.isRequired,
};

BackButton.defaultProps = {
  path: '',
};

export {
  BackButton,
};
