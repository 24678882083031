import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import {
  Visible,
} from 'components';
import ClearIcon from '@material-ui/icons/Clear';

const OrgName = ({
  orgName,
}) => (
  <Visible when={orgName}>
    <ClearIcon
      style={{
        fontSize: '12px',
        marginLeft: '12px',
      }}
    />
    <Typography
      variant="body1"
      style={{
        marginLeft: '8px',
      }}
    >
      {orgName}
    </Typography>
  </Visible>
);

OrgName.propTypes = {
  orgName: PropTypes.string,
};

OrgName.defaultProps = {
  orgName: null,
};

export {
  OrgName,
};
