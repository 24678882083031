import React, {
  useCallback,
  useMemo,
} from 'react';
import {
  arrayOf,
  func,
  string,
  bool,
} from 'prop-types';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Box,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import {
  translate,
} from 'lib/intl';
import {
  Loader,
  Visible,
  CustomButton,
} from 'components';
import {
  extractEmails,
  isMobileView,
} from 'lib/utils';
import userActionTypes from 'rdx/actionTypes';
import {
  InviteSelector,
} from './invite-selector';
import {
  EmailChip,
} from './email-chip';
import {
  EmailInput,
} from './email-input';

const {
  INVITE_FORM_UPDATE_GROUPS,
  INVITE_FORM_UPDATE_EMAILS,
} = userActionTypes;

const useStyles = makeStyles(theme => ({
  emailsSection: {
    background: theme.palette.grey[300],
  },
  emailsContainer: {
    maxHeight: 'calc(100vh - 385px)',
    overflow: 'scroll',
  },
}));

const InviteForm = ({
  groups,
  isUpdating,
  sendInvites,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = isMobileView();

  const dispatch = useDispatch();

  const {
    groups: selectedGroups,
    emails,
    hasError,
  } = useSelector(state => state.inviteForm);

  const updateGroups = newGroups => dispatch({
    type: INVITE_FORM_UPDATE_GROUPS,
    groups: newGroups,
  });

  const updateEmails = newEmails => dispatch({
    type: INVITE_FORM_UPDATE_EMAILS,
    emails: newEmails,
  });

  const deleteEmail = useCallback((idx) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(idx, 1);

    updateEmails(updatedEmails);
  }, [emails]);

  const addEmail = useCallback((newEmail) => {
    const updatedEmails = [...emails, newEmail];

    updateEmails(updatedEmails);
  }, [emails]);

  const addPastedEmails = useCallback((newEmails) => {
    const updatedEmails = [...emails, ...extractEmails(newEmails)];

    updateEmails(updatedEmails);
  }, [emails]);

  const onSendInvites = useCallback(() => {
    sendInvites({
      emails,
      groups: selectedGroups,
    });
  }, [
    emails,
    selectedGroups,
  ]);

  const Emails = useMemo(() => emails?.map((email, idx) => (
    <EmailChip
      email={email}
      handleDelete={() => deleteEmail(idx)}
      // eslint-disable-next-line
      key={`${email}-${idx}`}
    />
  )), [
    deleteEmail,
  ]);

  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <InviteSelector
        handleChange={updateGroups}
        options={groups}
        selectedOptions={selectedGroups}
      />
      <Box
        background={theme.palette.grey[300]}
        className={styles.emailsSection}
        mb={3}
        mt={2}
        pt={1}
      >
        <Box
          className={styles.emailsContainer}
          display="flex"
          flexWrap="wrap"
          px={1}
        >
          {Emails}
        </Box>
        <EmailInput
          handlePaste={addPastedEmails}
          hasError={hasError}
          submitChanges={addEmail}
        />
      </Box>
      <Box display="flex" justifyContent={isUpdating ? 'center' : 'start'}>
        <Visible
          fallback={<Loader />}
          when={!isUpdating}
        >
          <CustomButton
            fullWidth={isMobile}
            buttonType="primary"
            label={translate('SEND_INVITE')}
            onClick={onSendInvites}
          />
        </Visible>
      </Box>
    </Box>
  );
};

InviteForm.propTypes = {
  groups: arrayOf(string).isRequired,
  isUpdating: bool.isRequired,
  sendInvites: func.isRequired,
};

export {
  InviteForm,
};
