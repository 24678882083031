import React from 'react';
import {
  bool,
  string,
} from 'prop-types';
import {
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import {
  Visible,
} from 'components';

const useStyles = makeStyles({
  loader: {
    height: ({ size }) => size,
    width: ({ size }) => size,
  },
});

const Loader = (({
  size,
  isVisible,
}) => {
  const styles = useStyles({
    size,
  });

  return (
    <Visible when={isVisible}>
      <CircularProgress
        className={styles.loader}
        data-testid="loader"
        color="primary"
      />
    </Visible>
  );
});

Loader.propTypes = {
  isVisible: bool,
  size: string,
};

Loader.defaultProps = {
  isVisible: true,
  size: '54px',
};

export {
  Loader,
};
