import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  ListItem,
  ListItemAvatar,
  Typography,
} from '@material-ui/core';
import {
  NavLink,
} from 'react-router-dom';
import {
  Avatar,
} from 'components';

const useStyles = makeStyles(theme => ({
  navContainer: {
    padding: '0px',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
  },
  navItemWrap: {
    width: '100%',
  },
  navItem: {
    display: 'flex',
    textDecoration: 'none',
    padding: '8px 16px',
    [theme.breakpoints.up('xs')]: {
      justifyContent: 'center',
      '&.active > div': {
        backgroundColor: theme.palette.background.white,
        border: `1px solid ${theme.palette.grey.light}`,
      },
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'left',
      '&.active': {
        backgroundColor: theme.palette.grey.light,
      },
    },
  },
  avatar: {
    background: 'none',
  },
  link: {
    alignSelf: 'center',
    textDecoration: 'none',
    color: theme.palette.common.black,
    paddingLeft: theme.spacing(2),
  },
}));

const NavigationListItem = ({
  navRoute, icon, iconAlt, itemText, exact,
}) => {
  const classes = useStyles();
  const renderItemText = (text) => {
    if (text) {
      return (
        <Typography
          className={classes.link}
          variant="subtitle1"
          style={{
            fontWeight: 500,
          }}
        >
          {text}
        </Typography>
      );
    }

    return null;
  };

  const imageTestId = `navigation-list-item-image-${iconAlt}`;

  return (
    <ListItem
      data-testid="navigation-list-item"
      button
      className={classes.navContainer}
    >
      <ListItemAvatar
        className={classes.navItemWrap}
      >
        <NavLink
          className={classes.navItem}
          to={navRoute}
          exact={exact}
        >
          <Avatar
            className={classes.avatar}
            size="48px"
          >
            <img
              data-testid={imageTestId}
              alt={iconAlt}
              src={icon}
            />
          </Avatar>
          {renderItemText(itemText)}
        </NavLink>
      </ListItemAvatar>
    </ListItem>
  );
};

NavigationListItem.propTypes = {
  navRoute: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconAlt: PropTypes.string.isRequired,
  itemText: PropTypes.string,
  exact: PropTypes.bool,
};

NavigationListItem.defaultProps = {
  itemText: null,
  exact: false,
};

export {
  NavigationListItem,
};
