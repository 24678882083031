import userActionTypes from 'rdx/actionTypes';

const {
  INVITE_FORM_UPDATE_GROUPS,
  INVITE_FORM_UPDATE_EMAILS,
  INVITE_FORM_SET_HAS_ERROR,
  INVITE_FORM_RESET_ALL,
  SENDING_INVITES_SUCCESS,
} = userActionTypes;

const initialState = {
  groups: [],
  emails: [],
  hasError: false,
};

export function inviteForm(
  state = initialState,
  action,
) {
  switch (action.type) {
    case INVITE_FORM_UPDATE_GROUPS:
      return {
        ...state,
        groups: action.groups,
        hasError: false,
      };
    case INVITE_FORM_UPDATE_EMAILS:
      return {
        ...state,
        emails: action.emails,
        hasError: false,
      };
    case INVITE_FORM_SET_HAS_ERROR:
      return {
        ...state,
        hasError: action.hasError,
      };
    case SENDING_INVITES_SUCCESS:
      return initialState;
    case INVITE_FORM_RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
