/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
// Do not run amplify generation command again - this is set up to use env variables for cognito connections
import env from "@beam-australia/react-env";

// Live dev config
const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": env('COGNITO_USER_POOL_ID'),
    "aws_user_pools_web_client_id": env('COGNITO_WEB_CLIENT_ID'),
    "oauth": {}
};

export default awsmobile;