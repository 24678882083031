import {
  en,
} from './en';

const locales = {
  'en-US': en,
};

const DEFAULT_LOCALES = 'en-US';

export {
  locales,
  DEFAULT_LOCALES,
};
