import React, {
  memo,
} from 'react';
import {
  node,
  string,
} from 'prop-types';
import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Visible,
} from 'components';

const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: props => props.mobileFontSize,
    },
  },
  image: {
    width: props => props.iconSize,
  },
}));

const HeadingWithIcon = memo(({
  iconSrc,
  iconSize,
  mobileFontSize,
  title,
  variant,
}) => {
  const styles = useStyles({
    iconSize,
    mobileFontSize,
  });

  return (
    <Box
      alignItems="center"
      display="flex"
      my={1}
    >
      <Visible when={iconSrc}>
        <img
          alt={`${title} icon`}
          className={styles.image}
          data-testid="heading-icon"
          src={iconSrc}
        />
      </Visible>
      <Box ml={iconSrc ? 1 : 0}>
        <Typography
          className={styles.title}
          variant={variant}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
});

HeadingWithIcon.propTypes = {
  iconSrc: node.isRequired,
  iconSize: string,
  mobileFontSize: string,
  title: string.isRequired,
  variant: string,
};

HeadingWithIcon.defaultProps = {
  iconSize: '36px',
  mobileFontSize: '20px',
  variant: 'h5',
};

export {
  HeadingWithIcon,
};
