import React, {
  useMemo,
} from 'react';
import {
  arrayOf,
  func,
  shape,
  string,
} from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {
  translate,
} from 'lib/intl';
import {
  Visible,
} from 'components';
import {
  TeamMemberItem,
} from './team-member-item';

const StyledTeamMembers = styled(Box)`
  max-height: calc(100vh - 110px);
  overflow: scroll;
`;

const TeamMembers = ({
  availableGroups,
  invites,
  sendInvites,
  updateInvite,
  updatingInviteIds,
}) => {
  const isMobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const Members = useMemo(() => invites?.map(({
    invitePubId,
    ...props
  }) => (
    <TeamMemberItem
      availableGroups={availableGroups}
      key={invitePubId}
      invitePubId={invitePubId}
      isUpdating={updatingInviteIds?.includes(invitePubId)}
      sendInvites={sendInvites}
      updateInvite={updateInvite}
      {...props}
    />
  )), [
    availableGroups,
    invites,
    updatingInviteIds,
  ]);

  const NoInvitesMessage = useMemo(() => (
    <Box px={1} py={3}>
      <Typography variant="body1">{translate('INVITE_PAGE_NO_INVITES_LABEL')}</Typography>
    </Box>
  ), []);

  return (
    <StyledTeamMembers px={isMobileView ? 0 : 3}>
      <Visible
        fallback={NoInvitesMessage}
        when={Members?.length}
      >
        {Members}
      </Visible>
    </StyledTeamMembers>
  );
};

TeamMembers.propTypes = {
  availableGroups: arrayOf(string),
  invites: arrayOf(shape({
    invitePubId: string.isRequired,
    email: string.isRequired,
    groups: arrayOf(string),
    status: string.isRequired,
  })),
  sendInvites: func.isRequired,
  updateInvite: func.isRequired,
  updatingInviteIds: arrayOf(string),
};

TeamMembers.defaultProps = {
  availableGroups: [],
  invites: [],
  updatingInviteIds: [],
};

export {
  TeamMembers,
};
