import {
  assocPath,
  path as getByPath,
  keys,
  prop,
  sortBy,
} from 'rambdax';
import {
  AUTH_ROUTES,
} from 'lib/constants';
import {
  formatDate,
  formatCurrency,
} from 'lib/utils';

const mapName = (donor = {}) => {
  const {
    firstName,
    orgName,
    lastName,
  } = donor;

  const name = orgName || (firstName ? `${firstName} ${lastName}` : '');

  if (name) {
    return [{
      value: name,
    }];
  }

  return [];
};

const mapSingleValue = value => (value ? [{
  value,
}] : []);

// TODO: @anna - fix field once data is available
const mapSpouseName = ({ spouse } = {}) => {
  if (spouse) {
    const {
      displayName,
      userId,
    } = spouse;

    return [{
      value: displayName,
      url: userId || '',
    }];
  }

  return [];
};

// a generic method for mapping different types of connections
const mapConnectedPeople = (connections = []) => connections?.map(({
  displayName,
  orgId,
  orgName,
  userId,
}) => {
  const donorId = orgId || userId;

  return ({
    value: orgName || displayName,
    url: donorId ? `${AUTH_ROUTES.DONORS}/${orgId || userId}` : '',
  });
});

const mapReferralUrl = donorId => (donorId ? `${AUTH_ROUTES.DONORS}/${donorId}` : '');

const mapMultipleFields = (data, fields = [], urlPrefix = '') => fields?.reduce((acc, field) => {
  const value = data[field];

  if (value) {
    acc.push({
      value,
      url: `${urlPrefix}${value}`,
    });
  }

  return acc;
}, []);

const mapEmail = (
  emails = {},
  fields = ['primary', 'home'],
) => mapMultipleFields(emails, fields, 'mailto:');

const mapPhoneNumber = (
  phones = {},
  fields = ['primary', 'mobile'],
) => mapMultipleFields(phones, fields, 'tel:');

const mapAddress = ({
  city = '',
  country = '',
  line1,
  postalCode = '',
  state = '',
  unit = '',
} = {}) => {
  const address = [];

  // eslint-disable-next-line
  [line1, unit]?.forEach((value) => {
    if (value) {
      address.push({
        value,
      });
    }
  });

  if (city) {
    address.push({
      value: `${city}${postalCode || state ? `, ${state} ${postalCode}` : ''}`,
    });
  }

  if (country) {
    address.push({
      value: country,
    });
  }

  return address;
};

const mapWebsite = value => (value ? [{
  value,
  url: value,
}] : []);

const mapCapacity = capacity => (capacity
  ? `${formatCurrency(capacity?.fromN)} - ${formatCurrency(capacity?.toN)}`
  : '');

const mapLastDonationDate = lastDonationDate => (lastDonationDate ? formatDate(lastDonationDate) : '');

// a generic mapper for 'connections', 'affiliations' and 'employees'
const mapConnectionsData = (data, keyToExtract = '') => {
  let extractedKey = null;

  const mappedData = keys(data || {}).reduce((acc, key) => {
    const value = data[key];

    if (value && key === keyToExtract) {
      extractedKey = value;
    } else if (value) {
      acc.push({
        key,
        ...value,
      });
    }

    return acc;
  }, []);

  return {
    data: sortBy(prop('key'), mappedData),
    extractedKey,
  };
};

// maps donor data from a GET response to /orgs/{org_pub_id}/donors/{donor_pub_id}
const mapDonorInfo = (data) => {
  const {
    acquaintances = {},
    affiliations = {},
    connections = {},
    employees = {},
  } = data;

  const {
    data: updatedAcquaintances,
    extractedKey: spouse,
  } = mapConnectionsData(acquaintances, 'spouse');

  const {
    data: updatedConnections,
  } = mapConnectionsData(connections);

  const {
    data: updatedAffiliates,
  } = mapConnectionsData(affiliations);

  const {
    data: updatedEmployees,
  } = mapConnectionsData(employees);

  return {
    ...data,
    spouse,
    acquaintances: updatedAcquaintances,
    affiliations: updatedAffiliates,
    connections: updatedConnections,
    employees: updatedEmployees,
  };
};

// maps donor data before making a PUT request to /orgs/{org_pub_id}/donors/{donor_pub_id}
// or POST request to /orgs/{org_pub_id}/donors
const mapDonorEditedFields = ({
  donorForm,
  includeDonorType,
  isUserForm,
}) => {
  const {
    editedFields,
    org,
    user,
  } = donorForm;

  const data = isUserForm ? user : org;

  let res = includeDonorType ? {
    donorType: data.donorType,
  } : {};

  editedFields.forEach((fieldPath) => {
    // currently api doesn't work with empty strings, passing null instead of '' works
    const currentData = getByPath(fieldPath, data) || null;

    if (fieldPath === 'spouse') {
      res = assocPath('acquaintances.spouse', data?.spouse, res);
    } else if (['acquaintances', 'connections', 'affiliations', 'employees'].includes(fieldPath)) {
      const presavedData = getByPath(fieldPath, res);

      let lastKeyNumber = currentData?.reduce((lastIdx, { key }) => {
        const idx = key?.replace(/^\D+/g, '');
        if (key && lastIdx < idx) {
          return idx + 1;
        }

        return lastIdx + 1;
      }, 0);

      const mappedData = currentData?.reduce((acc, {
        key,
        isRemoved,
        ...val
      }) => {
        acc[key || `el${lastKeyNumber}`] = key && isRemoved
          ? null
          : { ...val };

        if (!key) {
          lastKeyNumber += 1;
        }

        return acc;
      }, {});

      res = assocPath(fieldPath, { ...presavedData, ...mappedData }, res);
    } else {
      res = assocPath(fieldPath, currentData, res);
    }
  });

  return res;
};

export {
  mapConnectedPeople,
  mapAddress,
  mapCapacity,
  mapDonorEditedFields,
  mapEmail,
  mapPhoneNumber,
  mapSpouseName,
  mapLastDonationDate,
  mapName,
  mapReferralUrl,
  mapSingleValue,
  mapWebsite,
  mapDonorInfo,
};
