import React from 'react';
import styled from 'styled-components';
import {
  string,
} from 'prop-types';
import {
  Typography,
} from '@material-ui/core';

const StyledTitle = styled(Typography)`
  font-weight: 600;
  padding-bottom: 20px;
`;

const DonorSectionTitle = ({
  children,
}) => (
  <StyledTitle variant="body1">
    {children}
  </StyledTitle>
);

DonorSectionTitle.propTypes = {
  children: string.isRequired,
};

export {
  DonorSectionTitle,
};
