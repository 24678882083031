import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  makeStyles,
  Typography,
  Grid,
} from '@material-ui/core';
import {
  translate,
} from 'lib/intl';
import {
  ListItemContainer,
} from 'components';
import {
  StreakDay,
} from './streak-day';

const useStyles = makeStyles(theme => ({
  titleSectionContainer: {
    paddingBottom: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  calendarSectionContainer: {
    display: 'flex',
    paddingTop: '5px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  typographyOverride: {
    lineHeight: '1.5',
    letterSpacing: '.25px',
    paddingTop: '1px',
    paddingRight: '4px',
  },
  streakIndicatorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    border: `1px solid ${theme.palette.grey.light}`,
    borderRadius: '50%',
    '&.missing': {
      border: `1px solid ${theme.palette.error.main}`,
    },
    '&.success': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

const DailyStreak = ({
  streakCount,
  dayResults,
}) => {
  const classes = useStyles();

  return (
    <ListItemContainer>
      <Grid
        container
        className={classes.titleSectionContainer}
      >
        <Typography
          variant="body1"
          className={classes.typographyOverride}
        >
          {translate('DAILY_SIGN_IN_STREAK')}
          :
        </Typography>
        <Typography
          variant="subtitle1"
          className={classes.typographyOverride}
        >
          <NumberFormat
            value={streakCount}
            displayType="text"
            thousandSeparator
          />
        </Typography>
        <Typography
          variant="subtitle1"
          className={classes.typographyOverride}
        >
          {translate('DAILY_SIGN_IN_STREAK_UNITS')}
        </Typography>
      </Grid>
      <Grid
        data-testid="daily-streak-container"
        container
        className={classes.calendarSectionContainer}
      >
        {dayResults.map((day, index) => {
          const { isStreakDay } = day;

          return (
            <StreakDay
              // eslint-disable-next-line
              key={index}
              isStreakDay={isStreakDay}
            />
          );
        })}
      </Grid>
    </ListItemContainer>
  );
};

DailyStreak.propTypes = {
  streakCount: PropTypes.number,
  dayResults: PropTypes.arrayOf(
    PropTypes.shape({
      isStreakDay: PropTypes.bool,
    }),
  ),
};

DailyStreak.defaultProps = {
  streakCount: 0,
  dayResults: [],
};

export {
  DailyStreak,
};
