export const INVITE_PAGE = {
  INVITE_PAGE_TEAM_LABEL: 'Invite your team',
  INVITE_PAGE_TEAM_DESCRIPTION: 'Add individual emails below or copy and paste a list',
  INVITE_PAGE_INPUT_PLACEHOLDER: 'Add teammates by email',
  INVITE_PAGE_PERMISSION_TYPE_LABEL: 'Permission type',
  INVITE_PAGE_TEAM_MEMBERS_LABEL: 'Team members',
  INVITE_PAGE_NO_INVITES_LABEL: 'You have no pending invites',
  INVITE_PAGE_SEND_INVITE_TAB: 'Send Invite',
  INVITE_PAGE_SEND_PENDING_INVITES: 'Pending Invites',
  INVITE_PAGE_INVITE_SENT_LABEL: 'Invite Sent!',
  INVITE_PAGE_INVITE_SENT_MESSAGE: 'Have your teammates check their email for the link to join.',
};
