import React from 'react';
import {
  translate,
} from 'lib/intl';
import {
  affiliationsIcon,
  concernsIcon,
  prohibitionsIcon,
  spouseIcon,
  familyIcon,
  connectionsIcon,
  phoneIcon,
  winningIcon,
  employeesIcon,
  industryIcon,
  emailIcon,
  buildingIcon,
  mailingBoxIcon,
  birthdayIcon,
  webIcon,
  jobIcon,
  jobPositionIcon,
} from 'assets/images';
import {
  isNotEmpty,
  isValidEmail,
} from 'lib/utils';
import {
  EditDonorIcon,
} from './edit-donor-icon';
import {
  DonorTextInput,
} from './donor-text-input';
import {
  EditDonorPhoneInput,
} from './edit-donor-phone-input';
import {
  AddressInputs,
} from './address-inputs';

const AFFILIATION_COMMON_PROPS = {
  Component: DonorTextInput,
  defaultValue: { displayName: '' },
  newValueMapper: (value, prevProps) => ({ ...prevProps, displayName: value }),
  valueMapper: data => data?.displayName || '',
  useMultipleInputs: true,
};

const PHONE_NUMBER_COMMON_PROPS = {
  Component: EditDonorPhoneInput,
  icon: <EditDonorIcon src={phoneIcon} />,
  path: 'phones',
  useMultipleInputs: true,
  useIndexes: false,
};

const EMAIL_COMMON_PROPS = {
  Component: DonorTextInput,
  icon: <EditDonorIcon src={emailIcon} />,
  label: translate('EMAIL_ADDRESS_LABEL'),
  path: 'emails',
  useMultipleInputs: true,
  validate: isValidEmail,
  useIndexes: false,
};

const WEB_COMMON_PROPS = {
  Component: DonorTextInput,
  icon: <EditDonorIcon src={webIcon} />,
  label: translate('WEBSITE_LABEL'),
};

const JOB_TITLE_COMMON_PROPS = {
  Component: DonorTextInput,
  icon: <EditDonorIcon src={jobPositionIcon} />,
  label: translate('TITLE_LABEL'),
};

const EDIT_DONOR_FIELDS_CONFIG = {
  USER_FIRST_NAME: {
    key: 'USER_FIRST_NAME',
    label: translate('FIRST_NAME_LABEL'),
    path: 'firstName',
    required: true,
    validate: isNotEmpty,
  },
  USER_LAST_NAME: {
    key: 'USER_LAST_NAME',
    label: translate('LAST_NAME_LABEL'),
    path: 'lastName',
    required: true,
    validate: isNotEmpty,
  },
  USER_EMAIL: {
    ...EMAIL_COMMON_PROPS,
    key: 'USER_EMAIL',
    defaultValue: {
      dataPath: 'emails.primary',
      type: 'primary',
    },
    types: [
      {
        label: translate('PRIMARY_LABEL'),
        value: 'primary',
      },
      {
        label: translate('HOME_LABEL'),
        value: 'home',
      },
    ],
  },
  USER_WORK_EMAIL: {
    ...EMAIL_COMMON_PROPS,
    key: 'USER_WORK_EMAIL',
    path: 'emails.work',
    label: translate('WORK_EMAIL_ADDRESS_LABEL'),
    useMultipleInputs: false,
  },
  USER_PHONE_NUMBER: {
    key: 'USER_PHONE_NUMBER',
    defaultValue: {
      dataPath: 'phones.primary',
      type: 'primary',
    },
    types: [
      {
        label: translate('PRIMARY_LABEL'),
        value: 'primary',
      },
      {
        label: translate('HOME_LABEL'),
        value: 'home',
      },
      {
        label: translate('MOBILE_LABEL'),
        value: 'mobile',
      },
    ],
    ...PHONE_NUMBER_COMMON_PROPS,
  },
  USER_MAILING_ADDRESS: {
    key: 'USER_MAILING_ADDRESS',
    Component: AddressInputs,
    icon: <EditDonorIcon src={mailingBoxIcon} />,
    path: 'addresses.mailing',
  },
  USER_EMPLOYER: {
    key: 'USER_EMPLOYER',
    icon: <EditDonorIcon src={jobIcon} />,
    label: translate('EMPLOYER_LABEL'),
    path: 'employer.employerName',
  },
  USER_JOB_POSITION: {
    key: 'USER_JOB_POSITION',
    path: 'employer.jobTitle',
    ...JOB_TITLE_COMMON_PROPS,
  },
  USER_WORK_PHONE_NUMBER: {
    key: 'USER_WORK_PHONE_NUMBER',
    ...PHONE_NUMBER_COMMON_PROPS,
    icon: <EditDonorIcon src={phoneIcon} />,
    path: 'phones.work',
    useMultipleInputs: false,
  },
  USER_WORK_ADDRESS: {
    key: 'USER_WORK_ADDRESS',
    Component: AddressInputs,
    icon: <EditDonorIcon src={buildingIcon} />,
    path: 'addresses.work',
  },
  USER_EMPLOYER_WEBSITE: {
    ...WEB_COMMON_PROPS,
    key: 'USER_EMPLOYER_WEBSITE',
    path: 'employer.employerWebsite',
  },
  SPOUSE: {
    ...AFFILIATION_COMMON_PROPS,
    key: 'SPOUSE',
    icon: <EditDonorIcon src={spouseIcon} />,
    label: translate('SPOUSE_LABEL'),
    path: 'spouse',
    useMultipleInputs: false,
  },
  ORG_NAME: {
    key: 'ORG_NAME',
    label: translate('COMPANY_NAME_LABEL'),
    path: 'orgName',
    required: true,
    validate: isNotEmpty,
  },
  ORG_INDUSTRY: {
    key: 'ORG_INDUSTRY',
    icon: <EditDonorIcon src={industryIcon} />,
    label: translate('COMPANY_UNDUSTRY_LABEL'),
    path: 'industry',
  },
  ORG_PHONE_NUMBER: {
    ...PHONE_NUMBER_COMMON_PROPS,
    key: 'ORG_PHONE_NUMBER',
    defaultValue: {
      dataPath: 'phones.frontDesk',
      type: 'frontDesk',
    },
    types: [
      {
        label: translate('FRONT_DESK_LABEL'),
        value: 'frontDesk',
      },
      {
        label: translate('BILLING_LABEL'),
        value: 'billing',
      },
    ],
  },
  ORG_ADDRESS: {
    key: 'ORG_ADDRESS',
    Component: AddressInputs,
    icon: <EditDonorIcon src={buildingIcon} />,
    path: 'addresses.business',
  },
  ORG_EMAIL: {
    ...EMAIL_COMMON_PROPS,
    key: 'ORG_EMAIL',
    defaultValue: {
      dataPath: 'email.billing',
      type: 'billing',
    },
    types: [
      {
        label: translate('BILLING_LABEL'),
        value: 'billing',
      },
      {
        label: translate('SALES_LABEL'),
        value: 'sales',
      },
    ],
  },
  ORG_PRIMARY_CONTACT_NAME: {
    key: 'ORG_PRIMARY_CONTACT_NAME',
    icon: <EditDonorIcon src={birthdayIcon} />,
    label: translate('PRIMARY_CONTACT_NAME'),
    path: 'developers.dev1.displayName',
  },
  ORG_PRIMARY_CONTACT_TITLE: {
    ...JOB_TITLE_COMMON_PROPS,
    key: 'ORG_PRIMARY_CONTACT_TITLE',
    path: 'developers.dev1.role',
  },
  ORG_PRIMARY_CONTACT_EMAIL: {
    ...EMAIL_COMMON_PROPS,
    key: 'ORG_PRIMARY_CONTACT_EMAIL',
    path: 'developers.dev1.primaryEmail',
    useMultipleInputs: false,
  },
  ORG_PRIMARY_CONTACT_PHONE_NUMBER: {
    ...PHONE_NUMBER_COMMON_PROPS,
    key: 'ORG_PRIMARY_CONTACT_PHONE_NUMBER',
    path: 'developers.dev1.primaryPhone',
    useMultipleInputs: false,
  },
  ORG_SECONDARY_CONTACT_NAME: {
    key: 'ORG_SECONDARY_CONTACT_NAME',
    icon: <EditDonorIcon src={birthdayIcon} />,
    label: translate('SECONDARY_CONTACT_NAME'),
    path: 'developers.dev2.displayName',
  },
  ORG_SECONDARY_CONTACT_TITLE: {
    key: 'ORG_SECONDARY_CONTACT_TITLE',
    path: 'developers.dev2.role',
    ...JOB_TITLE_COMMON_PROPS,
  },
  ORG_SECONDARY_CONTACT_EMAIL: {
    ...EMAIL_COMMON_PROPS,
    key: 'ORG_SECONDARY_CONTACT_EMAIL',
    path: 'developers.dev2.primaryEmail',
    useMultipleInputs: false,
  },
  ORG_SECONDARY_CONTACT_PHONE_NUMBER: {
    ...PHONE_NUMBER_COMMON_PROPS,
    key: 'ORG_SECONDARY_CONTACT_PHONE_NUMBER',
    path: 'developers.dev2.primaryPhone',
    useMultipleInputs: false,
  },
  WEBSITE: {
    key: 'WEBSITE',
    path: 'website',
    ...WEB_COMMON_PROPS,
  },
  BIRTHDAY: {
    key: 'BIRTHDAY',
    icon: <EditDonorIcon src={birthdayIcon} />,
    label: translate('BIRTHDAY_LABEL'),
    path: 'dob',
  },
  NOTE_1: {
    key: 'NOTE_1',
    icon: <EditDonorIcon src={winningIcon} />,
    label: translate('DONOR_NOTE_PLACEHOLDER_1'),
    path: 'notes.note1',
  },
  NOTE_2: {
    key: 'NOTE_2',
    icon: <EditDonorIcon src={concernsIcon} />,
    label: translate('DONOR_NOTE_PLACEHOLDER_2'),
    path: 'notes.note2',
  },
  NOTE_3: {
    key: 'NOTE_3',
    icon: <EditDonorIcon src={prohibitionsIcon} />,
    label: translate('DONOR_NOTE_PLACEHOLDER_3'),
    path: 'notes.note3',
  },
  HOUSEHOLD: {
    key: 'HOUSEHOLD',
    ...AFFILIATION_COMMON_PROPS,
    icon: <EditDonorIcon src={familyIcon} />,
    label: translate('FAMILY_MEMBER_LABEL'),
    path: 'acquaintances',
  },
  CONNECTIONS: {
    key: 'CONNECTIONS',
    ...AFFILIATION_COMMON_PROPS,
    icon: <EditDonorIcon src={connectionsIcon} />,
    label: translate('CONNECTIONS_LABEL'),
    path: 'connections',
  },
  AFFILIATIONS: {
    key: 'AFFILIATIONS',
    ...AFFILIATION_COMMON_PROPS,
    icon: <EditDonorIcon src={affiliationsIcon} />,
    label: translate('AFFILIATIONS_LABEL'),
    path: 'affiliations',
  },
  EMPLOYEES: {
    key: 'EMPLOYEES',
    ...AFFILIATION_COMMON_PROPS,
    icon: <EditDonorIcon src={employeesIcon} />,
    label: translate('EMPLOYEES_LABEL'),
    path: 'employees',
  },
};

const USER_INFO_CONFIG = [
  EDIT_DONOR_FIELDS_CONFIG.USER_FIRST_NAME,
  EDIT_DONOR_FIELDS_CONFIG.USER_LAST_NAME,
  EDIT_DONOR_FIELDS_CONFIG.USER_EMAIL,
  EDIT_DONOR_FIELDS_CONFIG.USER_PHONE_NUMBER,
  EDIT_DONOR_FIELDS_CONFIG.USER_MAILING_ADDRESS,
  EDIT_DONOR_FIELDS_CONFIG.BIRTHDAY,
];

const USER_WORK_INFO_CONFIG = [
  EDIT_DONOR_FIELDS_CONFIG.USER_EMPLOYER,
  EDIT_DONOR_FIELDS_CONFIG.USER_JOB_POSITION,
  EDIT_DONOR_FIELDS_CONFIG.USER_WORK_EMAIL,
  EDIT_DONOR_FIELDS_CONFIG.USER_WORK_PHONE_NUMBER,
  EDIT_DONOR_FIELDS_CONFIG.USER_WORK_ADDRESS,
  EDIT_DONOR_FIELDS_CONFIG.USER_EMPLOYER_WEBSITE,
];

const USER_CONNECTIONS_CONFIG = [
  EDIT_DONOR_FIELDS_CONFIG.SPOUSE,
  EDIT_DONOR_FIELDS_CONFIG.HOUSEHOLD,
  EDIT_DONOR_FIELDS_CONFIG.CONNECTIONS,
  EDIT_DONOR_FIELDS_CONFIG.AFFILIATIONS,
];

const ORG_COMPANY_INFO_CONFIG = [
  EDIT_DONOR_FIELDS_CONFIG.ORG_NAME,
  EDIT_DONOR_FIELDS_CONFIG.ORG_INDUSTRY,
  EDIT_DONOR_FIELDS_CONFIG.ORG_EMAIL,
  EDIT_DONOR_FIELDS_CONFIG.ORG_PHONE_NUMBER,
  EDIT_DONOR_FIELDS_CONFIG.ORG_ADDRESS,
  EDIT_DONOR_FIELDS_CONFIG.WEBSITE,
];

// TODO: @anna - decide what to do with EMPLOYEES
const ORG_CONNECTIONS_CONFIG = [
  EDIT_DONOR_FIELDS_CONFIG.EMPLOYEES,
  EDIT_DONOR_FIELDS_CONFIG.AFFILIATIONS,
  EDIT_DONOR_FIELDS_CONFIG.CONNECTIONS,
];

const ORG_PRIMARY_CONTACT_INFO_CONFIG = [
  EDIT_DONOR_FIELDS_CONFIG.ORG_PRIMARY_CONTACT_NAME,
  EDIT_DONOR_FIELDS_CONFIG.ORG_PRIMARY_CONTACT_TITLE,
  EDIT_DONOR_FIELDS_CONFIG.ORG_PRIMARY_CONTACT_EMAIL,
  EDIT_DONOR_FIELDS_CONFIG.ORG_PRIMARY_CONTACT_PHONE_NUMBER,
];

const ORG_SECONDARY_CONTACT_INFO_CONFIG = [
  EDIT_DONOR_FIELDS_CONFIG.ORG_SECONDARY_CONTACT_NAME,
  EDIT_DONOR_FIELDS_CONFIG.ORG_SECONDARY_CONTACT_TITLE,
  EDIT_DONOR_FIELDS_CONFIG.ORG_SECONDARY_CONTACT_EMAIL,
  EDIT_DONOR_FIELDS_CONFIG.ORG_SECONDARY_CONTACT_PHONE_NUMBER,
];

const NOTES_CONFIG = [
  EDIT_DONOR_FIELDS_CONFIG.NOTE_1,
  EDIT_DONOR_FIELDS_CONFIG.NOTE_2,
  EDIT_DONOR_FIELDS_CONFIG.NOTE_3,
];

const EDIT_DONOR_FORM_TYPES = {
  user: {
    label: translate('EDIT_DONOR_FORM_PERSON_LABEL'),
    value: 'user',
  },
  org: {
    label: translate('EDIT_DONOR_FORM_ORG_LABEL'),
    value: 'org',
  },
};

export {
  EDIT_DONOR_FIELDS_CONFIG,
  EDIT_DONOR_FORM_TYPES,
  NOTES_CONFIG,
  ORG_COMPANY_INFO_CONFIG,
  ORG_CONNECTIONS_CONFIG,
  ORG_PRIMARY_CONTACT_INFO_CONFIG,
  ORG_SECONDARY_CONTACT_INFO_CONFIG,
  USER_CONNECTIONS_CONFIG,
  USER_INFO_CONFIG,
  USER_WORK_INFO_CONFIG,
};
