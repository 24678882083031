import React, {
  useMemo,
} from 'react';
import {
  Box,
  Hidden,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  translate,
} from 'lib/intl';
import {
  HeadingWithIcon,
  InviteForm,
  PageTabs,
  StatusDialog,
  TeamMembers,
  Visible,
} from 'components';
import {
  inviteMessageIcon,
} from 'assets/images';
import {
  useInvitePage,
} from './hooks';
import {
  INVITE_PAGE_TABS,
} from './constants';

const useStyles = makeStyles(() => ({
  container: {
    minHeight: '100%',
  },
}));

// TODO: @anna - add Snackbar for errors?
const InvitePage = () => {
  const {
    closeModal,
    groups,
    invites,
    isFormDisabled,
    isSuccessModalOpen,
    sendInvites,
    setTabIdx,
    tabIdx,
    updateInvite,
    updatingInviteIds,
  } = useInvitePage();

  const styles = useStyles();

  const Description = useMemo(() => (
    <Box my={3}>
      <Typography variant="body1">{translate('INVITE_PAGE_TEAM_DESCRIPTION')}</Typography>
    </Box>
  ), []);

  const InviteFormContent = useMemo(() => (
    <InviteForm
      groups={groups}
      isUpdating={isFormDisabled}
      sendInvites={sendInvites}
    />
  ), [
    groups,
    isFormDisabled,
  ]);

  const TeamMembersContent = useMemo(() => (
    <TeamMembers
      availableGroups={groups}
      invites={invites}
      sendInvites={sendInvites}
      updateInvite={updateInvite}
      updatingInviteIds={updatingInviteIds}
    />
  ), [
    groups,
    invites,
  ]);

  const MobileTabContent = useMemo(() => (
    <Visible
      fallback={TeamMembersContent}
      when={tabIdx === 0}
    >
      {Description}
      {InviteFormContent}
    </Visible>
  ), [
    tabIdx,
    TeamMembersContent,
  ]);

  const Title = useMemo(() => (
    <HeadingWithIcon
      iconSrc={inviteMessageIcon}
      title={translate('INVITE_PAGE_TEAM_LABEL')}
    />
  ), []);

  return (
    <Grid className={styles.container} container>
      <StatusDialog
        open={isSuccessModalOpen}
        handleClose={closeModal}
        message={translate('INVITE_PAGE_INVITE_SENT_MESSAGE')}
        title={translate('INVITE_PAGE_INVITE_SENT_LABEL')}
      />
      {/* Mobile View */}
      <Hidden mdUp>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="column" px={2}>
            {Title}
            <PageTabs
              onChange={setTabIdx}
              tabs={INVITE_PAGE_TABS}
              variant="fullWidth"
            />
            {MobileTabContent}
          </Box>
        </Grid>
      </Hidden>
      {/* Desktop View */}
      <Hidden smDown>
        <Grid item md={6}>
          <Box
            display="flex"
            flexDirection="column"
            px={2}
          >
            {Title}
            {Description}
            {InviteFormContent}
          </Box>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid item md={6}>
          <Box
            borderColor="grey.light"
            borderLeft={1}
            display="flex"
            className={styles.container}
            flexDirection="column"
          >
            <Box
              borderBottom={1}
              color="grey.light"
              display="flex"
              px={4}
              py={2}
            >
              <Typography
                color="textPrimary"
                variant="subtitle1"
              >
                {translate('INVITE_PAGE_TEAM_MEMBERS_LABEL')}
              </Typography>
            </Box>
            {TeamMembersContent}
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export {
  InvitePage,
};
