import {
  useRoutesWrapper,
} from './hooks';

const RoutesWrapper = ({ children }) => {
  useRoutesWrapper();

  return children;
};

export {
  RoutesWrapper,
};
