import React, {
  useMemo,
} from 'react';
import {
  func,
} from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  Typography,
} from '@material-ui/core';
import {
  keys,
} from 'rambdax';
import {
  translate,
} from 'lib/intl';
import {
  iceBoxIcon,
  TriangleIcon,
} from 'assets/images';
import {
  Avatar,
  BackButton,
  Gap,
  LastInteraction,
  LinkLikeButton,
  ViewFields,
  Visible,
} from 'components';
import {
  DonorType,
  getInitials,
  isMobileView,
} from 'lib/utils';
import {
  mapConnectedPeople,
  mapEmail,
  mapPhoneNumber,
  mapSingleValue,
  mapSpouseName,
} from 'lib/mappers';
import {
  EMPTY_FUNC,
} from 'lib/constants';
import {
  NotesIcon,
  EmailIcon,
  JobIcon,
  SpouseIcon,
  PhoneIcon,
  WinningIcon,
  ConcernsIcon,
  ProhibitionsIcon,
} from './donor-icons';

const StyledEditButton = styled(Box)`
  cursor: pointer;
  
  p {
    font-size: 14px;
    font-weight: 500;
  }
`;

const AVATAR_MOB_SIZE = '64px';
const AVATAR_DESKTOP_SIZE = '100px';

const NOTES_ICONS = {
  note1: WinningIcon,
  note2: ConcernsIcon,
  note3: ProhibitionsIcon,
};

// TODO: @anna - add logic of showing icons once available
const Icons = () => (
  <Box display="flex" alignItems="center">
    <Avatar
      alt="ice-box-icon"
      data-testid="ice-box-icon"
      size="24px"
      src={iceBoxIcon}
    />
    <Gap />
    <TriangleIcon />
  </Box>
);

// TODO: @anna - update logic once real data is used
const DonorInfoSection = ({
  donor,
  openEditModal,
}) => {
  const {
    affiliations,
    avatar,
    donorPubId,
    employer,
    phones,
    emails,
    firstName = '',
    lastName = '',
    notes = {},
    orgName = '',
    updated,
  } = donor;

  const name = useMemo(() => orgName || `${firstName} ${lastName}`, [
    firstName,
    lastName,
    orgName,
  ]);

  const isMobile = isMobileView();

  const initials = useMemo(() => getInitials(firstName ? `${firstName} ${lastName}` : orgName), [
    firstName,
    lastName,
    orgName,
  ]);


  const Notes = useMemo(() => keys(notes).sort()?.reduce((acc, key) => {
    const value = notes[key];

    if (value) {
      acc.push(
        <ViewFields
          key={key}
          icon={NOTES_ICONS[key]}
          data={[{ value: notes[key] }]}
          gapSize={1}
        />,
      );
    }

    return acc;
  }, []), [notes]);

  return (
    <Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          py={2}
        >
          <BackButton
            label={translate('GO_BACK')}
          />
          <Visible when={donorPubId}>
            <StyledEditButton data-testid="edit-donor-button" onClick={openEditModal}>
              <Typography color="primary">
                {translate('DONOR_EDIT_PROFILE')}
              </Typography>
            </StyledEditButton>
          </Visible>
        </Box>
        <Box
          alignItems="flex-start"
          display="flex"
          justifyContent="space-between"
          pb={1}
        >
          <Avatar
            size={isMobile ? AVATAR_MOB_SIZE : AVATAR_DESKTOP_SIZE}
            src={avatar}
          >
            {initials}
          </Avatar>
          {/* TODO: @anna - update logic for icons */}
          <Icons />
        </Box>
      </Box>
      <Box pb={2} pt={1}>
        <Typography variant="h5">{name}</Typography>
      </Box>
      <LastInteraction date={updated} />
      <Box py={2}>
        <Visible when={!isMobile}>
          <ViewFields
            icon={SpouseIcon}
            data={mapSpouseName(donor)}
          />
          <Gap vertical />
          <ViewFields
            icon={PhoneIcon}
            data={mapPhoneNumber(phones)}
          />
          <ViewFields
            icon={EmailIcon}
            data={mapEmail(emails)}
          />
          <ViewFields
            icon={JobIcon}
            data={mapSingleValue(employer?.employerName)}
          />
          <Gap vertical />
        </Visible>
        {Notes}
        <Visible when={!isMobile}>
          <ViewFields
            icon={NotesIcon}
            data={mapConnectedPeople(affiliations)}
          />
        </Visible>
        <Gap size={2} vertical />
        <Box display="flex" flexDirection="row">
          <LinkLikeButton onClick={EMPTY_FUNC} fontSize="14px">
            {translate('NEW_INTERACTION_REPORT_LABEL')}
          </LinkLikeButton>
        </Box>
      </Box>
    </Box>
  );
};

DonorInfoSection.propTypes = {
  donor: DonorType,
  openEditModal: func.isRequired,
};

DonorInfoSection.defaultProps = {
  donor: {},
};

export {
  DonorInfoSection,
};
