import React, {
  useMemo,
} from 'react';
import {
  arrayOf,
  bool,
  func,
  string,
} from 'prop-types';
import styled from 'styled-components';
import {
  Box,
} from '@material-ui/core';
import {
  translate,
} from 'lib/intl';
import {
  Avatar,
} from 'components';
import {
  DonorType,
  getInitials,
  isMobileView,
} from 'lib/utils';
import {
  EditDonorSectionWrapper,
} from './edit-donor-section-wrapper';
import {
  SectionsGenerator,
} from './sections-generator';
import {
  SocialInputs,
} from './social-inputs';
import {
  ShowMoreContent,
} from './show-more-content';
import {
  ORG_COMPANY_INFO_CONFIG,
  USER_INFO_CONFIG,
} from './config';

const StyledWrapper = styled(Box)`
  position: relative;
`;

const StyledAvatar = styled(Avatar)`
  left: ${({ mobile }) => (mobile ? '20px' : '30px')};
  position: absolute;
  top: ${({ mobile }) => (mobile ? '70px' : '60px')};
`;

const EditDonorMainInfo = ({
  data,
  isUserType,
  errors,
  setErrors,
  showErrors,
  updateData,
}) => {
  const {
    firstName,
    lastName,
    orgName,
    avatar,
  } = data;

  const initials = useMemo(() => getInitials(isUserType ? `${firstName} ${lastName}` : orgName), [
    firstName,
    isUserType,
    lastName,
    orgName,
  ]);

  const isMobile = isMobileView();

  const title = useMemo(() => translate(isUserType
    ? 'DONOR_PERSONAL_INFORMATION'
    : 'DONOR_COMPANY_INFORMATION'), [isUserType]);

  const config = useMemo(() => (isUserType
    ? USER_INFO_CONFIG
    : ORG_COMPANY_INFO_CONFIG), [isUserType]);

  return (
    <StyledWrapper>
      <EditDonorSectionWrapper
        defaultExpanded
        id="edit-donor-main-info-section"
        title={title}
      >
        <StyledAvatar
          alt="donor-form-avatar"
          data-testid="donor-form-avatar"
          size={isMobile ? '40px' : '64px'}
          src={avatar}
          mobile={Number(isMobile)}
        >
          {initials}
        </StyledAvatar>
        <SectionsGenerator
          config={config}
          data={data}
          errors={errors}
          setErrors={setErrors}
          showErrors={showErrors}
          updateData={updateData}
        />
        <ShowMoreContent>
          <SocialInputs
            data={data}
            path=""
            updateData={updateData}
          />
        </ShowMoreContent>
      </EditDonorSectionWrapper>
    </StyledWrapper>
  );
};

EditDonorMainInfo.propTypes = {
  data: DonorType.isRequired,
  errors: arrayOf(string).isRequired,
  isUserType: bool,
  setErrors: func.isRequired,
  showErrors: bool.isRequired,
  updateData: func.isRequired,
};

EditDonorMainInfo.defaultProps = {
  isUserType: true,
};

export {
  EditDonorMainInfo,
};
