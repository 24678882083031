import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, Box, TextField, FormHelperText,
} from '@material-ui/core';
import { AuthActionButton } from 'components/auth/auth-action-button';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { userActions } from 'rdx/actions';
import { connect } from 'react-redux';
import {
  PUBLIC_ROUTES,
} from 'lib/constants';
import {
  translate,
} from 'lib/intl';
import {
  Visible,
} from 'components';
import {
  emailRegex,
} from './constants';

const {
  RESET_PASSWORD_REQUEST_SUCCESS,
} = PUBLIC_ROUTES;

const ResetPasswordForm = ({
  isFetching,
  isResetRequestSuccessful,
  resetPasswordAction,
  resetPasswordResetState,
  error,
}) => {
  const history = useHistory();
  const {
    register, handleSubmit, errors,
  } = useForm();

  if (isResetRequestSuccessful) {
    resetPasswordResetState();
    history.push(RESET_PASSWORD_REQUEST_SUCCESS);
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box pb={4} display="flex">
        <Typography variant="h5">
          {translate('RESET_PASSWORD_EMAIL')}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <form onSubmit={handleSubmit((data) => {
          const { orgEmail } = data;

          return resetPasswordAction(orgEmail);
        })}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={!!errors.orgEmail}
                helperText={errors.orgEmail ? errors.orgEmail.message : ''}
                name="orgEmail"
                label="Org Email Address"
                variant="outlined"
                inputRef={register({
                  required: 'Org Email Required',
                  pattern: {
                    // eslint-disable-next-line
                    value: emailRegex,
                    message: 'Invalid email',
                  },
                })}
              />
            </Grid>
          </Grid>
          <Visible when={error}>
            <Grid container item xs={12}>
              <FormHelperText error={!!error} style={{ textAlign: 'center', marginTop: '20px', width: '100%' }}>{error?.message}</FormHelperText>
            </Grid>
          </Visible>
          <AuthActionButton isFetching={isFetching} text={translate('RESET_PASSWORD')} />
        </form>
      </Box>
    </Box>
  );
};

ResetPasswordForm.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  resetPasswordAction: PropTypes.func.isRequired,
  isResetRequestSuccessful: PropTypes.bool.isRequired,
  resetPasswordResetState: PropTypes.func.isRequired,
  error: PropTypes.shape({ code: {}, message: {} }),
};

ResetPasswordForm.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => {
  const { isFetching, isResetRequestSuccessful, error } = state.resetPassword;

  return { isFetching, isResetRequestSuccessful, error };
};

const actionCreators = {
  resetPasswordAction: userActions.resetPassword,
  resetPasswordResetState: userActions.resetPasswordResetState,
};

const connectedResetPasswordForm = connect(mapStateToProps, actionCreators)(ResetPasswordForm);

export { connectedResetPasswordForm as ResetPasswordForm };
