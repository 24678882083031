import React from 'react';
import {
  func,
} from 'prop-types';
import {
  translate,
} from 'lib/intl';
import {
  DonorType,
} from 'lib/utils';
import {
  EditDonorSectionWrapper,
} from './edit-donor-section-wrapper';
import {
  NOTES_CONFIG,
} from './config';
import {
  SectionsGenerator,
} from './sections-generator';

const EditDonorNotes = ({
  data,
  updateData,
}) => (
  <EditDonorSectionWrapper
    id="edit-donor-key-notes-section"
    title={translate('THREE_KEY_NOTES_LABEL')}
  >
    <SectionsGenerator
      config={NOTES_CONFIG}
      data={data}
      updateData={updateData}
    />
  </EditDonorSectionWrapper>
);

EditDonorNotes.propTypes = {
  data: DonorType.isRequired,
  updateData: func.isRequired,
};

export {
  EditDonorNotes,
};
