import {
  createAction,
} from 'lib/utils';
import {
  mapDonorInfo,
} from 'lib/mappers';
import {
  donorInfoService,
} from 'services/donor-info';
import userActionTypes from 'rdx/actionTypes';

const {
  DONOR_INFO_REQUEST,
  DONOR_INFO_SUCCESS,
  DONOR_INFO_FAILURE,
} = userActionTypes;

const fetchDonorInfo = (orgPubId, donorPubId) => async (dispatch) => {
  try {
    dispatch(createAction({ type: DONOR_INFO_REQUEST }));

    const response = await donorInfoService.fetchDonorInfo(orgPubId, donorPubId);
    const responseData = response?.data;

    const data = {
      ...responseData,
      donor: mapDonorInfo(responseData?.donor),
    };

    dispatch(createAction({
      type: DONOR_INFO_SUCCESS,
      data,
    }));
  } catch (err) {
    dispatch(createAction({
      type: DONOR_INFO_FAILURE,
      err,
    }));
  }
};

export const donorInfoActions = {
  fetchDonorInfo,
};
