import React from 'react';
import {
  Box,
} from '@material-ui/core';
import {
  translate,
} from 'lib/intl';
import {
  mapConnectedPeople,
  mapSpouseName,
} from 'lib/mappers';
import {
  ViewFields,
} from 'components';
import {
  DonorType,
  isMobileView,
} from 'lib/utils';
import {
  AffiliationsIcon,
  ConnectionsIcon,
  FamilyIcon,
  SpouseIcon,
} from './donor-icons';
import {
  DonorSectionWrapper,
} from './donor-section-wrapper';
import {
  DonorSectionTitle,
} from './donor-section-title';

const Affiliations = ({
  donor,
}) => {
  const isMobile = isMobileView();

  const {
    affiliations,
    acquaintances,
    connections,
  } = donor;

  return (
    <DonorSectionWrapper>
      <DonorSectionTitle>
        {translate('DONOR_FAMILY_AND_AFFILIATIONS')}
      </DonorSectionTitle>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={isMobile ? 'column' : 'row'}
        flexWrap="wrap"
      >
        <Box pr={2}>
          <ViewFields
            icon={SpouseIcon}
            data={mapSpouseName(donor)}
          />
          <ViewFields
            icon={FamilyIcon}
            data={mapConnectedPeople(acquaintances)}
          />
        </Box>
        <Box pr={2}>
          <ViewFields
            icon={AffiliationsIcon}
            data={mapConnectedPeople(affiliations)}
          />
        </Box>
        <Box>
          <ViewFields
            icon={ConnectionsIcon}
            data={mapConnectedPeople(connections)}
          />
        </Box>
      </Box>
    </DonorSectionWrapper>
  );
};

Affiliations.propTypes = {
  donor: DonorType,
};

Affiliations.defaultProps = {
  donor: {},
};

export {
  Affiliations,
};
