import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const AuthRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      !isAuthenticated
        ? <Component {...props} />
        : <Redirect to="/" />
    )}
  />
);

AuthRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.authentication;

  return { isAuthenticated };
};

const connectedAuthRoute = connect(mapStateToProps)(AuthRoute);

export { connectedAuthRoute as AuthRoute };
