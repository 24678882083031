import {
  memo,
} from 'react';

/**
 * Conditionally show children, with an optional fallback component.
 * @param {React.Node} children - child component(s).
 * @param {JSX.Element} fallback - Singleton fallback component.
 * @param {Any} when - Condition to be met in order for children to show..
 * @returns {React.Node|Null} children, fallback, or null
 */
const Visible = memo(({ children, fallback, when }) => {
  if (when) {
    return children;
    // eslint-disable-next-line no-else-return
  } else if (fallback) {
    return fallback;
  }

  return null;
});

export {
  Visible,
};
