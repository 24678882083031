// Regex
// eslint-disable-next-line no-control-regex
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const phoneNumberRegex = /[0-9]{11,15}/;

// Auth validation limits
const firstNameMaxLength = 40;
const lastNameMaxLength = 40;
const orgNameMinLength = 2;
const orgNameMaxLength = 99;

// Cognito error code
const userNotConfirmedError = 'UserNotConfirmedException';

export {
  emailRegex,
  phoneNumberRegex,
  firstNameMaxLength,
  lastNameMaxLength,
  orgNameMinLength,
  orgNameMaxLength,
  userNotConfirmedError,
};
