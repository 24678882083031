import userActionTypes from 'rdx/actionTypes';

const initialState = {
  isFetching: false,
  error: null,
  activeItemId: null,
  currentPage: 1,
  items: [],
};

// Prevents duplicates in stateful feed array
// This gets around the double rendering caused by react strict mode in development
// TODO this is temporary
const addUniqueFeedItems = (currentItems, newItems) => {
  const currentItemsCopy = [...currentItems];

  if (newItems) {
    newItems.forEach((newItem) => {
      if (!currentItemsCopy.find(item => item.feed_item_pub_id === newItem.feed_item_pub_id)) {
        currentItemsCopy.push(newItem);
      }
    });
  }

  return currentItemsCopy;
};

export function fetchFeed(
  state = initialState,
  action,
) {
  switch (action.type) {
    case userActionTypes.FEED_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case userActionTypes.FEED_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: addUniqueFeedItems(state.items, action.feed.items),
        currentPage: action.feed.page,
      };
    case userActionTypes.FEED_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
