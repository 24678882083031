import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  translate,
} from 'lib/intl';
import {
  isMobileView,
} from 'lib/utils';
import { EMPTY_FUNC } from 'lib/constants';
import {
  Feed,
} from 'components';
import {
  GoalsProgress,
} from './goals-progress';
import {
  DailyStreak,
} from './daily-streak';
import {
  RecentlyViewedDonors,
} from './recently-viewed-donors';

// TODO remove test styles once real content is introduced
const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    display: 'flex',
    overflowY: 'hidden',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      flexDirection: 'row',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  navVerticalContainer: {
    borderRight: `1px solid ${theme.palette.grey.light}`,
  },
  hideScrollbar: {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  navHorizontalHeader: {
    borderBottom: `1px solid ${theme.palette.grey.light}`,
  },
  goalsVerticalContentContainer: {
    height: '100vh',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      overflowY: 'inherit',
    },
  },
  verticalGoalsTestContent: {
    height: '1800px',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  horizontalScrollContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexWrap: 'nowrap',
      height: '100px',
      overflowY: 'hidden',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      msOverflowStyle: 'none',
      webkitOverflowScrolling: 'touch',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  goalFillerTest: {
    flex: '0 0 auto',
    height: '77px',
    background: theme.palette.grey.light,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '302px',
      marginRight: theme.spacing(2),
    },
  },
}));

// TODO Remove after API integration
/* eslint-disable no-unused-vars */
const HomeLayout = ({
  feed,
  getFeedPage,
  setActiveFeedItem,
  activeFeedItemId,
  recentlyViewedDonors,
  goals,
  visits,
}) => {
  const containerRef = useRef(null);
  const isMobile = isMobileView();
  const styles = useStyles();

  const { data: recentlyViewedDonorsData, isFetching } = recentlyViewedDonors;
  const topContainerId = 'home-layout-container';

  return (
    <Grid
      id={topContainerId}
      ref={containerRef}
      className={styles.container}
      container
    >
      <Grid
        className={styles.navVerticalContainer}
        item
        sm={4}
        xs={12}
      >
        <Box
          p={2}
          className={`${styles.goalsVerticalContentContainer} ${styles.hideScrollbar}`}
        >
          <Box
            // TODO temporary test style for overflow container
            className={styles.verticalGoalsTestContent}
          >
            <Box>
              <Typography
                color="textPrimary"
                variant="subtitle1"
              >
                {translate('GOALS_TITLE')}
              </Typography>
              <Box
                className={styles.horizontalScrollContainer}
              >
                {/* TODO input real values when API is fixed */}
                <GoalsProgress
                  goalTitle={translate('DONOR_WEEKLY_GOAL')}
                  goalAmount={20000}
                  goalCurrentAmount={12500}
                  goalProjectedRate={17500}
                />
                <GoalsProgress
                  goalTitle={translate('DONOR_MONTHLY_GOAL')}
                  goalAmount={100000}
                  goalCurrentAmount={32500}
                  goalProjectedRate={60500}
                />
                <DailyStreak
                  streakCount={27}
                  dayResults={[
                    { isStreakDay: true },
                    { isStreakDay: true },
                    { isStreakDay: false },
                    { isStreakDay: null },
                    { isStreakDay: null },
                  ]}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                color="textPrimary"
                variant="subtitle1"
                style={{
                  paddingTop: '12px',
                }}
              >
                {translate('RECENTLY_VIEWED_DONORS_TITLE')}
              </Typography>
              <Box
                className={styles.horizontalScrollContainer}
              >
                <RecentlyViewedDonors
                  donors={recentlyViewedDonorsData}
                  isFetching={isFetching}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        className={styles.navVerticalContainer}
        item
        sm={8}
        xs={12}
      >
        <Box
          className={styles.navHorizontalHeader}
          p={2}
        >
          <Typography
            color="textPrimary"
            variant="subtitle1"
          >
            {translate('FEED_TITLE')}
          </Typography>
        </Box>
        <Feed
          isMobile={isMobile}
          layoutContainerId={topContainerId}
          layoutContainerRef={containerRef}
          feed={feed}
          getFeedPage={getFeedPage}
          setActiveFeedItem={setActiveFeedItem}
          activeFeedItemId={activeFeedItemId}
        />
      </Grid>
    </Grid>
  );
};

HomeLayout.propTypes = {
  feed: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    currentPage: PropTypes.number,
    isFetching: PropTypes.bool,
  }),
  getFeedPage: PropTypes.func,
  setActiveFeedItem: PropTypes.func,
  activeFeedItemId: PropTypes.number,
  recentlyViewedDonors: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    isFetching: PropTypes.bool,
  }),
  goals: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  visits: PropTypes.shape({
    streak: PropTypes.string,
    days: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

HomeLayout.defaultProps = {
  feed: {
    items: [],
  },
  getFeedPage: EMPTY_FUNC,
  setActiveFeedItem: EMPTY_FUNC,
  activeFeedItemId: null,
  recentlyViewedDonors: {
    data: [],
  },
  goals: [],
  visits: {
    streak: '',
    days: [],
  },
};

export {
  HomeLayout,
};
