import {
  camelCaseKeys,
  createAction,
} from 'lib/utils';
import {
  invitesService,
} from 'services/invites';
import userActionTypes from 'rdx/actionTypes';

const {
  SENDING_INVITES_REQUEST,
  SENDING_INVITES_SUCCESS,
  SENDING_INVITES_FAILURE,
  INVITES_REQUEST,
  INVITES_SUCCESS,
  INVITES_FAILURE,
  UPDATE_INVITES_REQUEST,
  UPDATE_INVITES_SUCCESS,
  UPDATE_INVITES_FAILURE,
} = userActionTypes;

const sendInvites = (ownerOrgPubId, dataToSend) => async (dispatch) => {
  try {
    dispatch(createAction({ type: SENDING_INVITES_REQUEST }));

    const response = await invitesService.sendInvites(ownerOrgPubId, dataToSend);

    dispatch(createAction({
      type: SENDING_INVITES_SUCCESS,
      data: camelCaseKeys(response),
    }));
  } catch (err) {
    dispatch(createAction({
      type: SENDING_INVITES_FAILURE,
      err,
    }));
  }
};

const fetchInvites = ownerOrgPubId => async (dispatch) => {
  try {
    dispatch(createAction({ type: INVITES_REQUEST }));

    const response = await invitesService.fetchInvites(ownerOrgPubId);

    dispatch(createAction({
      type: INVITES_SUCCESS,
      data: response?.data || {},
    }));
  } catch (err) {
    dispatch(createAction({
      type: INVITES_FAILURE,
      err,
    }));
  }
};

const updateInvites = (invitePubId, data) => async (dispatch) => {
  try {
    dispatch(createAction({ type: UPDATE_INVITES_REQUEST, invitePubId }));

    const response = await invitesService.updateInvite(invitePubId, data);

    dispatch(createAction({
      type: UPDATE_INVITES_SUCCESS,
      invitePubId,
      data: camelCaseKeys(response),
    }));
  } catch (err) {
    dispatch(createAction({
      type: UPDATE_INVITES_FAILURE,
      invitePubId,
      err,
    }));
  }
};

export const invitesActions = {
  fetchInvites,
  sendInvites,
  updateInvites,
};
