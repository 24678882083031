import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import env from '@beam-australia/react-env';
import {
  Box,
  Container,
  Grid,
  makeStyles,
} from '@material-ui/core';
import {
  dotOrgLogo,
} from 'assets/images';
import {
  authNavLinkStyle,
} from 'components/auth/styles';
import {
  OrgName,
} from 'components';

const customizedAuthNavLinkStyle = {
  ...authNavLinkStyle,
  marginLeft: 'auto',
};
const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    flexGrow: 1,
  },
  container: {
    borderRadius: '8px',
    borderColor: theme.palette.grey[500],
    padding: '48px 56px',
    [theme.breakpoints.down('xs')]: {
      border: 'none',
      padding: '20px',
    },
  },
  navLinkContainer: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginLeft: 'auto',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}));

const AuthContainer = ({
  size,
  navLinkText,
  navLinkRoute,
  orgName,
  children,
}) => {
  const helpLinkAddress = env('HELP_LINK_ADDRESS');
  const privacyLink = env('PRIVACY_LINK');
  const termsLink = env('TERMS_LINK');

  const classes = useStyles();
  // TODO move to central style location
  const linkStyles = {
    fontWeight: '500', textDecoration: 'none', color: '#666', paddingLeft: '24px',
  };

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      alignItems="center"
    >
      <Container
        maxWidth={size}
        m={7}
      >
        <Box
          className={classes.container}
          mt={7}
          mb={1}
          border={1}
        >
          <Box
            mb={6}
            display="flex"
            alignItems="center"
          >
            <img alt="DotOrg logo" src={dotOrgLogo} />
            <OrgName
              orgName={orgName}
            />
            <Box
              className={classes.navLinkContainer}
            >
              <Link
                data-testid="auth-container-nav-link"
                to={{ pathname: navLinkRoute }}
                style={customizedAuthNavLinkStyle}
              >
                {navLinkText}
              </Link>
            </Box>
          </Box>
          <Box mt={3}>
            { children }
          </Box>
        </Box>
        <Box
          mb={7}
          className={classes.footer}
        >
          <a href={`mailto:${helpLinkAddress}`} style={linkStyles}>Help</a>
          <a href={privacyLink} style={linkStyles}>Privacy</a>
          <a href={termsLink} style={linkStyles}>Terms</a>
        </Box>
      </Container>
    </Grid>
  );
};

AuthContainer.propTypes = {
  size: PropTypes.string.isRequired,
  navLinkText: PropTypes.string.isRequired,
  navLinkRoute: PropTypes.string.isRequired,
  orgName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AuthContainer.defaultProps = {
  orgName: null,
};

export default AuthContainer;
