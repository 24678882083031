import React from 'react';
import {
  arrayOf,
  bool,
  func,
  string,
} from 'prop-types';
import {
  DonorType,
} from 'lib/utils';
import {
  EditDonorMainInfo,
} from './edit-donor-main-info';
import {
  EditDonorNotes,
} from './edit-donor-notes';
import {
  EditDonorWorkInfo,
} from './edit-donor-work-info';
import {
  EditDonorAffiliations,
} from './edit-donor-affiliations';

const EditDonorUserSections = ({
  data,
  errors,
  setErrors,
  showErrors,
  updateData,
}) => (
  <>
    <EditDonorMainInfo
      data={data}
      errors={errors}
      setErrors={setErrors}
      showErrors={showErrors}
      updateData={updateData}
    />
    <EditDonorNotes data={data} updateData={updateData} />
    <EditDonorWorkInfo data={data} updateData={updateData} />
    <EditDonorAffiliations data={data} updateData={updateData} />
  </>
);

EditDonorUserSections.propTypes = {
  data: DonorType.isRequired,
  errors: arrayOf(string).isRequired,
  showErrors: bool.isRequired,
  setErrors: func.isRequired,
  updateData: func.isRequired,
};

export {
  EditDonorUserSections,
};
