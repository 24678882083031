import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  ListItemContainer,
  Avatar,
  Visible,
  Loader,
} from 'components';
import {
  donorIcon,
} from 'assets/images';
import {
  getInitials,
} from 'lib/utils';

const StyledDonorIcon = styled.img`
  height: 18px;
  width: 18px;
`;

const RecentlyViewedDonors = ({
  donors,
  isFetching,
}) => (
  <>
    <Visible when={isFetching}>
      <Box
        data-testid="recent-donors-loading"
        style={{
          textAlign: 'center',
          marginTop: '20px',
        }}
      >
        <Loader
          size="48px"
        />
      </Box>
    </Visible>
    <Visible when={!isFetching}>
      <Box>
        {donors.map(donor => (
          <ListItemContainer
            key={donor?.donorPubId}
          >
            <Box
              style={{
                display: 'flex',
              }}
            >
              <Box>
                <Avatar
                  size="40px"
                  src={donor?.avatar}
                >
                  {getInitials(donor?.displayName)}
                </Avatar>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '12px',
                }}
              >
                <Box>
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    {donor?.displayName}
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                  }}
                >
                  <StyledDonorIcon
                    alt="recently-viewed-donor-icon"
                    data-testid="recently-viewed-donor-icon"
                    src={donorIcon}
                  />
                  <Typography
                    variant="subtitle2"
                    style={{
                      paddingLeft: '4px',
                    }}
                  >
                    {donor?.when}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </ListItemContainer>
        ))}
      </Box>
    </Visible>
  </>
);

RecentlyViewedDonors.propTypes = {
  donors: PropTypes.arrayOf(
    PropTypes.shape({
      isStreakDay: PropTypes.bool,
    }),
  ),
  isFetching: PropTypes.bool,
};

RecentlyViewedDonors.defaultProps = {
  donors: [],
  isFetching: false,
};

export {
  RecentlyViewedDonors,
};
