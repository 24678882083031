import React, {
  useCallback,
  useState,
} from 'react';
import {
  arrayOf,
  bool,
  func,
  string,
} from 'prop-types';
import {
  assocPath,
  path as getByPath,
} from 'rambdax';
import {
  DonorType,
} from 'lib/utils';
import {
  Visible,
} from 'components';
import {
  EditDonorMainInfo,
} from './edit-donor-main-info';
import {
  EditDonorNotes,
} from './edit-donor-notes';
import {
  EditDonorContactInfo,
} from './edit-donor-contact-info';
import {
  EditDonorAffiliations,
} from './edit-donor-affiliations';

const SECONDARY_CONTACT_PATH = 'developers.dev1';

const EditDonorOrgSections = ({
  data,
  errors,
  setErrors,
  showErrors,
  updateData,
}) => {
  const [showSecondaryContact, setShowSecondaryContact] = useState(
    Boolean(getByPath(SECONDARY_CONTACT_PATH, data)),
  );

  const toggleSecondaryContact = useCallback(() => {
    const hideSecondary = showSecondaryContact;

    setShowSecondaryContact(!hideSecondary);

    if (hideSecondary) {
      updateData(assocPath(SECONDARY_CONTACT_PATH, null, data), SECONDARY_CONTACT_PATH);
    }
  }, [
    showSecondaryContact,
  ]);

  return (
    <>
      <EditDonorMainInfo
        data={data}
        errors={errors}
        isUserType={false}
        setErrors={setErrors}
        showErrors={showErrors}
        updateData={updateData}
      />
      <EditDonorNotes data={data} updateData={updateData} />
      <EditDonorContactInfo
        data={data}
        isPrimaryContact
        showSecondaryContact={showSecondaryContact}
        toggleSecondaryContact={toggleSecondaryContact}
        updateData={updateData}
      />
      <Visible when={showSecondaryContact}>
        <EditDonorContactInfo
          data={data}
          isPrimaryContact={false}
          showSecondaryContact={showSecondaryContact}
          toggleSecondaryContact={toggleSecondaryContact}
          updateData={updateData}
        />
      </Visible>
      <EditDonorAffiliations
        data={data}
        isUserType={false}
        updateData={updateData}
      />
    </>
  );
};

EditDonorOrgSections.propTypes = {
  data: DonorType.isRequired,
  errors: arrayOf(string).isRequired,
  showErrors: bool.isRequired,
  setErrors: func.isRequired,
  updateData: func.isRequired,
};

export {
  EditDonorOrgSections,
};
