import userActionTypes from 'rdx/actionTypes';

const {
  DONORS_REQUEST,
  DONORS_SUCCESS,
  DONORS_FAILURE,
} = userActionTypes;

const initialState = {
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: [],
};

export function donors(
  state = initialState,
  action,
) {
  switch (action.type) {
    case DONORS_REQUEST:
      return {
        ...state,
        dataLoaded: false,
        isFetching: true,
        error: null,
      };
    case DONORS_SUCCESS:
      return {
        ...state,
        data: action.data.donors,
        dataLoaded: true,
        isFetching: false,
        error: null,
      };
    case DONORS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
