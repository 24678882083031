import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  arrayOf,
  bool,
  func,
  oneOf,
  string,
} from 'prop-types';
import {
  Box,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  colors,
} from 'styles/theme';
import {
  translate,
} from 'lib/intl';
import {
  isMobileView,
} from 'lib/utils';
import {
  HeadingWithIcon,
  Gap,
  LinkLikeButton,
  Loader,
  Visible,
} from 'components';
import {
  InviteSelector,
} from './invite-selector';
import {
  INVITE_STATUSES,
  ICON_STATUS_MAPPER,
} from './constants';

const StyledTeamMemberItem = styled(Box)`
  border-bottom: 1px solid ${colors.lightGrey};
`;

const StyledSelectBottomComponent = styled(Box)`
  cursor: pointer;
`;

const TeamMemberItem = ({
  availableGroups,
  email,
  groups,
  invitePubId,
  isUpdating,
  status,
  sendInvites,
  updateInvite,
}) => {
  const isMobile = isMobileView();
  const [selectedOptions, setOptions] = useState(groups || []);
  const [hasChanges, setHasChanges] = useState(false);

  const resendInvite = useCallback(() => {
    sendInvites({
      emails: [email],
      groups: selectedOptions,
    });
  }, [
    email,
    selectedOptions,
  ]);

  const onGroupsChange = useCallback((newGroups) => {
    setHasChanges(true);
    setOptions(newGroups);
  }, []);

  const onSelectClose = useCallback((currentOptions) => {
    if (hasChanges && groups?.sort().join(',') !== currentOptions?.sort().join(',')) {
      updateInvite({
        invitePubId,
        groups: currentOptions,
        status,
      });
    }
  }, [hasChanges]);

  const SelectBottomComponent = useMemo(() => ({ onClose }) => {
    const onRemove = () => {
      updateInvite({
        invitePubId,
        status: INVITE_STATUSES.CANCELLED,
      });
      onClose();
    };

    return (
      <StyledSelectBottomComponent px={2} py={1} onClick={onRemove}>
        <Typography variant="body1">{translate('REMOVE')}</Typography>
      </StyledSelectBottomComponent>
    );
  }, []);

  return (
    <StyledTeamMemberItem
      alignItems={isMobile ? 'start' : 'center'}
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
      px={2}
      py={1}
    >
      <HeadingWithIcon
        iconSrc={ICON_STATUS_MAPPER[status]}
        iconSize="16px"
        mobileFontSize="16px"
        title={email}
        variant="body1"
      />
      <Box alignItems="center" display="flex" ml="12px">
        <Visible
          fallback={<Loader />}
          when={!isUpdating}
        >
          <InviteSelector
            BottomComponent={SelectBottomComponent}
            onClose={onSelectClose}
            options={availableGroups}
            selectedOptions={selectedOptions}
            handleChange={onGroupsChange}
          />
          <Gap size={isMobile ? 3 : 2} />
          <LinkLikeButton onClick={resendInvite}>
            {translate('RESEND')}
          </LinkLikeButton>
        </Visible>
      </Box>
    </StyledTeamMemberItem>
  );
};

TeamMemberItem.propTypes = {
  availableGroups: arrayOf(string),
  email: string.isRequired,
  invitePubId: string.isRequired,
  isUpdating: bool.isRequired,
  groups: arrayOf(string),
  status: oneOf(Object.values(INVITE_STATUSES)).isRequired,
  sendInvites: func.isRequired,
  updateInvite: func.isRequired,
};

TeamMemberItem.defaultProps = {
  availableGroups: [],
  groups: [],
};

export {
  TeamMemberItem,
};
