export const COMMON = {
  APP_NAME: 'DotOrg',
  GO_BACK: 'Back',
  ALL: 'All',
  YES: 'Yes',
  NO: 'No',
  THANK_YOU: 'Thank you',
  SUBMIT: 'Submit',
  CONTACT_US: 'contact us',
  LAST_LABEL: 'Last',
  LAST_YEAR_LABEL: 'Last Year',
  THIS_YEAR_LABEL: 'This Year',
  THIS_YEAR_TOTAL_LABEL: 'This Year Total',
  LIFETIME_LABEL: 'Lifetime',
  LIFETIME_TOTAL_LABEL: 'Lifetime Total',
  REFERRED_BY_LABEL: 'Referred by:',
  CAPACITY_LABEL: 'Cap',
  PLANNED_LABEL: 'Planned',
  SHOW_MORE_OPTIONS_LABEL: 'Show More Options',
  SHOW_LESS_OPTIONS_LABEL: 'Show Less Options',
  PINNED: 'Pinned',
  GENERIC_ERROR: 'An error occurred.',
};
