import React from 'react';
import {
  AuthContainer,
} from 'components';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import {
  checkEmailIcon,
} from 'assets/images';
import {
  translate,
} from 'lib/intl';
import {
  PUBLIC_ROUTES,
} from 'lib/constants';

const RegistrationSuccessPage = () => (
  <AuthContainer size="md" navLinkText="Sign in" navLinkRoute={PUBLIC_ROUTES.LOGIN}>
    <Grid container>
      <Grid item sm={6} xs={12}>
        <Typography variant="h5">{translate('REGISTER_SUCCESS_HEADER')}</Typography>
        <Typography variant="h6" style={{ marginTop: '30px' }}>{translate('REGISTER_EMAIL_LINK_CHECK')}</Typography>
        <Typography variant="body1" style={{ margin: '30px 0' }}>{translate('REGISTER_EMAIL_LINK_CLOSE_TAB')}</Typography>
      </Grid>
      <Grid
        item
        sm={6}
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '160px',
          height: '160px',
        }}
      >
        <img alt="Check email" src={checkEmailIcon} />
      </Grid>
    </Grid>
  </AuthContainer>
);

export {
  RegistrationSuccessPage,
};
