import React from 'react';
import {
  Box,
  Link,
} from '@material-ui/core';
import {
  translate,
} from 'lib/intl';
import {
  mapCapacity,
  mapLastDonationDate,
  mapReferralUrl,
} from 'lib/mappers';
import {
  Avatar,
  Visible,
} from 'components';
import {
  COLON,
} from 'lib/constants';
import {
  DonorType,
  formatCurrency,
  getInitials,
  isMobileView,
} from 'lib/utils';
import {
  HourGlassIcon,
  GiftIcon,
  MoneyBagIcon,
} from './donor-icons';
import {
  DonorSectionWrapper,
} from './donor-section-wrapper';
import {
  DonorSectionTitle,
} from './donor-section-title';
import {
  DonorSummaryItem,
} from './donor-summary-item';

const DonorSummary = ({
  donor,
}) => {
  const isMobile = isMobileView();

  const {
    capacity,
    donorIdentity,
    lastDonationDate,
    lifetimeCount = '0',
    lifetimeSum,
    lastYearSum,
    planned,
    thisYearCount = '0',
    thisYearSum,
  } = donor?.donorSummary || {};

  return (
    <DonorSectionWrapper>
      <DonorSectionTitle>
        {translate('DONOR_DIVING_SUMMARY')}
      </DonorSectionTitle>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={isMobile ? 'column' : 'row'}
        flexWrap="wrap"
      >
        <Box pr={2}>
          <DonorSummaryItem parts={[
            translate('LAST_LABEL'),
            GiftIcon,
            COLON,
            mapLastDonationDate(lastDonationDate),
          ]}
          />
          <DonorSummaryItem parts={[
            translate('THIS_YEAR_LABEL'),
            MoneyBagIcon,
            COLON,
            formatCurrency(thisYearSum),
          ]}
          />
          <DonorSummaryItem parts={[
            translate('THIS_YEAR_TOTAL_LABEL'),
            GiftIcon,
            COLON,
            thisYearCount,
          ]}
          />
        </Box>
        <Box pr={2}>
          <DonorSummaryItem parts={[
            translate('LAST_YEAR_LABEL'),
            MoneyBagIcon,
            COLON,
            formatCurrency(lastYearSum),
          ]}
          />
          <DonorSummaryItem parts={[
            translate('LIFETIME_LABEL'),
            MoneyBagIcon,
            COLON,
            formatCurrency(lifetimeSum),
          ]}
          />
          <DonorSummaryItem parts={[
            translate('LIFETIME_TOTAL_LABEL'),
            MoneyBagIcon,
            COLON,
            lifetimeCount,
          ]}
          />
        </Box>
        <Box>
          <Visible when={donorIdentity}>
            <DonorSummaryItem parts={[
              translate('REFERRED_BY_LABEL'),
              (
                <Avatar size="24px">
                  {getInitials(donorIdentity?.user?.displayName)}
                </Avatar>
              ),
              (
                <Link href={mapReferralUrl(donorIdentity?.donorId)}>
                  {donorIdentity?.user?.displayName}
                </Link>
              ),
            ]}
            />
          </Visible>
          <DonorSummaryItem parts={[
            GiftIcon,
            translate('CAPACITY_LABEL'),
            MoneyBagIcon,
            COLON,
            mapCapacity(capacity),
          ]}
          />
          <DonorSummaryItem parts={[
            HourGlassIcon,
            GiftIcon,
            COLON,
            formatCurrency(planned),
            translate('PLANNED_LABEL'),
          ]}
          />
        </Box>
      </Box>
    </DonorSectionWrapper>
  );
};

DonorSummary.propTypes = {
  donor: DonorType,
};

DonorSummary.defaultProps = {
  donor: {},
};

export {
  DonorSummary,
};
