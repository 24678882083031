import React, {
  useCallback,
  useMemo,
} from 'react';
import {
  bool,
  func,
} from 'prop-types';
import {
  Box,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  values,
} from 'rambdax';
import {
  colors,
} from 'styles/theme';
import {
  EDIT_DONOR_FORM_TYPES,
} from './config';

const StyledWrapper = styled(Box)`
  .MuiSelect-select:focus {
    background: none;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid ${colors.grey500};
  }

  .MuiSelect-select.MuiSelect-select {
    padding-bottom: 4px;
  }
`;

const EditDonorTypeSelector = ({
  isUserForm,
  toggleDonorForm,
}) => {
  const MenuItems = useMemo(() => values(EDIT_DONOR_FORM_TYPES).map(({ label, value }) => (
    <MenuItem key={value} value={value}>
      <Typography color="textPrimary">
        {label}
      </Typography>
    </MenuItem>
  )), []);

  const value = useMemo(() => (isUserForm
    ? EDIT_DONOR_FORM_TYPES.user.value
    : EDIT_DONOR_FORM_TYPES.org.value
  ), [
    isUserForm,
  ]);

  const onChange = useCallback(({ target: { value: currentValue } }) => {
    if (value !== currentValue) {
      toggleDonorForm();
    }
  }, [value]);

  return (
    <StyledWrapper>
      <Select
        data-testid="edit-donor-type-selector"
        id="edit-donor-type-selector"
        onChange={onChange}
        value={value}
      >
        {MenuItems}
      </Select>
    </StyledWrapper>
  );
};

EditDonorTypeSelector.propTypes = {
  isUserForm: bool.isRequired,
  toggleDonorForm: func.isRequired,
};

export {
  EditDonorTypeSelector,
};
