import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  Box,
  makeStyles,
  Typography,
  Grid,
} from '@material-ui/core';
import {
  moneyBagIcon,
} from 'assets/images';
import {
  colors,
} from 'styles/theme';
import {
  ListItemContainer,
} from 'components';

const useStyles = makeStyles(theme => ({
  titleSectionContainer: {
    paddingBottom: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  progressSectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  progressContainer: {
    height: '11px',
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
  },
  progress: {
    height: '100%',
    background: colors.progressGreen,
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    zIndex: 2,
  },
  projected: {
    height: '100%',
    background: colors.progressDarkGrey,
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    zIndex: 1,
  },
  progressBackground: {
    background: colors.progressLightGrey,
    borderRadius: theme.shape.borderRadius,
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  active: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  typographyOverride: {
    lineHeight: '1.5',
    letterSpacing: '.25px',
  },
  moneyIcon: {
    width: 'auto',
    height: '20px',
    paddingLeft: '8px',
  },
}));

const GoalsProgress = ({
  goalTitle,
  goalAmount,
  goalCurrentAmount,
  goalProjectedRate,
}) => {
  const classes = useStyles();
  // const feedItemClassName = isActive ? `${styles.feedItem} ${styles.active}` : styles.feedItem;

  const progressPercentage = goalCurrentAmount / goalAmount * 100;
  const projectedProgressPercentage = goalProjectedRate / goalAmount * 100;

  return (
    <ListItemContainer>
      <Grid
        container
        className={classes.titleSectionContainer}
      >
        <Typography
          variant="body1"
          className={classes.typographyOverride}
          style={{
            paddingTop: '1px',
            paddingRight: '4px',
          }}
        >
          {goalTitle}
          :
        </Typography>
        <Typography
          variant="subtitle1"
          className={classes.typographyOverride}
          style={{
            paddingTop: '1px',
          }}
        >
          <NumberFormat
            value={goalAmount}
            displayType="text"
            thousandSeparator
            prefix="$"
          />
        </Typography>
        <img
          className={classes.moneyIcon}
          alt="Money Bag Icon"
          src={moneyBagIcon}
        />
      </Grid>
      <Grid
        container
        className={classes.progressSectionContainer}
      >
        <Grid
          item
          xs={10}
          className={classes.progressContainer}
        >
          <Box
            className={classes.progress}
            style={{
              width: `${progressPercentage}%`,
            }}
          />
          <Box
            className={classes.projected}
            style={{
              width: `${projectedProgressPercentage}%`,
            }}
          />
          <Box
            className={classes.progressBackground}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <Typography
            variant="subtitle1"
            className={classes.typographyOverride}
            style={{
              fontSize: '12px',
              paddingLeft: '8px',
            }}
          >
            <NumberFormat
              value={goalCurrentAmount}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
          </Typography>
        </Grid>
      </Grid>
    </ListItemContainer>
  );
};

GoalsProgress.propTypes = {
  goalTitle: PropTypes.string.isRequired,
  goalAmount: PropTypes.number.isRequired,
  goalCurrentAmount: PropTypes.number,
  goalProjectedRate: PropTypes.number,
};

GoalsProgress.defaultProps = {
  goalCurrentAmount: 0,
  goalProjectedRate: 0,
};

export {
  GoalsProgress,
};
