import React from 'react';
import {
  translate,
} from 'lib/intl';
// TODO debug jest pages not found module issue
import {
  GenericErrorPage,
} from '../../error-pages';

const RegistrationVerificationErrorPage = () => (
  <GenericErrorPage
    mainTitle={translate('REGISTER_VERIFICATION_ERROR')}
    content={translate('REGISTER_VERIFICATION_ERROR_CONTACT')}
  />
);

export {
  RegistrationVerificationErrorPage,
};
