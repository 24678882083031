import userActionTypes from 'rdx/actionTypes';

const initialState = {
  prevPath: '',
};

export function location(
  state = initialState,
  action,
) {
  switch (action.type) {
    case userActionTypes.SET_LOCACTION_STATE:
      return {
        ...state,
        prevPath: action.prevPath,
      };
    default:
      return state;
  }
}
