
import {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  donorFormActions,
} from 'rdx/actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  flatten,
  uniq,
} from 'rambdax';
import {
  mapDonorEditedFields,
} from 'lib/mappers';

const {
  saveDonor: saveUpdatedDonor,
  updateUserDonorForm,
  updateOrgDonorForm,
  resetDonorSaving,
  resetDonorForm,
  setErrorsOrgDonorForm,
  updateDonorEditedFields,
} = donorFormActions;

const validateDonorForm = (donorForm, isUserForm) => {
  const errors = [...donorForm.errors];

  if (isUserForm && !donorForm.user.firstName) {
    errors.push('firstName');
  }

  if (isUserForm && !donorForm.user.lastName) {
    errors.push('lastName');
  }

  if (!isUserForm && !donorForm.org.orgName) {
    errors.push('orgName');
  }

  return errors;
};

const useDonorForm = ({
  defaultData,
  onSave,
  ownerOrgPubId,
  id,
  isCreating = false,
}) => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isUserForm, setIsUserForm] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [showStatusDialog, setShowStatusDialog] = useState(false);

  const dispatch = useDispatch();

  const {
    donorForm,
    isLoading,
    isDataLoaded,
    isFailed,
    savedDonorId,
  } = useSelector(state => ({
    donorForm: state.donorForm,
    isLoading: state.saveDonor.isFetching,
    isDataLoaded: state.saveDonor.dataLoaded,
    isFailed: state.saveDonor.error,
    savedDonorId: state.saveDonor?.data?.donor?.donorPubId,
  }));

  const updateForm = useCallback((data, path) => {
    dispatch(isUserForm
      ? updateUserDonorForm(data)
      : updateOrgDonorForm(data));

    if (path) {
      dispatch(updateDonorEditedFields(uniq(flatten([donorForm.editedFields, path]))));
    }
  }, [
    donorForm,
    isUserForm,
  ]);

  const resetForm = useCallback(() => dispatch(resetDonorForm()), []);

  const setErrors = useCallback(errors => dispatch(setErrorsOrgDonorForm(errors)), []);

  const openEditModal = useCallback(() => {
    if (id) {
      updateForm(defaultData, '');
    }
    setEditModalOpen(true);
  }, [
    defaultData,
    id,
    updateForm,
  ]);

  const closeEditModal = useCallback(() => {
    setEditModalOpen(false);
    resetForm();
  }, []);


  useEffect(() => {
    if (isDataLoaded) {
      closeEditModal();
      setShowStatusDialog(true);
    }
  }, [
    isDataLoaded,
  ]);

  useEffect(() => {
    if (isFailed) {
      setShowStatusDialog(true);
    }
  }, [isFailed]);

  const toggleDonorForm = useCallback(() => {
    resetForm();
    setIsUserForm(!isUserForm);
  }, [
    isUserForm,
    resetForm,
  ]);

  // TODO: @anna - add save on Edit handler in DOT-115
  const saveDonor = useCallback(() => {
    const errors = validateDonorForm(donorForm, isUserForm);

    if (errors.length) {
      setErrors(errors);
      setShowErrors(true);
    } else {
      const mappedData = mapDonorEditedFields({
        donorForm,
        includeDonorType: !id,
        isUserForm,
      });

      dispatch(saveUpdatedDonor({
        donorPubId: id,
        data: mappedData,
        ownerOrgPubId,
      }));
    }
  }, [
    id,
    donorForm,
    isCreating,
    ownerOrgPubId,
  ]);

  const closeStatusDialog = useCallback(() => {
    if (isDataLoaded && onSave) {
      onSave();
    }

    setShowStatusDialog(false);
  }, [isDataLoaded]);

  const resetSavingDonor = useCallback(() => dispatch(resetDonorSaving()), []);

  const formData = useMemo(() => (isUserForm
    ? donorForm.user
    : donorForm.org), [
    donorForm,
    isUserForm,
  ]);

  const errors = useMemo(() => donorForm.errors, [
    donorForm,
  ]);

  return {
    errors,
    formData,
    isDataLoaded,
    isEditModalOpen,
    isFailed,
    isLoading,
    isUserForm,
    openEditModal,
    closeEditModal,
    resetForm,
    showErrors,
    toggleDonorForm,
    updateForm,
    resetSavingDonor,
    setErrors,
    showStatusDialog,
    closeStatusDialog,
    saveDonor,
    savedDonorId,
  };
};

export {
  useDonorForm,
};
