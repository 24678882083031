import React, {
  useMemo,
} from 'react';
import {
  bool,
  string,
} from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  Typography,
} from '@material-ui/core';
import {
  Gap,
  Visible,
} from 'components';
import {
  donorIcon,
} from 'assets/images';
import {
  formatDistance,
} from 'lib/utils';
import {
  translate,
} from 'lib/intl';

const StyledDonorIcon = styled.img`
  height: 18px;
  width: 18px;
`;

const LastInteraction = ({
  date,
  showLastLabel,
}) => {
  const timeDistanceToNow = useMemo(() => (date ? formatDistance(date) : ''), [date]);

  return (
    <Visible when={timeDistanceToNow}>
      <Box alignItems="center" display="flex">
        <Visible when={showLastLabel}>
          <Typography variant="caption">{translate('LAST_LABEL')}</Typography>
        </Visible>
        <Gap size="4px" />
        <StyledDonorIcon
          alt="last-interaction-donor-icon"
          data-testid="last-interaction-donor-icon"
          src={donorIcon}
        />
        <Gap size="4px" />
        <Typography variant="caption">{timeDistanceToNow}</Typography>
      </Box>
    </Visible>
  );
};

LastInteraction.propTypes = {
  date: string,
  showLastLabel: bool,
};

LastInteraction.defaultProps = {
  date: '',
  showLastLabel: false,
};

export {
  LastInteraction,
};
