import {
  createApiRequest,
} from 'lib/utils';

const fetchUsers = ownerOrgPubId => createApiRequest({
  method: 'get',
  path: `users/${ownerOrgPubId}`,
});

export const usersService = {
  fetchUsers,
};
