import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box, AppBar, makeStyles, Toolbar, IconButton, Menu, Fade, List, Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import {
  dotOrgLogo,
  homeIcon,
  donorIcon,
  campaignIcon,
  reportsIcon,
  phoneIcon,
  settingsIcon,
} from 'assets/images';
import {
  AUTH_ROUTES,
} from 'lib/constants';
import {
  NavigationListItem,
  UserProfileBottomLinks,
  UserProfileSignOutButton,
  UserProfileUpper,
} from 'components';

const MobileNavigation = ({
  children,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const appBarRef = useRef();
  let userSession;
  let orgName;

  try {
    userSession = useSelector(state => state.authentication.user);
    orgName = userSession?.org?.ownerOrgName;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
  }

  const useStyles = makeStyles(theme => ({
    container: {
      overflowY: 'hidden',
    },
    buttonWrap: {
      margin: theme.spacing(2),
      marginTop: 0,
    },
  }));
  const classes = useStyles();

  const handleMenu = () => {
    setAnchorEl(appBarRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      className={classes.container}
    >
      <AppBar
        position="static"
        color="inherit"
        ref={appBarRef}
      >
        <Toolbar>
          <IconButton
            data-testid="mobile-navigation-menu-button"
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
          <img
            alt="DotOrg logo"
            src={dotOrgLogo}
            style={{ width: '40px' }}
          />
          <ClearIcon
            style={{
              fontSize: '18px',
              paddingLeft: '8px',
            }}
          />
          <Typography
            variant="body1"
            style={{
              paddingLeft: '8px',
            }}
          >
            {orgName}
          </Typography>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            keepMounted
            marginThreshold={0}
            PaperProps={{
              style: {
                height: '90%',
                width: '80%',
                borderRadius: '0',
              },
            }}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            MenuListProps={{
              style: {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              },
            }}
          >
            <UserProfileUpper />
            <List
              component="nav"
              aria-label="upper-main"
              style={{
                borderTop: '1px solid #ECECEC',
              }}
            >
              <NavigationListItem navRoute={AUTH_ROUTES.LANDING} exact icon={homeIcon} iconAlt="Home Icon" itemText="Home" />
              <NavigationListItem navRoute="/donors" icon={donorIcon} iconAlt="Donor Icon" itemText="Donors" />
              <NavigationListItem navRoute="/campaigns" icon={campaignIcon} iconAlt="Campaign Icon" itemText="Campaigns" />
              <NavigationListItem navRoute="/reports" icon={reportsIcon} iconAlt="Reports Icon" itemText="Reports" />
              <NavigationListItem navRoute={AUTH_ROUTES.INVITE} icon={settingsIcon} iconAlt="Settings Icon" itemText="Invite your team" />
              <NavigationListItem navRoute="/contacts" icon={phoneIcon} iconAlt="Phone Icon" itemText="Contacts" />
            </List>
            <div
              style={{
                marginTop: 'auto',
              }}
            >
              <UserProfileSignOutButton />
              <UserProfileBottomLinks />
            </div>
          </Menu>
        </Toolbar>
      </AppBar>
      <>
        {children}
      </>
    </Box>
  );
};

MobileNavigation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

MobileNavigation.defaultProps = {
  children: null,
};

export default MobileNavigation;
