import React, {
  Fragment,
  useMemo,
} from 'react';
import {
  Box,
  Link,
  Typography,
} from '@material-ui/core';
import {
  prop,
} from 'rambdax';
import styled from 'styled-components';
import {
  CustomButton,
  DonorCard,
  EditDonorModal,
  Gap,
  Visible,
  StatusDialog,
} from 'components';
import {
  donorIcon,
  PlusIcon,
} from 'assets/images';
import {
  translate,
} from 'lib/intl';
import {
  useDonorForm,
} from 'lib/hooks';
import {
  colors,
} from 'styles/theme';
import {
  DONORS_PAGE_HEADER_HEIGHT,
} from './constants';
import {
  useDonorsPage,
} from './hooks';

const StyledContainer = styled(Box)`
  height: 100%;
  padding-top: 0px;
  position: relative;
`;

const StyledDonorsContainer = styled(Box)`
  background: ${colors.greyCustom2};
  border: 1px solid ${colors.lightGrey};
  border-radius: 8px;
  height: calc(100% - ${DONORS_PAGE_HEADER_HEIGHT});
  max-width: 600px;
  padding: 12px;
`;

const StyledImage = styled.img`
  height: ${prop('size')};
  width: ${prop('size')};
`;

const DonorsPage = () => {
  const {
    donors,
    fetchDonors,
    onDonorClick,
    ownerOrgPubId,
  } = useDonorsPage();

  const {
    errors,
    formData,
    isFailed,
    isEditModalOpen,
    isLoading,
    isUserForm,
    openEditModal,
    closeEditModal,
    updateForm,
    saveDonor,
    showErrors,
    resetSavingDonor,
    setErrors,
    toggleDonorForm,
    showStatusDialog,
    closeStatusDialog,
    savedDonorId,
  } = useDonorForm({
    isCreating: true,
    onSave: fetchDonors,
    ownerOrgPubId,
  });

  const Donors = useMemo(() => donors?.map(props => (
    // eslint-disable-next-line
    <Fragment key={props.donorPubId}>
      <DonorCard onClick={onDonorClick} {...props} />
      <Gap vertical />
    </Fragment>
  )), [donors]);

  return (
    <StyledContainer px={2} pb={2}>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        height={DONORS_PAGE_HEADER_HEIGHT}
      >
        <Box display="flex">
          <StyledImage
            alt="donor-icon"
            data-testid="donor-icon"
            size="26px"
            src={donorIcon}
          />
          <Gap size={1} />
          <Typography variant="h5">{translate('DONORS_TITLE')}</Typography>
        </Box>
        <CustomButton
          id="new-donor-button"
          buttonType="primary"
          variant="contained"
          label={translate('NEW_DONOR_LABEL')}
          onClick={openEditModal}
          prefix={<PlusIcon />}
        />
      </Box>
      <Visible when={donors?.length}>
        <StyledDonorsContainer>
          {Donors}
        </StyledDonorsContainer>
      </Visible>
      <EditDonorModal
        data={formData}
        errors={errors}
        handleClose={closeEditModal}
        handleSave={saveDonor}
        isCreating
        isLoading={isLoading}
        isUserForm={isUserForm}
        open={isEditModalOpen}
        setErrors={setErrors}
        showErrors={showErrors}
        toggleDonorForm={toggleDonorForm}
        updateData={updateForm}
      />
      <StatusDialog
        open={showStatusDialog}
        onClose={closeStatusDialog}
        onExited={resetSavingDonor}
        isSuccessMessage={!isFailed}
        message={translate(isFailed ? 'TRY_AGAIN' : 'DONOR_CREATED_MESSAGE')}
        title={translate(isFailed ? 'DEFAULT_ERROR' : 'DONOR_CREATED_LABEL')}
      >
        {/* TODO: @anna - remove this link once all donors are visible on the page */}
        <Visible when={savedDonorId}>
          <Link onClick={() => onDonorClick(savedDonorId)} style={{ cursor: 'pointer' }}>
            {translate('DONOR_OPEN_PROFILE')}
          </Link>
        </Visible>
      </StatusDialog>
    </StyledContainer>
  );
};

export {
  DonorsPage,
};
