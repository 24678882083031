import React, {
  Fragment,
} from 'react';
import {
  arrayOf,
  node,
} from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  Typography,
} from '@material-ui/core';

const StyledTextContainer = styled(Box)`
  align-items: center;
  display: flex;
  padding-bottom: 20px;
`;

const DonorSummaryItem = ({ parts }) => (
  <StyledTextContainer>
    {parts?.map((part, idx) => {
      if (['string', 'number'].includes(typeof part)) {
        return (
          // eslint-disable-next-line
          <Typography key={idx}>
            {part}
            &nbsp;
          </Typography>
        );
      }

      return (
        // eslint-disable-next-line
        <Fragment key={idx}>
          {part}
          &nbsp;
        </Fragment>
      );
    })}
  </StyledTextContainer>
);

DonorSummaryItem.propTypes = {
  parts: arrayOf(node).isRequired,
};

export {
  DonorSummaryItem,
};
