import {
  values,
} from 'rambdax';

const showPersonalInformation = ({
  addresses,
  dob,
  emails,
  facebook,
  instagram,
  linkedin,
  phones,
  twitter,
  website,
} = {}) => addresses?.primary
  || addresses?.mailing
  || emails?.primary
  || phones?.home
  || phones?.primary
  || website
  || facebook
  || instagram
  || linkedin
  || twitter
  || dob;

const showWorkInformation = ({
  addresses,
  emails,
  employer,
  phones,
} = {}) => addresses?.work
  || emails?.work
  || phones?.work
  || values(employer).find(val => val);

const showAffiliations = ({
  affiliations,
  acquaintances,
  connections,
  employees,
}) => acquaintances?.length
  || affiliations?.length
  || connections?.length
  || employees?.length;

export {
  showPersonalInformation,
  showWorkInformation,
  showAffiliations,
};
