import React from 'react';
import styled from 'styled-components';
import {
  Box,
} from '@material-ui/core';
import {
  Visible,
} from 'components';
import {
  DonorType,
  isMobileView,
  showPersonalInformation,
  showWorkInformation,
  showAffiliations,
} from 'lib/utils';
import {
  colors,
} from 'styles/theme';
import {
  PersonalInformation,
} from './personal-information';
import {
  WorkInformation,
} from './work-information';
import {
  Affiliations,
} from './affiliations';
import {
  DonorSummary,
} from './donor-summary';

const SectionContainer = styled(Box)`
  border-bottom: ${({ border }) => (border ? `1px solid ${colors.transparentGrey}` : 'none')};
  padding: 32px ${({ mobile }) => (mobile ? 16 : 32)}px;
`;

const PersonalInfoContent = ({
  donor,
}) => {
  const isMobile = isMobileView();

  return (
    <Box>
      <Visible when={showPersonalInformation(donor)}>
        <SectionContainer mobile={Number(isMobile)} border="true">
          <PersonalInformation donor={donor} />
        </SectionContainer>
      </Visible>
      <Visible when={showWorkInformation(donor)}>
        <SectionContainer mobile={Number(isMobile)} border="true">
          <WorkInformation donor={donor} />
        </SectionContainer>
      </Visible>
      <Visible when={showAffiliations(donor)}>
        <SectionContainer mobile={Number(isMobile)} border="true">
          <Affiliations donor={donor} />
        </SectionContainer>
      </Visible>
      <SectionContainer mobile={Number(isMobile)}>
        <DonorSummary donor={donor} />
      </SectionContainer>
    </Box>
  );
};

PersonalInfoContent.propTypes = {
  donor: DonorType,
};

PersonalInfoContent.defaultProps = {
  donor: {},
};

export {
  PersonalInfoContent,
};
