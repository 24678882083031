import React from 'react';
import {
  makeStyles,
} from '@material-ui/core';
import {
  translate,
} from 'lib/intl';
import {
  userService,
} from 'services/user';
import {
  CustomButton,
} from 'components';

const UserProfileSignOutButton = () => {
  const handleLogout = () => userService.logout().then((response) => {
    if (!response) {
      // eslint-disable-next-line no-console
      console.warn('Error logging out');
    }
  });

  const useStyles = makeStyles(themeStyles => ({
    buttonWrap: {
      margin: '16px 28px',
      marginTop: 0,
      [themeStyles.breakpoints.down('xs')]: {
        margin: themeStyles.spacing(2),
      },
    },
  }));
  const classes = useStyles();

  return (
    <div
      data-testid="user-profile-sign-out-button"
      className={classes.buttonWrap}
    >
      <CustomButton
        fullWidth
        buttonType="tertiary"
        variant="contained"
        onClick={handleLogout}
        label={translate('SIGN_OUT')}
      />
    </div>
  );
};

export {
  UserProfileSignOutButton,
};
