import {
  createAction,
} from 'lib/utils';
import {
  donorsService,
} from 'services/donors';
import userActionTypes from 'rdx/actionTypes';

const {
  DONORS_REQUEST,
  DONORS_SUCCESS,
  DONORS_FAILURE,
  RECENTLY_VIEWED_DONORS_REQUEST,
  RECENTLY_VIEWED_DONORS_SUCCESS,
  RECENTLY_VIEWED_DONORS_FAILURE,
} = userActionTypes;

const fetchDonors = ownerOrgPubId => async (dispatch) => {
  try {
    dispatch(createAction({ type: DONORS_REQUEST }));

    const response = await donorsService.fetchDonors(ownerOrgPubId);

    dispatch(createAction({
      type: DONORS_SUCCESS,
      data: response?.data || {},
    }));
  } catch (err) {
    dispatch(createAction({
      type: DONORS_FAILURE,
      err,
    }));
  }
};

const fetchRecentlyViewedDonors = () => async (dispatch) => {
  try {
    dispatch(createAction({ type: RECENTLY_VIEWED_DONORS_REQUEST }));
    const response = await donorsService.fetchRecentlyViewedDonors();

    dispatch(createAction({
      type: RECENTLY_VIEWED_DONORS_SUCCESS,
      data: response?.data || {},
    }));
  } catch (error) {
    dispatch(createAction({
      type: RECENTLY_VIEWED_DONORS_FAILURE,
      error,
    }));
  }
};

export const donorsActions = {
  fetchDonors,
  fetchRecentlyViewedDonors,
};
