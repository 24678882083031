import userActionTypes from 'rdx/actionTypes';

const {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAILURE,
} = userActionTypes;

const initialState = {
  isFetching: false,
  error: null,
  visits: {},
  goals: [],
};

export function user(
  state = initialState,
  action,
) {
  switch (action.type) {
    case USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case USERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        visits: action.visits,
        goals: action.goals,
      };
    case USERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
