import React from 'react';
import styled from 'styled-components';
import {
  string,
  node,
} from 'prop-types';
import {
  Avatar as MaterialAvatar,
} from '@material-ui/core';
import {
  prop,
} from 'rambdax';

const StyledAvatar = styled(MaterialAvatar)`
  font-size: ${({ size }) => parseInt(size, 10) / 2 - 1}px;
  height: ${prop('size')};
  line-height: ${prop('size')};
  width: ${prop('size')};
`;

const Avatar = ({
  children,
  size,
  ...rest
}) => (
  <StyledAvatar size={size} {...rest}>
    {children}
  </StyledAvatar>
);

Avatar.propTypes = {
  children: node,
  size: string,
};

Avatar.defaultProps = {
  children: '',
  size: '40px',
};

export {
  Avatar,
};
