const EMPTY_FUNC = () => { };

const ZERO = 0;

const SOCIAL_ACCOUNTS = [
  'facebook',
  'instagram',
  'linkedin',
  'twitter',
];

const COLON = ':';

// supported two-letter ISO country codes (like US)
const COUNTRY_CODES = {
  US: 'US',
};

export {
  EMPTY_FUNC,
  COLON,
  COUNTRY_CODES,
  SOCIAL_ACCOUNTS,
  ZERO,
};
