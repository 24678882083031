import React from 'react';
import {
  func,
  string,
} from 'prop-types';
import {
  Box,
} from '@material-ui/core';
import {
  DonorType,
  isMobileView,
} from 'lib/utils';
import {
  translate,
} from 'lib/intl';
import {
  Gap,
} from 'components';

import {
  DonorTextInput,
} from './donor-text-input';

const AddressInputs = ({
  data,
  path,
  updateData,
}) => {
  const isMobile = isMobileView();

  return (
    <>
      <DonorTextInput
        data={data}
        path={`${path}.country`}
        label={translate('COUNTRY_LABEL')}
        updateData={updateData}
      />
      <Box display={isMobile ? 'block' : 'flex'}>
        <DonorTextInput
          data={data}
          path={`${path}.line1`}
          label={translate('STREET_ADDRESS_LABEL')}
          updateData={updateData}
        />
        <Gap size={2} />
        <DonorTextInput
          data={data}
          path={`${path}.unit`}
          label={translate('APT_LABEL')}
          updateData={updateData}
          width="30%"
        />
      </Box>
      <Box display={isMobile ? 'block' : 'flex'}>
        <DonorTextInput
          data={data}
          path={`${path}.city`}
          label={translate('CITY_LABEL')}
          updateData={updateData}
        />
        <Gap size={2} />
        <Box display="flex">
          <DonorTextInput
            data={data}
            path={`${path}.state`}
            label={translate('STATE_LABEL')}
            updateData={updateData}
          />
          <Gap size={2} />
          <DonorTextInput
            data={data}
            path={`${path}.zip`}
            label={translate('ZIP_LABEL')}
            updateData={updateData}
          />
        </Box>
      </Box>
    </>
  );
};

AddressInputs.propTypes = {
  data: DonorType.isRequired,
  path: string.isRequired,
  updateData: func.isRequired,
};

export {
  AddressInputs,
};
