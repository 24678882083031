
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  ZERO,
} from 'lib/constants';
import {
  useParams,
} from 'react-router-dom';
import {
  donorInfoActions,
} from 'rdx/actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

const useDonorPage = () => {
  const [tabIdx, setTabIdx] = useState(ZERO);
  const { id } = useParams();

  const dispatch = useDispatch();

  const {
    donorInfo,
    ownerOrgPubId,
  } = useSelector(state => ({
    donorInfo: state.donorInfo?.data?.donor || {},
    ownerOrgPubId: state.authentication?.user?.org?.ownerOrgPubId || '',
  }));

  const fetchDonorInfo = useCallback(() => {
    if (id) {
      dispatch(donorInfoActions.fetchDonorInfo(ownerOrgPubId, id));
    }
  }, [
    ownerOrgPubId,
    id,
  ]);

  useEffect(() => {
    fetchDonorInfo();
  }, []);

  return {
    donor: donorInfo,
    fetchDonorInfo,
    id,
    isDonorHistoryTab: tabIdx === 0,
    ownerOrgPubId,
    setTabIdx,
  };
};

export {
  useDonorPage,
};
