import userActionTypes from 'rdx/actionTypes';

const {
  UPDATE_INVITES_REQUEST,
  UPDATE_INVITES_SUCCESS,
  UPDATE_INVITES_FAILURE,
} = userActionTypes;

const initialState = {
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: {},
  invitePubIds: [],
};

const filterInviteIds = (state, action) => state.invitePubIds
  .filter(id => id !== action.invitePubId);

export function updatingInvites(
  state = initialState,
  action,
) {
  switch (action.type) {
    case UPDATE_INVITES_REQUEST:
      return {
        ...state,
        dataLoaded: false,
        isFetching: true,
        error: null,
        invitePubIds: [
          ...state.invitePubIds,
          action.invitePubId,
        ],
      };
    case UPDATE_INVITES_SUCCESS:
      return {
        ...state,
        data: action.data,
        dataLoaded: true,
        isFetching: false,
        invitePubIds: filterInviteIds(state, action),
        error: null,
      };
    case UPDATE_INVITES_FAILURE:
      return {
        ...state,
        isFetching: false,
        invitePubIds: filterInviteIds(state, action),
        error: action.error,
      };
    default:
      return state;
  }
}
