import React from 'react';
import styled from 'styled-components';
import {
  node,
} from 'prop-types';
import {
  Box,
} from '@material-ui/core';

const StyledContainer = styled(Box)`
  max-width: 1100px;
`;

const DonorSectionWrapper = ({ children }) => (
  <StyledContainer>
    {children}
  </StyledContainer>
);

DonorSectionWrapper.propTypes = {
  children: node.isRequired,
};

export {
  DonorSectionWrapper,
};
