import React, {
  useMemo,
} from 'react';
import {
  bool,
  func,
} from 'prop-types';
import {
  translate,
} from 'lib/intl';
import {
  DonorType,
} from 'lib/utils';
import {
  EditDonorSectionWrapper,
} from './edit-donor-section-wrapper';
import {
  SectionsGenerator,
} from './sections-generator';
import {
  ORG_CONNECTIONS_CONFIG,
  USER_CONNECTIONS_CONFIG,
} from './config';

const EditDonorAffiliations = ({
  data,
  isUserType,
  updateData,
}) => {
  const config = useMemo(() => (isUserType
    ? USER_CONNECTIONS_CONFIG
    : ORG_CONNECTIONS_CONFIG), [isUserType]);

  const title = useMemo(() => translate(
    isUserType
      ? 'DONOR_FAMILY_AND_AFFILIATIONS'
      : 'DONOR_EMPLOYEES_AND_AFFILIATIONS',
  ), [isUserType]);

  return (
    <EditDonorSectionWrapper
      id="edit-donor-affiliations-section"
      title={title}
    >
      <SectionsGenerator
        config={config}
        data={data}
        updateData={updateData}
      />
    </EditDonorSectionWrapper>
  );
};

EditDonorAffiliations.propTypes = {
  data: DonorType.isRequired,
  isUserType: bool,
  updateData: func.isRequired,
};

EditDonorAffiliations.defaultProps = {
  isUserType: true,
};

export {
  EditDonorAffiliations,
};
