import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  node,
} from 'prop-types';
import styled from 'styled-components';
import {
  Button,
} from '@material-ui/core';
import {
  translate,
} from 'lib/intl';
import {
  ShowLessArrowsIcon,
  ShowMoreArrowsIcon,
} from 'assets/images';
import {
  Gap,
  Visible,
} from 'components';
import {
  colors,
} from 'styles/theme';
import {
  InputSectionWrapper,
} from './input-section-wrapper';

const StyledButton = styled(Button)`
  background: ${colors.lightGreen};
  border-radius: 100px;
  color: ${colors.darkGreen};
  height: 56px;
  margin-top: 24px;
  padding: 0 24px;
  text-transform: none;
`;

const SHOW_MORE_CONFIG = {
  MORE: {
    label: translate('SHOW_MORE_OPTIONS_LABEL'),
    icon: <ShowMoreArrowsIcon />,
  },
  LESS: {
    label: translate('SHOW_LESS_OPTIONS_LABEL'),
    icon: <ShowLessArrowsIcon />,
  },
};

const ShowMoreContent = ({ children }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = useCallback(() => setShowMore(!showMore), [showMore]);

  const {
    label,
    icon,
  } = useMemo(() => (showMore ? SHOW_MORE_CONFIG.LESS : SHOW_MORE_CONFIG.MORE), [showMore]);

  return (
    <>
      <Visible when={showMore}>
        {children}
      </Visible>
      <InputSectionWrapper>
        <StyledButton
          color="primary"
          data-testid={showMore ? 'show-less-button' : 'show-more-button'}
          onClick={toggleShowMore}
        >
          {label}
          <Gap size={2} />
          {icon}
        </StyledButton>
      </InputSectionWrapper>
    </>
  );
};

ShowMoreContent.propTypes = {
  children: node.isRequired,
};

export {
  ShowMoreContent,
};
