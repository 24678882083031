import React from 'react';
import {
  Box,
} from '@material-ui/core';
import {
  isMobileView,
} from 'lib/utils';

const DonorHistoryContent = () => {
  const isMobile = isMobileView();

  return (
    <Box px={isMobile ? 2 : 3} py={4}>
      Content will be implemented later
    </Box>
  );
};

export {
  DonorHistoryContent,
};
