export const DONOR = {
  BACK_TO_MY_DONORS_LABEL: 'Back to My Donors',
  DONOR_EDIT_PROFILE: 'Edit Profile',
  NEW_INTERACTION_REPORT_LABEL: 'New Interaction Report',
  PERSONAL_INFO_TAB: 'Personal info',
  DONOR_HISTORY_TAB: 'Donor History',
  DONOR_NOTE_LABEL: 'Note',
  DONOR_NOTE_PLACEHOLDER_1: 'Goal with this donor...',
  DONOR_NOTE_PLACEHOLDER_2: 'Interesting tidbit...',
  DONOR_NOTE_PLACEHOLDER_3: 'Do not...',
  DONOR_PERSONAL_INFORMATION: 'Personal Information',
  DONOR_COMPANY_INFORMATION: 'Company Information',
  DONOR_PRIMARY_CONTACT_INFORMATION: 'Primary Contact Information',
  DONOR_WORK_INFORMATION: 'Work Information',
  DONOR_FAMILY_AND_AFFILIATIONS: 'Family and Affiliations',
  DONOR_EMPLOYEES_AND_AFFILIATIONS: 'Employees and Affiliations',
  DONOR_DIVING_SUMMARY: 'Giving Summary',
  EDIT_DONOR_PROFILE_TITLE: 'Edit Donor Profile',
  CREATE_DONOR_PROFILE_TITLE: 'Create Donor Profile',
  FIRST_NAME_LABEL: 'First Name',
  LAST_NAME_LABEL: 'Last Name',
  EMAIL_ADDRESS_LABEL: 'Email Address',
  WORK_EMAIL_ADDRESS_LABEL: 'Work Email Address',
  PHONE_NUMBER_LABEL: 'Phone Number',
  COUNTRY_LABEL: 'Country',
  STREET_ADDRESS_LABEL: 'Street Address',
  APT_LABEL: 'Apt/Suite',
  CITY_LABEL: 'City',
  STATE_LABEL: 'State',
  ZIP_LABEL: 'ZIP',
  INSTAGRAM_LABEL: 'Instagram Profile',
  TWITTER_LABEL: 'Twitter Profile',
  LINKEDIN_LABEL: 'LinkedIn Profile',
  FACEBOOK_LABEL: 'Facebook Profile',
  BIRTHDAY_LABEL: 'Birthday',
  EMPLOYER_LABEL: 'Employer',
  EMPLOYEES_LABEL: 'Employees',
  TITLE_LABEL: 'Title',
  TYPE_LABEL: 'Type',
  WEBSITE_LABEL: 'Website',
  SPOUSE_LABEL: 'Spouse',
  FAMILY_MEMBER_LABEL: 'Family Member',
  CONNECTIONS_LABEL: 'Connections',
  COMPANY_NAME_LABEL: 'Company Name',
  COMPANY_UNDUSTRY_LABEL: 'Company Industry',
  AFFILIATIONS_LABEL: 'Company Affiliations',
  PRIMARY_LABEL: 'Primary',
  MOBILE_LABEL: 'Mobile',
  HOME_LABEL: 'Home',
  THREE_KEY_NOTES_LABEL: '3 Key Notes',
  DONOR_FORM_FIX_ERRORS_MESSAGE: 'Fix errors to save the donor',
  EDIT_DONOR_FORM_PERSON_LABEL: 'Person',
  EDIT_DONOR_FORM_ORG_LABEL: 'Organization',
  FRONT_DESK_LABEL: 'Front Desk',
  BILLING_LABEL: 'Billing',
  SALES_LABEL: 'Sales',
  PRIMARY_CONTACT_INFO: 'Primary Contact Information',
  SECONDARY_CONTACT_INFO: 'Secondary Contact Information',
  PRIMARY_CONTACT_NAME: 'Primary Contact Name',
  SECONDARY_CONTACT_NAME: 'Secondary Contact Name',
  ADD_SECONDARY_CONTACT: 'Add Secondary Contact',
  REMOVE_CONTACT: 'Remove Contact',
  DONOR_UPDATED_LABEL: 'Donor Updated!',
  DONOR_UPDATED_MESSAGE: 'Donor was successfully updated',
};
