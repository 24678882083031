import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Box,
  CircularProgress,
  makeStyles,
  Slide,
} from '@material-ui/core';
import {
  Visible,
} from 'components';
import { EMPTY_FUNC } from 'lib/constants';
import {
  isInViewport,
  isMobileView,
} from 'lib/utils';
import {
  feedControl,
  useOutsideAlerter,
} from 'pages/home-page/hooks';
import {
  FeedItem,
} from './feed-item';
import {
  FeedSlider,
} from './feed-slider';

// TODO make this dynamic
const feedHeaderAndPaddingHeight = 71;
const mobileHomeHeaderHeight = 56;
const marginTop = 1;
const defaultHeight = '48px';
const useStyles = makeStyles(theme => ({
  feedVerticalContentContainer: {
    height: '100vh',
    background: theme.palette.background.light,
    overflowY: 'auto',
    position: 'relative',
    paddingBottom: '54px',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      overflowY: 'inherit',
    },
    '&.active': {
      paddingRight: '15px',
    },
  },
  feedSlideoutContainer: {
    position: 'absolute',
    height: '100vh',
    top: '2px',
    bottom: 0,
    right: 0,
    width: '50%',
    background: theme.palette.background.white,
    boxShadow: `0 0 6px 0 ${theme.palette.grey.dark}`,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      width: '75%',
      paddingRight: '0',
    },
  },
  loading: {
    display: 'block',
    height: defaultHeight,
    width: defaultHeight,
    margin: '0 auto',
  },
  overlayLoader: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      minHeight: '500px',
    },
  },
}));

const Feed = ({
  layoutContainerId,
  layoutContainerRef,
  feed,
  getFeedPage,
  setActiveFeedItem,
  activeFeedItemId,
}) => {
  let containerRef;
  let slideOutRef;
  const isMobile = isMobileView();
  const styles = useStyles();
  const scrollToRef = (ref) => {
    if (ref) {
      if (isMobile && layoutContainerRef && slideOutRef) {
        // Only scroll to the item in mobile if the item is not within the viewport
        if (!isInViewport(ref)) {
          // eslint-disable-next-line no-param-reassign
          layoutContainerRef.current.scrollTop = ref.offsetTop;
        }

        const containerRect = containerRef.getBoundingClientRect();

        if (containerRect.y > 0) {
          // If above the fold, top position will be 0
          slideOutRef.style.top = '0';
        } else {
          // If below the fold, adjust the slideout to match the top of the container (below header)
          slideOutRef.style.top = `${-1 * containerRect.y + mobileHomeHeaderHeight + marginTop}px`;
        }
      } else if (!isMobile && containerRef) {
        containerRef.scrollTop = ref.offsetTop - feedHeaderAndPaddingHeight;
      }
    }
  };
  const itemRefs = [];

  const setContainerRef = (ref) => {
    containerRef = ref;
  };
  const setSlideoutRef = (ref) => {
    slideOutRef = ref;
  };
  const setRef = (itemRef, id) => {
    itemRefs[id] = itemRef;
  };

  const buildFeedItem = (item) => {
    const {
      feedItemPubId: id,
      feedItemContentType: type,
      content: body,
      datetime: date,
      pinned,
    } = item;
    const isActive = id === activeFeedItemId;

    return (
      <FeedItem
        id={id}
        key={id}
        date={date}
        body={body}
        type={type}
        pinned={pinned}
        isActive={isActive}
        ref={(itemRef) => { setRef(itemRef, id); }}
        onClick={() => setActiveFeedItem(id)}
      />
    );
  };
  const { isFetching, items } = feed;
  const desktopScrollContainer = 'infinite-scroll-container';
  let mobileActiveItem;

  if (isMobile) {
    mobileActiveItem = activeFeedItemId;
  }

  // Takes over fetching if there is an active feed item on page load
  feedControl(
    activeFeedItemId,
    mobileActiveItem,
    isMobile,
    isFetching,
    itemRefs,
    layoutContainerRef,
    getFeedPage,
    feed,
    scrollToRef,
  );

  const activeItem = items.find(item => item.feed_item_pub_id === activeFeedItemId);
  const containerClassName = styles.feedVerticalContentContainer;
  const statefulContainerClassName = activeFeedItemId ? containerClassName.concat(' active') : containerClassName;
  // TODO replace with actual API call when ready
  const getNextPage = () => {
    getFeedPage(feed.currentPage + 1);
  };

  return (
    <>
      <Box
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Visible when={isFetching}>
          <Box
            className={styles.overlayLoader}
          >
            <CircularProgress
              data-testid="auth-action-button-loading"
              color="primary"
              className={styles.loading}
            />
          </Box>
        </Visible>
        <Box
          id={desktopScrollContainer}
          ref={(thisRef) => { setContainerRef(thisRef); }}
          className={statefulContainerClassName}
        >
          <InfiniteScroll
            key={isMobile ? layoutContainerId : desktopScrollContainer}
            dataLength={items.length}
            next={getNextPage}
            hasMore={!activeFeedItemId}
            scrollableTarget={isMobile ? layoutContainerId : desktopScrollContainer}
            endMessage={<div />}
          >
            {items.map(buildFeedItem)}
          </InfiniteScroll>
        </Box>
        <Slide
          ref={(thisRef) => { setSlideoutRef(thisRef); }}
          direction="left"
          in={(Boolean(activeFeedItemId) && !isFetching)}
          mountOnEnter
          unmountOnExit
        >
          <Box
            className={styles.feedSlideoutContainer}
          >
            <Visible when={activeItem && typeof activeItem !== 'undefined'}>
              <FeedSlider
                item={activeItem}
                useOutsideAlerter={useOutsideAlerter}
              />
            </Visible>
          </Box>
        </Slide>
      </Box>
    </>
  );
};

Feed.propTypes = {
  layoutContainerId: PropTypes.string,
  layoutContainerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  feed: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    currentPage: PropTypes.number,
    isFetching: PropTypes.bool,
  }),
  getFeedPage: PropTypes.func,
  setActiveFeedItem: PropTypes.func,
  activeFeedItemId: PropTypes.number,
};

Feed.defaultProps = {
  layoutContainerId: null,
  layoutContainerRef: null,
  feed: {
    items: [],
  },
  getFeedPage: EMPTY_FUNC,
  setActiveFeedItem: EMPTY_FUNC,
  activeFeedItemId: null,
};

export {
  Feed,
};
